import { createAction, props } from "@ngrx/store";

enum actionTypes {
  SET_ID = "[Corona] Store ID",
  CLEAR_ID = "[Corona] Clear ID",
}

export const setId = createAction(actionTypes.SET_ID, props<{ data: any }>());

export const clearId = createAction(actionTypes.CLEAR_ID);
