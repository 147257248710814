<div
  *ngIf="uploadMode; else preview"
  class="d-flex align-items-center justify-content-between rounded-circle shadow-sm border"
  [style]="{ height: height + 'px', width: height + 'px' }"
  (click)="file.click()"
>
  <input
    type="file"
    hidden
    #file
    required
    (change)="onFileChange($event)"
    accept="image/*"
  />
  <img
    [src]="filePreview | safeImage"
    class="rounded-circle border shadow-sm"
    appImgFallback="/assets/gravatar.svg"
    [style]="{ height: height + 'px', width: height + 'px' }"
  />
  <i class="fas fa-camera text-dark"></i>
</div>

<ng-template #preview>
  <div
    class="d-flex align-items-center justify-content-between rounded-circle"
    [style]="{ height: height + 'px', width: height + 'px' }"
  >
    <img
      [src]="filePreview | safeImage"
      class="rounded-circle border shadow-sm"
      appImgFallback="/assets/gravatar.svg"
      [style]="{ height: height + 'px', width: height + 'px' }"
    />
  </div>
</ng-template>
