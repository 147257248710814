import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { FormConfig } from "src/app/formsy";
import { AuthService } from "../../services/auth.service";
import { FormGroup } from "@angular/forms";
import { storeUser, updateUser } from "../../store/auth.action";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { LoaderService } from "src/app/loader";
import { Logger } from "src/constants/logger";
import { Observable } from "rxjs";
import { SubSink } from "subsink";
import { ToastService } from "src/app/toast";
import { MatDialog } from "@angular/material/dialog";
// import { FlowPopupComponent } from "src/app/primary-care/components/flow-popup/flow-popup.component";
import { take } from "rxjs/operators";
import { NgLog } from "src/app/logger";
import { ConfigModel } from "src/app/app-config/actions";
import { LoginFormModel, SignupFormModel } from "../../models/login-form.model";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
@NgLog()
export class LoginFormComponent implements OnInit, OnDestroy, OnChanges {
  private sink = new SubSink();
  model: FormConfig = LoginFormModel;
  mode: string = "login";
  @Input() socials: string[];
  @Input() type = "patient";
  loginForm: FormGroup;
  hasError: boolean;
  feature: any;
  user$: Observable<any>;
  flow: string;
  submitted = false;
  appId: string;
  status = false;
  constructor(
    private authService: AuthService,
    private store: Store<{
      user: any;
      flow: string;
      selectedDoctor: any;
      appConfig: ConfigModel;
    }>,
    private router: Router,
    private loader: LoaderService,
    private dialog: MatDialog,
    private toast: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    this.user$ = this.store.select((d) => d.user);
  }
  ngOnInit(): void {
    this.getAppId(); // used to check doctors access for practice
    this.getFlow(); // patient flow property
    this.sink.add(
      this.store
        .select("user")
        .pipe(take(1))
        .subscribe((user) => {
          if (user && user.uid) {
            this.checkUserStatus(user);
          }
        })
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.mode && changes.mode.currentValue) {
      this.cdr.detectChanges();
    }
  }
  ngOnDestroy() {
    this.sink.unsubscribe();
  }
  get buttonText() {
    return this.mode === "login" ? "Login" : "Sign Up";
  }
  changeMode(type) {
    this.mode = type;
    if (type === "signup") {
      this.model = SignupFormModel;
    } else {
      this.model = LoginFormModel;
    }
  }
  getAppId() {
    this.sink.add(
      this.store
        .select("appConfig")
        .pipe(take(1))
        .subscribe((c) => (this.appId = c.appId))
    );
  }
  getFlow() {
    this.sink.add(
      this.store
        .select("flow")
        .pipe(take(1))
        .subscribe((d) => {
          this.flow = d;
        })
    );
  }
  showButton(type) {
    return (
      this.socials && this.socials.length > 0 && this.socials.includes(type)
    );
  }
  getForm(form: FormGroup) {
    this.loginForm = form;
  }
  doAction() {
    if (this.loginForm.valid) {
      this.status = true;
      if (this.mode === "login") {
        this.login();
      } else {
        this.signUp();
      }
    } else {
      this.loginForm.markAllAsTouched();
    }
  }
  async signUp() {
    try {
      const { email, password } = this.loginForm.value;
      const user = await this.authService.emailRegister(email, password);
      this.store.dispatch(storeUser({ data: user }));
      this.router.navigate(["/patient/signup"]);
      this.status = false;
    } catch (e) {
      this.authService.signOut(); // clear user created session
      Logger.Log("sign up error", e);
      this.showErrorMessage(e.code);
    }
  }
  showErrorMessage(code) {
    let msg = "";
    switch (code) {
      case "auth/email-already-in-use":
        msg = "Email already exists, try signing in to your account";
        break;
      default:
        msg = "An error occured while signing up, try again later.";
    }
    this.status = false;
    this.toast.showError(msg);
  }
  async login() {
    try {
      const { email, password } = this.loginForm.value;
      const user = await this.authService.userLogin(email, password);
      Logger.Log("login success", user);
      this.store.dispatch(storeUser({ data: user }));
      this.status = false;
      if (this.checkUserType(user)) {
        this.checkUserStatus(user);
      }
    } catch (e) {
      Logger.Log("login", e);
      this.showMessage(e.code);
      this.status = false;
    }
  }
  checkUserType(user) {
    if (user.roles.includes("patient") && this.type === "patient") {
      return true;
    } else if (user.roles.includes("doctor") && this.type === "doctor") {
      return true;
    } else {
      this.authService.signOut();
      this.toast.showError("Invalid login details");
      return false;
    }
  }
  showMessage(code) {
    if (code === "auth/user-not-found") {
      this.toast.showError("User does not exists, Sign up to continue");
    } else {
      this.toast.showError("Invalid login details.");
    }
  }
  checkUserStatus(user) {
    if (user.roles.includes("patient")) {
      if (user.status === "pending") {
        this.router.navigate(["/patient", "signup"]);
      } else {
        this.checkIfDoctorSelected(user.status);
      }
    } else if (user.roles.includes("doctor")) {
      // set doctor status to online
      this.checkDoctorStatus(user.uid);
    }
  }
  async checkDoctorStatus(uid: string) {
    try {
      await this.authService.doctorVerification(uid, this.appId);
      await this.authService.updateOnlineStatus(uid, true);
      this.router.navigate(["/doctor", "dashboard"]);
    } catch (e) {
      Logger.Log("doctor not allowed", e);
      this.authService.signOut();
      this.toast.showError("Invalid login details");
    }
  }
  checkIfDoctorSelected(status, lastPage?) {
    this.store
      .select("selectedDoctor")
      .pipe(take(1))
      .subscribe((doc) => {
        if (doc === null) {
          this.router.navigate(["/doctor/selector"], {
            queryParams: { returnUrl: lastPage },
          });
        } else {
          if (status === "pending") {
            this.router.navigate(["/patient", "signup"]);
          } else {
            this.router.navigate(["/patient", "intake"]);
          }
        }
      });
  }
  async resetPassword() {
    if (this.loginForm.get("email").valid) {
      try {
        this.loader.showLoader();
        await this.authService.resetPassword(
          this.loginForm.get("email").value,
          this.type
        );
        this.toast.showInfo(
          "A password reset email has been sent to the registered email id."
        );
        this.loader.hideLoader();
      } catch (e) {
        this.loginForm.get("email").markAsTouched();
        this.loader.hideLoader();
        this.toast.showError("There was an error sending password reset email");
      }
    } else {
      this.loginForm.get("email").markAsTouched();
      this.toast.showError("Please enter your email to reset password.");
    }
  }
  socialLogin(type) {
    this.status = true;
    if (type === "google") {
      this.loginWithGoogle();
    } else {
      this.loginWithFacebook();
    }
  }
  async loginWithGoogle() {
    try {
      const user = await this.authService.googleLogin();
      this.store.dispatch(storeUser({ data: user }));
      this.checkUserStatus(user);
      this.status = false;
    } catch (e) {
      Logger.Log("google login", e);
      this.status = false;
      this.toast.showError("Error occured while signing in using google");
    }
  }
  async loginWithFacebook() {
    try {
      const user = await this.authService.googleLogin();
      this.store.dispatch(storeUser({ data: user }));
      this.checkUserStatus(user);
      this.status = false;
    } catch (e) {
      Logger.Log("google login", e);
      this.status = false;
      this.toast.showError("Error occured while signing in using facebook");
    }
  }
}
