<div class="d-flex text-info justify-content-between align-items-center mb-2">
  <span>Payment Details</span>
  <button
    mat-icon-button
    color="primary"
    (click)="enableEdit()"
    *ngIf="disabled"
  >
    <i class="fas fa-pencil-alt"></i>
  </button>
  <div class="d-flex" *ngIf="!disabled">
    <button class="btn btn-info btn-sm px-1 py-0 mr-2" (click)="saveDetails()">
      Save
    </button>
    <button
      class="btn btn-outline-dark btn-sm px-1 py-0"
      (click)="cancelEdit()"
    >
      Cancel
    </button>
  </div>
</div>
<form class="row">
  <mat-form-field class="col-md-12" *ngIf="disabled; else newCard">
    <mat-label>Card Number</mat-label>
    <input matInput disabled [value]="cardNumber" />
  </mat-form-field>
  <ng-template #newCard>
    <div class="col-md-8">
      <mat-form-field class="w-100">
        <mat-label>Card Number</mat-label>
        <input matInput [formControl]="card" maxlength="20" />
      </mat-form-field>
      <app-show-errors [ctrl]="card"></app-show-errors>
    </div>
    <div class="col-md-4">
      <mat-form-field class="w-100">
        <mat-label>CVC</mat-label>
        <input matInput [formControl]="cvc" maxlength="4" />
      </mat-form-field>
      <app-show-errors [ctrl]="cvc"></app-show-errors>
    </div>
  </ng-template>

  <div class="col-md-12">
    <app-month-year-calendar
      [disabled]="disabled"
      template="material"
      [ctrl]="expiry"
    ></app-month-year-calendar>
  </div>
</form>
