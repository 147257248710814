import { createAction, props } from "@ngrx/store";

export enum ActionTypes {
  SELECT_DOCTOR = "[Doctor] Select Doctor",
  DESELECT_DOCTOR = "[Doctor] Clear Selected Doctor",
}

export const selectDoctor = createAction(
  ActionTypes.SELECT_DOCTOR,
  props<{ data: any }>()
);

export const deselectDoctor = createAction(ActionTypes.DESELECT_DOCTOR);
