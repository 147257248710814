import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { setFlow } from "src/app/app-config";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PrimaryCareDialogComponent } from "../primary-care-dialog/primary-care-dialog.component";

@Component({
  selector: "app-flow-popup",
  templateUrl: "./flow-popup.component.html",
  styleUrls: ["./flow-popup.component.scss"],
})
export class FlowPopupComponent implements OnInit {
  constructor(
    private store: Store<{ flow: string }>,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<FlowPopupComponent>
  ) {}

  ngOnInit(): void {}
  openDialog(type): void {
    this.store.dispatch(setFlow({ data: type }));
    this.dialogRef.close();
    const dialogRef = this.dialog.open(PrimaryCareDialogComponent, {
      width: "400px",
      data: {},
      hasBackdrop: true,
      panelClass: "custom-panel",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
