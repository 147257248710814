import { Pipe, PipeTransform } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { Observable } from "rxjs";

@Pipe({
  name: "fileStorageUrl",
  pure: true,
})
export class FileStorageUrlPipe implements PipeTransform {
  constructor(private storage: AngularFireStorage) {}
  transform(value: string, folder: string): Observable<string> {
    if (value && value !== "") {
      const ref = this.storage.ref(`${folder}/${value}`);
      return ref.getDownloadURL();
    }
    return null;
  }
}
