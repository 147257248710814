import { createAction, props } from "@ngrx/store";
import { FeaturesModel } from ".";

export enum FeatureActionTypes {
  ADD_FEATURES = "[Config] Store Features",
  REMOVE_FEATURES = "[Config] Clear Features",
}

export const storeFeatures = createAction(
  FeatureActionTypes.ADD_FEATURES,
  props<{ data: FeaturesModel }>()
);

export const clearFeatures = createAction(FeatureActionTypes.REMOVE_FEATURES);
