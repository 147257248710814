import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { RELATIONSHIPS } from "../../models/registration-form.model";
import { ToastService } from "src/app/toast";
import { nameValidator } from "src/app/formsy/validators/name.validator";

@Component({
  selector: "app-insurance-details",
  templateUrl: "./insurance-details.component.html",
  styleUrls: ["./insurance-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceDetailsComponent implements OnInit, OnChanges {
  @Input() insurance: any;
  @Input() doc: any;
  @Output() save = new EventEmitter();
  @Input() disableFields: boolean;
  disabled = true;
  options = RELATIONSHIPS;
  insuranceForm = new FormGroup({
    company: new FormControl(null, []),
    groupId: new FormControl(null, []),
    insuredName: new FormControl(null, [nameValidator]),
    relationship: new FormControl(null, []),
  });
  documentForm = new FormGroup({
    front: new FormControl(null),
    back: new FormControl(null),
  });
  constructor(private toast: ToastService) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.insurance && changes.insurance.currentValue) {
      const data = changes.insurance.currentValue;
      this.insuranceForm.patchValue({
        company: data.company,
        groupId: data.groupId,
        insuredName: data.insuredName,
        relationship: data.relationship,
      });
    }
    if (changes && changes.disableFields) {
      this.disabled = changes.disableFields.currentValue;
    }
    if (changes && changes.doc) {
      this.documentForm.patchValue(changes.doc.currentValue);
    }
  }

  enableEdit() {
    this.disabled = !this.disabled;
  }
  saveDetails() {
    if (this.insuranceForm.valid) {
      this.save.emit({
        insurance: this.insuranceForm.value,
        documents: this.documentForm.value,
      });
    } else {
      this.toast.showError("Please fill in all the fields");
    }
  }
}
