import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { mergeMapTo, mergeMap } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { Logger } from "src/constants/logger";
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  message$ = this.currentMessage.asObservable();
  constructor(
    private afMessaging: AngularFireMessaging,
    private afs: AngularFirestore
  ) {}
  // get permission to send messages

  requestPermission(user) {
    this.afMessaging.requestPermission
      .pipe(mergeMapTo(this.afMessaging.tokenChanges))
      .subscribe(
        (token) => {
          Logger.Log("Permission granted! Save to the server!", token);
          this.saveToken(user, token);
        },
        (error) => {
          Logger.Log(error);
        }
      );
  }
  receiveMessage() {
    this.afMessaging.onMessage((payload) => {
      Logger.Log("new message received. ", payload);
      this.currentMessage.next(payload);
      const title = payload.notification.title;
      const options = {
        body: payload.notification.body || {},
        icon: payload.notification.icon || "",
        click_action: payload.data.click_action || "",
      };
      const notification = new Notification(title, options);
      notification.onclick = function (event) {
        window.open(payload.data.click_action, "_blank");
      };
      setTimeout(() => {
        notification.close();
      }, 5000);
    });
  }
  // Listen for token refresh
  monitorRefresh(user) {
    this.afMessaging.onTokenRefresh(() => {
      this.afMessaging.getToken
        .toPromise()
        .then((refreshedToken) => {
          Logger.Log("Token refreshed.");
          this.saveToken(user, refreshedToken);
        })
        .catch((err) => console.log(err, "Unable to retrieve new token"));
    });
  }
  deleteToken() {
    this.afMessaging.getToken
      .pipe(mergeMap((token) => this.afMessaging.deleteToken(token)))
      .subscribe((token) => {
        Logger.Log("Token deleted!");
      });
  }
  // save the permission token in firestore
  private saveToken(user, token): void {
    if (user) {
      const currentTokens = user["fcmTokens"] || {};
      Logger.Log(currentTokens, token);
      // If token does not exist in firestore, update db
      if (!currentTokens[token]) {
        const userRef = this.afs.collection("users").doc(user.uid);
        const tokens = { ...currentTokens, [token]: true };
        userRef.update({ fcmTokens: tokens });
      }
    }
  }
}
