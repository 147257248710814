import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from "moment";

const moment = _rollupMoment || _moment;

import { TEMPLATE_ENGINES } from "../../models/form-config.model";
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-month-year-calendar",
  templateUrl: "./month-year-calendar.component.html",
  styleUrls: ["./month-year-calendar.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MonthYearCalendarComponent implements OnChanges {
  @Input() label: string;
  @Input() ctrl: FormControl;
  @Input() template: TEMPLATE_ENGINES;
  @Input() name: string;
  @Input() disabled: boolean = false;
  @Input() appearance: string = null;
  //date = new FormControl(moment());
  todayDate = moment().add(1, "months");
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.ctrl && changes.ctrl.currentValue) {
      const ctrl = changes.ctrl.currentValue as FormControl;
      if (ctrl.value === null) {
        this.ctrl.setValue(this.todayDate);
      }
    }
  }

  chosenYearHandler(normalizedYear) {
    const ctrlValue = this.ctrl.value || moment();
    ctrlValue.year(moment(normalizedYear).year());
    this.ctrl.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth, datepicker) {
    const ctrlValue = this.ctrl.value || moment();
    ctrlValue.month(moment(normalizedMonth).month());
    this.ctrl.setValue(ctrlValue);
    datepicker.close();
  }
}
