import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
  ConfigModel,
  FeaturesModel,
  addConfig,
  storeFeatures,
} from "../actions";
import { Store } from "@ngrx/store";
import { Logger } from "src/constants/logger";
import { setFlow } from "../actions/flow.actions";
import { take } from "rxjs/operators";

@Injectable()
export class AppConfigService {
  constructor(
    private http: HttpClient,
    private store: Store<{ appConfig: ConfigModel }>
  ) {}

  getPractice() {
    return this.store
      .select("appConfig")
      .pipe(take(1))
      .toPromise()
      .then((c) => {
        if (c && c.appId) {
          return Promise.resolve(c);
        }
        throw "no config";
      })
      .catch((e) =>
        this.http.get<ConfigModel>(`${environment.v1}/client`).toPromise()
      );
    // return this.http.get<ConfigModel>(`${environment.v1}/client`);
  }
  getAllFeatures() {
    return this.http.get<FeaturesModel>(`/assets/features.json`);
  }
  getAllClients() {
    return this.http.get<ConfigModel[]>(`${environment.v1}/clients`);
  }
  getAllConfigs() {
    return Promise.all([
      this.getPractice(),
      this.getAllFeatures().toPromise(),
    ]).then((res) => {
      Logger.Log("result", res);
      this.store.dispatch(addConfig({ data: res[0] }));
      this.store.dispatch(storeFeatures({ data: res[1] }));
      // this.store.dispatch(setFlow({ data: "primary care" }));
      return Promise.resolve("done");
    });
  }
}
