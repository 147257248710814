import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { TEMPLATE_ENGINES } from "../../models/form-config.model";
import { NgLog } from "src/app/logger";

@Component({
  selector: "app-textbox",
  templateUrl: "./textbox.component.html",
  styleUrls: ["./textbox.component.scss"],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
@NgLog()
export class TextboxComponent implements OnInit, OnChanges {
  @Input() type: "text" | "email" | "number" | "multiline" | "password";
  @Input() label: string;
  @Input() name: string;
  @Input() class: string;
  @Input() ctrl: FormControl;
  @Input() placeholder: string;
  @Input() template: TEMPLATE_ENGINES;
  @Input() maxlength: number = 50;
  placeholderText: any;
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && !!changes.placeholder && changes.placeholder.currentValue) {
      this.placeholderText = changes.placeholder.currentValue;
    }
    if (
      changes &&
      changes.maxlength &&
      changes.maxlength.currentValue === undefined
    ) {
      this.maxlength = 50;
    }
  }
}
