import {
  Component,
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { TEMPLATE_ENGINES } from "../..";

@Component({
  selector: "app-masked-textbox",
  templateUrl: "./masked-textbox.component.html",
  styleUrls: ["./masked-textbox.component.scss"],
})
export class MaskedTextboxComponent implements OnInit, OnChanges {
  @Input() type: "text" | "email" | "number" | "multiline" | "password";
  @Input() label: string;
  @Input() name: string;
  @Input() class: string;
  @Input() ctrl: FormControl;
  @Input() placeholder: string;
  @Input() template: TEMPLATE_ENGINES;
  @Input() mask: string;
  placeholderText: any;
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && !!changes.placeholder && changes.placeholder.currentValue) {
      this.placeholderText = changes.placeholder.currentValue;
    }
  }
}
