import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-patient-meta-edits",
  templateUrl: "./patient-meta-edits.component.html",
  styleUrls: ["./patient-meta-edits.component.scss"],
})
export class PatientMetaEditsComponent {
  constructor(
    public dialogRef: MatDialogRef<PatientMetaEditsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { name: string; gender: string; dob: Date }
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
