import { NgModule } from "@angular/core";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from "@angular/router";
import { NewHeaderComponent } from "./components/new-header/new-header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { PoweredByComponent } from "./components/powered-by/powered-by.component";

@NgModule({
  declarations: [
    NavbarComponent,
    NewHeaderComponent,
    FooterComponent,
    PoweredByComponent,
  ],
  imports: [SharedModule, RouterModule],
  exports: [NavbarComponent, FooterComponent, PoweredByComponent],
})
export class UiModule {}
