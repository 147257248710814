import { NgModule } from "@angular/core";
import { FormBuilderComponent } from "./containers/form-builder/form-builder.component";
import { TextboxComponent } from "./components/textbox/textbox.component";
import { RadioComponent } from "./components/radio/radio.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { CheckListComponent } from "./components/check-list/check-list.component";
import { DropDownComponent } from "./components/drop-down/drop-down.component";
import { AutoCompleteComponent } from "./components/auto-complete/auto-complete.component";
import { CalendarComponent } from "./components/calendar/calendar.component";
import { SharedModule } from "../shared/shared.module";
import { ShowErrorsComponent } from "./components/show-errors/show-errors.component";
import { FormBuilderService } from "./services/form-builder.service";
import { MarkAsteriskDirective } from "./directives/mark-asterisk.directive";
import { MonthYearCalendarComponent } from "./components/month-year-calendar/month-year-calendar.component";
import { FileComponent } from "./components/file/file.component";
import { FileDragDropDirective } from "./directives/file-drag-drop.directive";
import { MaskDirective } from "./directives/mask.directive";
import { MaskedTextboxComponent } from "./components/masked-textbox/masked-textbox.component";
import { ChecklistGridComponent } from "./components/checklist-grid/checklist-grid.component";
import { PasswordComponent } from "./components/password/password.component";
import { PhoneComponent } from "./components/phone/phone.component";
import { FilePreviewComponent } from "./components/file-preview/file-preview.component";
import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";
import { DateTimeComponent } from "./components/date-time/date-time.component";
import { MultiSelectComponent } from "./components/multi-select/multi-select.component";
import { ChecklistWithNoneComponent } from "./components/checklist-with-none/checklist-with-none.component";
const COMPONENTS = [
  FormBuilderComponent,
  TextboxComponent,
  RadioComponent,
  CheckboxComponent,
  CheckListComponent,
  DropDownComponent,
  AutoCompleteComponent,
  CalendarComponent,
  ShowErrorsComponent,
  MarkAsteriskDirective,
  MonthYearCalendarComponent,
  FileComponent,
  FileDragDropDirective,
  MaskDirective,
  MaskedTextboxComponent,
  ChecklistGridComponent,
  PasswordComponent,
  PhoneComponent,
  FilePreviewComponent,
  DateTimeComponent,
  MultiSelectComponent,
  ChecklistWithNoneComponent,
];
@NgModule({
  declarations: [...COMPONENTS],
  imports: [SharedModule, NgxMatIntlTelInputModule],
  exports: [...COMPONENTS],
  providers: [FormBuilderService],
})
export class FormsyModule {}
