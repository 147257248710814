<mat-dialog-content>
  <div class="d-flex justify-content-center align-items-center py-2">
    <button
      color="primary"
      class="mr-4"
      mat-raised-button
      (click)="openDialog('primary-care')"
    >
      Primary Care
    </button>
    <button mat-raised-button (click)="openDialog('mental-health')">
      Mental Health
    </button>
    <!-- <button class="btn-light" mat-raised-button>Pediatrics</button> -->
  </div>
</mat-dialog-content>
