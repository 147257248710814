import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import * as moment from "moment";
import {
  creditCardValidator,
  cvcValidator,
} from "src/app/formsy/validators/payment.validators";
import { Logger } from "src/constants/logger";
import { ToastService } from "src/app/toast";

@Component({
  selector: "app-payment-details",
  templateUrl: "./payment-details.component.html",
  styleUrls: ["./payment-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDetailsComponent implements OnInit {
  @Input() payment: any;
  @Output() save = new EventEmitter();
  @Input() disableFields;
  disabled = true;
  card = new FormControl(null, [Validators.required, creditCardValidator]);
  cvc = new FormControl(null, [Validators.required, cvcValidator]);
  expiry = new FormControl(null, [Validators.required]);
  cardDetails: { card: string; expiryYear: number; expiryMonth: number };
  constructor(private toast: ToastService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.payment && changes.payment.currentValue) {
      this.cardDetails = changes.payment.currentValue;
      const d = moment([
        this.cardDetails.expiryYear,
        this.cardDetails.expiryMonth - 1,
        1,
      ]);
      Logger.Log("payment", this.cardDetails, d);
      this.expiry.setValue(d);
    }
    if (changes && changes.disableFields) {
      this.disabled = changes.disableFields.currentValue;
      if (this.cardDetails && this.cardDetails.card) {
        this.cardDetails.card = this.cardDetails.card.substr(
          this.cardDetails.card.length - 4
        );
      }
    }
  }

  get cardNumber() {
    if (this.cardDetails && this.cardDetails.card) {
      return `xxxx-xxxx-xxxx-${this.cardDetails.card}`;
    }
    return "";
  }
  enableEdit() {
    this.disabled = !this.disabled;
  }
  cancelEdit() {
    this.disabled = true;
    const d = moment([
      this.cardDetails.expiryYear,
      this.cardDetails.expiryMonth - 1,
      1,
    ]);
    this.expiry.setValue(d);
  }
  saveDetails() {
    if (this.card.valid && this.cvc.valid && this.expiry.valid) {
      let expiry: any = moment(this.expiry.value).format("MM/YYYY");
      expiry = expiry.split("/");
      this.cardDetails.card = this.card.value;
      this.cardDetails.expiryMonth = expiry[0];
      this.cardDetails.expiryYear = expiry[1];
      Logger.Log("card", this.cardDetails);
      this.save.emit({ ...this.cardDetails, cvc: this.cvc.value });
    } else {
      // this.toast.showError("Please fill in all the fields");
    }
  }
}
