import { Injectable } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class FileStorageService {
  private _fileURL: Observable<any>;
  constructor(private storage: AngularFireStorage) {}
  uploadFile(file: string, filePath: string = "upload", params?: {}) {
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file, { ...params });
    return task
      .snapshotChanges()
      .pipe(finalize(() => (this._fileURL = fileRef.getDownloadURL())));
  }

  get fileURL() {
    return this._fileURL;
  }
}
