import { NgModule, ModuleWithProviders, APP_INITIALIZER } from "@angular/core";
import { AppConfigService } from "./services/app-config.service";
import { StoreModule } from "@ngrx/store";
import { configFeatureKey, configReducer } from "./reducers/config.reducer";
import { featureKey, featureReducer } from "./reducers/feature.reducer";
import {
  selectedFeatureKey,
  selectedFeatureReducer,
} from "./reducers/selected-feature.reducer";
import { selectedDoctorReducer } from "../doctor/store/selected-doctor.reducer";
import { flowKey, flowReducer } from "./reducers/flow.reducer";
import { selectedPatientReducer } from "../doctor/store/selected-patient.reducer";
import { MasterListComponent } from "./pages/master-list/master-list.component";
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from "@angular/router";
import { MasterGuard } from "./services/master.guard";
function initApp(appConfigService: AppConfigService) {
  return () => appConfigService.getAllConfigs();
}
@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    StoreModule.forFeature(configFeatureKey, configReducer),
    StoreModule.forFeature(featureKey, featureReducer),
    StoreModule.forFeature(selectedFeatureKey, selectedFeatureReducer),
    StoreModule.forFeature("selectedDoctor", selectedDoctorReducer),
    StoreModule.forFeature("selectedPatient", selectedPatientReducer),
    StoreModule.forFeature(flowKey, flowReducer),
  ],
  declarations: [MasterListComponent],
})
export class AppConfigModule {
  public static forRoot(): ModuleWithProviders<AppConfigModule> {
    return {
      ngModule: AppConfigModule,
      providers: [
        AppConfigService,
        MasterGuard,
        {
          provide: APP_INITIALIZER,
          useFactory: initApp,
          deps: [AppConfigService],
          multi: true,
        },
      ],
    };
  }
}
