<div class="row" *ngIf="checklistArr && checklistArr.length > 0">
  <div [ngClass]="classDef" *ngFor="let f of checklistArr">
    <mat-checkbox
      [checked]="f.selected"
      (change)="updateData(f, $event.checked)"
      color="primary"
      ><span class="text-dark">{{ f.value }}</span></mat-checkbox
    >
  </div>
  <div [ngClass]="otherClassDef" *ngIf="showOther">
    <input
      *ngIf="!isMultiline; else multi"
      class="form-control"
      [readOnly]="isNoneSelected()"
      (focus)="deselectNone()"
      placeholder="Other"
      [formControl]="otherCtrl"
    />
  </div>
</div>

<ng-template #multi>
  <textarea
    class="form-control"
    [readOnly]="isNoneSelected()"
    (focus)="deselectNone()"
    placeholder="Other"
    [formControl]="otherCtrl"
  ></textarea>
</ng-template>
