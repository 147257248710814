import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class BannerService {
  private bannerSubject = new Subject<string>();
  banner$ = this.bannerSubject.asObservable();
  constructor() {}
  setMessage(message) {
    this.bannerSubject.next(message);
  }
  clearMessage() {
    this.bannerSubject.next("");
  }
}
