import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import { DateConverterPipe } from "../../pipes/date-converter.pipe";
import { MatDialog } from "@angular/material/dialog";
import { PatientMetaEditsComponent } from "../patient-meta-edits/patient-meta-edits.component";
import { convertToUnixTS, dateFromTimestamp } from "src/app/utils/time";

@Component({
  selector: "app-patient-meta",
  templateUrl: "./patient-meta.component.html",
  styleUrls: ["./patient-meta.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe, DateConverterPipe],
})
export class PatientMetaComponent implements OnInit {
  @Input() user: any;
  @Input() mrn: number;
  @Input() hasUnix: boolean = false;
  @Input() enableEdit: boolean = false;
  @Output() update = new EventEmitter();
  constructor(
    public dialog: MatDialog,
    private dateConverter: DateConverterPipe
  ) {}

  ngOnInit(): void {}

  openDialog(): void {
    const dialogRef = this.dialog.open(PatientMetaEditsComponent, {
      width: "350px",
      data: {
        name: this.user.displayName,
        dob: new Date(this.formatDOB()),
        gender: this.user.gender,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        this.update.emit({
          displayName: result.name,
          gender: result.gender,
          dob: result.dob,
        });
      }
    });
  }
  formatDOB() {
    if (this.hasUnix) {
      return dateFromTimestamp(this.user.dob);
    }
    return this.dateConverter.transform(this.user.dob);
  }
}
