export const ERROR_MESSAGE = {
  required: () => `This field is required`,
  minlength: (par) => `Min ${par.requiredLength} chars is required`,
  email: () => `Invalid email format`,
  creditCard: () => `Invalid credit card format`,
  cvc: () => `Invaid cvc format`,
  validatePhoneNumber: () => `Invalid phone number entered`,
  matDatepickerMax: () => `Invalid date selected`,
  matDatepickerParse: () => `Invalid date entered`,
  alpha: () => `Only alphabets are allowed`,
  dateCompare: () => `Selected date-time can not be greater than arrival time`,
};
