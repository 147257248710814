<div
  class="toast"
  [style.background]="toastColor"
  [@fadeAnimation]="{
    value: animationState,
    params: {
      fadeIn: toastConfig.animation.fadeIn,
      fadeOut: toastConfig.animation.fadeOut
    }
  }"
  (@fadeAnimation.done)="onFadeFinished($event)"
>
  <mat-icon (click)="close()" class="close-icon">close</mat-icon>
  <div>
    <div class="toast-icon" [innerHTML]="iconType"></div>
    <div class="toast-content">
      <strong *ngIf="data.title">{{ data?.title }}</strong>
      <span>{{ data.text }}</span>
    </div>
  </div>
</div>
