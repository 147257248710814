import { FieldConfig, FieldUpdates } from "./field-config.model";

export class FormConfig {
  controls: FieldConfig[];
  updateOn?: FieldUpdates = "blur";
  templateEngine?: TEMPLATE_ENGINES = TEMPLATE_ENGINES.BOOTSTRAP;
}

export enum TEMPLATE_ENGINES {
  BOOTSTRAP = "bootstrap",
  MATERIAL = "material",
}
