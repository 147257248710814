<div *ngIf="template === 'material'" class="form-group row">
  <mat-form-field class="col-md-12">
    <ngx-mat-intl-tel-input
      [preferredCountries]="preferredCountries"
      [enablePlaceholder]="true"
      [enableSearch]="true"
      name="phone"
      placeholder="Cell Phone Number"
      [formControl]="ctrl"
      [required]="ctrl.getError('required')"
    ></ngx-mat-intl-tel-input>
  </mat-form-field>
  <div class="col-md-12">
    <app-show-errors [ctrl]="ctrl" [template]="template"></app-show-errors>
  </div>
</div>
<!-- <pre>{{ ctrl.value | json }}</pre> -->
