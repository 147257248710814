import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "mat-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class RatingComponent implements OnInit {
  @Input("rating") rating: number = 3;
  @Input("starCount") starCount: number = 5;
  @Input("color") color: string = "primary";
  @Input() count: number;
  @Input() hideTooltip = false;
  @Output() private ratingUpdated = new EventEmitter();
  ratingDesc = ["Poor", "Fair", "Okay", "Good", "Excellent"];
  ratingArr = [];

  constructor() {}

  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }
  onClick(rating: number) {
    this.ratingUpdated.emit(rating);
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return "star";
    } else {
      return "star_border";
    }
  }
}
export enum StarRatingColor {
  primary = "primary",
  accent = "accent",
  warn = "warn",
}
