import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { DateConverterPipe } from "./date-converter.pipe";
@Pipe({
  name: "dob",
})
export class DobPipe implements PipeTransform {
  constructor(private dateConverter: DateConverterPipe) {}
  transform(value: string | Date): string {
    if (!value) {
      return null;
    } else {
      try {
        const age = moment().diff(
          moment(this.dateConverter.transform(value)),
          "years",
          false
        );
        return `${age} years`;
      } catch (e) {
        return null;
      }
    }
  }
}
