import { Component, OnInit, Input } from "@angular/core";
import { FormConfig } from "src/app/formsy";
import { FormGroup } from "@angular/forms";
import { CORONA_FORM_MODEL } from "../../models/corona.model";
import { MatDialog } from "@angular/material/dialog";
import { ToastService } from "src/app/toast";
import { LoaderService } from "src/app/loader";
import { CoronaConsentDialogComponent } from "../corona-consent-dialog/corona-consent-dialog.component";
import { Logger } from "src/constants/logger";
import { PrimaryCareService } from "../../services/primary-care.service";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { setId } from "../../store/corona.actions";

@Component({
  selector: "app-corona-form",
  templateUrl: "./corona-form.component.html",
  styleUrls: ["./corona-form.component.scss"],
})
export class CoronaFormComponent implements OnInit {
  coronaForm: FormConfig = null;
  @Input() showButton = true;
  form: FormGroup;
  constructor(
    public dialog: MatDialog,
    private toast: ToastService,
    private loader: LoaderService,
    private primaryCare: PrimaryCareService,
    private router: Router,
    private store: Store
  ) {
    this.initializeForm();
  }

  openDialog(): void {
    if (this.form.valid) {
      const data = Object.values(this.form.value);
      Logger.Log(data);
      if (data.includes("Yes")) {
        const dialogRef = this.dialog.open(CoronaConsentDialogComponent, {
          width: "40vw",
          panelClass: "custom-panel",
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.saveData();
            //this.openMetaDialog();
          } else {
            this.form.reset();
            this.router.navigate(["/"]);
          }
        });
      } else {
        this.toast.showInfo(`You do not have any symptoms of COVID-19`);
        this.form.reset();
        this.router.navigate(["/"]);
      }
    } else {
      this.form.markAllAsTouched();
      // this.toast.showError("Please fill in all details");
    }
  }

  // openMetaDialog() {
  //   const dialogRef = this.dialog.open(UserMetaFormComponent, {
  //     width: "30vw",
  //     panelClass: "custom-panel",
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     Logger.Log("form", result);
  //     this.saveData(result);
  //   });
  // }

  initializeForm() {
    this.coronaForm = CORONA_FORM_MODEL as FormConfig;
  }

  ngOnInit(): void {}

  getForm(form: FormGroup) {
    this.form = form;
  }

  async saveData() {
    try {
      this.loader.showLoader();
      this.store.dispatch(setId({ data: { ...this.form.value } }));
      this.loader.hideLoader();
      this.form.reset();
      this.router.navigate(["/doctor", "selector"]);
    } catch (e) {
      this.loader.hideLoader();
      this.toast.showError("An unknown error occured");
    }
  }
}
