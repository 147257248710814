import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgLog, LoggerService } from "src/app/logger";
import { AppConfigService, selectFeature, setFlow } from "src/app/app-config";
import {
  ConfigModel,
  addConfig,
  storeFeatures,
  FeaturesModel,
} from "src/app/app-config/actions";
import { Store, select } from "@ngrx/store";
import { Observable, forkJoin } from "rxjs";
import { SubSink } from "subsink";
import { Router, ActivatedRoute } from "@angular/router";
import { Logger } from "src/constants/logger";
@Component({
  selector: "app-main-loader",
  templateUrl: "./main-loader.component.html",
  styleUrls: ["./main-loader.component.scss"],
})
@NgLog()
export class MainLoaderComponent implements OnInit, OnDestroy {
  showSpinner: boolean = true;
  store$: Observable<ConfigModel>;
  sink = new SubSink();
  allFeatures: any[];
  allFeatures$: Observable<any[]>;
  constructor(
    private configService: AppConfigService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private store: Store<{
      appConfig: ConfigModel;
      features: any[];
    }>
  ) {
    this.store$ = this.store.pipe(select("appConfig"));
    this.allFeatures$ = this.store.select((d) => d.features);
  }

  ngOnInit(): void {
    this.showSpinner = true;
    this.getConfigNFeatures();
    // this.getAppConfig();
    // this.configService.getPractice().subscribe(
    //   (data) => {
    //     Logger.Log("v1 practice", data);
    //     this.store.dispatch(addConfig({ data }));
    //     this.store.dispatch(setFlow({ data: "primary care" }));
    //     this.navigateToPage();
    //   },
    //   (e) => {
    //     console.log("no record", e);
    //     // 404 page
    //   }
    // );
  }
  ngOnDestroy() {
    this.sink.unsubscribe();
  }
  getConfigNFeatures() {
    forkJoin([
      this.configService.getPractice(),
      this.configService.getAllFeatures(),
    ]).subscribe(
      (res) => {
        Logger.Log("result", res);
        this.store.dispatch(addConfig({ data: res[0] }));
        this.store.dispatch(storeFeatures({ data: res[1] }));
        // this.store.dispatch(setFlow({ data: "primary care" }));
        this.navigateToPage();
      },
      (err) => {
        Logger.Log("error", err);
      }
    );
  }
  navigateToPage() {
    this.sink.add(
      this.activeRoute.queryParamMap.subscribe((par) => {
        let page = "/";
        if (par.has("returnUrl")) {
          page = par.get("returnUrl");
        }
        this.router.navigate([page]);
      })
    );
  }
}
