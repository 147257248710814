import { createAction, props } from "@ngrx/store";

export enum ActionTypes {
  SELECT_FEATURE = "[Config] Select Feature",
  DESELECT_FEATURE = "[Config] Clear Selected Feature",
}

export const selectFeature = createAction(
  ActionTypes.SELECT_FEATURE,
  props<{ data: any }>()
);

export const deselectFeature = createAction(ActionTypes.DESELECT_FEATURE);
