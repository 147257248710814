import { FormControl } from "@angular/forms";
import { Logger } from "src/constants/logger";

export function creditCardValidator(ctrl: FormControl) {
  const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
  const amexpRegEx = /^(?:3[47][0-9]{13})$/;
  const discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
  if (ctrl.value !== "" && ctrl.value !== null) {
    if (
      visaRegEx.test(ctrl.value.trim()) ||
      mastercardRegEx.test(ctrl.value.trim()) ||
      amexpRegEx.test(ctrl.value.trim()) ||
      discovRegEx.test(ctrl.value.trim())
    ) {
      return null;
    } else {
      return { creditCard: true };
    }
  }
  return null;
}

export function cvcValidator(ctrl: FormControl) {
  if (ctrl.value !== "" && ctrl.value !== null) {
    const cvv = /^[0-9]{3,4}$/;
    if (!cvv.test(ctrl.value)) {
      return { cvc: true };
    } else {
      return null;
    }
  }
  return null;
}
