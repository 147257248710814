import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { TEMPLATE_ENGINES } from "../../models/form-config.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SubSink } from "subsink";

@Component({
  selector: "app-phone",
  templateUrl: "./phone.component.html",
  styleUrls: ["./phone.component.scss"],
})
export class PhoneComponent implements OnInit, OnDestroy {
  @Input() type: "text";
  @Input() label: string;
  @Input() name: string;
  @Input() class: string;
  @Input() ctrl: FormControl;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() template: TEMPLATE_ENGINES;
  @Input() preferredCountries = [];
  placeholderText: any;
  private sink = new SubSink();
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}
  ngOnDestroy() {
    this.sink.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && !!changes.placeholder && changes.placeholder.currentValue) {
      this.placeholderText = changes.placeholder.currentValue;
    }
    if (changes && changes.ctrl && changes.ctrl.currentValue) {
      this.sink.add(
        this.ctrl.valueChanges.subscribe((v) => this.cdr.detectChanges())
      );
    }
  }
}
