import { Injectable, ErrorHandler } from "@angular/core";
import { ToastService } from "src/app/toast";
import { Logger } from "src/constants/logger";
import { LoggerService } from "src/app/logger";
import { environment } from "src/environments/environment";

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private toast: ToastService, private logger: LoggerService) {}
  handleError(error: string) {
    Logger.Log("global error", error);
    if (environment.production) {
      this.logger.logError("global app error", error);
    }
    // if (error.includes("Network Error")) {
    //   this.toast.show({
    //     type: "error",
    //     text: "You seem to be offline, try connecting to alternate network",
    //     title: "Network Error",
    //   });
    // } else {
    this.toast.show({
      type: "error",
      text: "Please call support",
      title: "System Error",
    });
    // }
  }
}
