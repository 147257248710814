import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
  name: "dobUnix",
})
export class DobUnixPipe implements PipeTransform {
  transform(value: number, format: string = "MM/DD/YYYY"): any {
    try {
      if (!value) {
        return null;
      } else {
        return moment.unix(value).format(format);
      }
    } catch {
      return value;
    }
  }
}
