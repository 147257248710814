<div class="d-flex flex-column clearfix">
  <mat-card class="bg-white rounded mat-elevation-z1 p-5">
    <mat-card-content>
      <app-form-builder
        [model]="model"
        (onLoad)="getForm($event)"
      ></app-form-builder>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-flat-button
        class="btn btn-info btn-block p-2"
        type="submit"
        color="primary"
        (click)="doAction()"
        *ngIf="!status; else loader"
      >
        {{ buttonText | uppercase }}
      </button>
      <ng-template #loader>
        <button mat-flat-button class="btn btn-info btn-block p-2" disabled>
          <div class="spinner-border text-dark" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </ng-template>
      <div
        class="text-center forgot mt-4 mb-2"
        *ngIf="buttonText.toLowerCase() === 'login'"
      >
        <a (click)="resetPassword()">
          Forgot Password?
        </a>
        <div *ngIf="type === 'patient'" class="mt-2">
          New User?
          <a
            style="color: #016e95; cursor: pointer;"
            (click)="this.changeMode('signup')"
            >Sign Up</a
          >
          here
        </div>
      </div>
      <div
        class="text-center forgot mt-4 mb-2"
        *ngIf="buttonText.toLowerCase() !== 'login'"
      >
        <div *ngIf="type === 'patient'" class="mt-2">
          Existing User?
          <a
            style="color: #016e95; cursor: pointer;"
            (click)="this.changeMode('login')"
            >Login</a
          >
          instead
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
  <section
    class="bg-white rounded mat-elevation-z2 mt-3 px-5 py-3"
    *ngIf="socials?.length > 0"
  >
    <h6 class="text-center text-dark my-2">OR</h6>
    <div class="social-logins d-flex flex-column">
      <button
        mat-stroked-button
        *ngIf="showButton('google')"
        (click)="socialLogin('google')"
      >
        <i class="fab fa-google text-danger"></i>
        {{ buttonText | titlecase }} with Google
      </button>
      <button
        mat-stroked-button
        *ngIf="showButton('facebook')"
        (click)="socialLogin('facebook')"
      >
        <i class="fab fa-facebook text-primary"></i>
        {{ buttonText | titlecase }} with Facebook
      </button>
    </div>
  </section>
</div>
