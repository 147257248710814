<div class="container">
  <div class="row">
    <div
      class="col-md-4 justify-content-center d-flex flex-column clearfix px-1 align-self-center"
    >
      <h1>We are here to <br />Help You!</h1>
      <p>
        Fill out the sreening questions to find out if you are at risk for
        COVID-19.
        <span>Corona-Virus related virtual visits are free of cost.</span>
      </p>
    </div>
    <div class="col-md-8 align-self-center">
      <app-corona-form></app-corona-form>
    </div>
  </div>
</div>
