<div class="form-group row">
  <mat-form-field *ngIf="template === 'material'" class="col-md-12">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      [formControl]="ctrl"
      [name]="name"
      [max]="today"
      [required]="ctrl.getError('required')"
      (focus)="picker.open()"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <div class="col-md-12">
    <app-show-errors [ctrl]="ctrl" [template]="template"></app-show-errors>
  </div>
</div>
