import * as moment from "moment";
export const getCurrentDate = (format = "MM/DD/YYYY") =>
  moment().format(format);

export const dateFromTimestamp = (timestamp: number, format = "MM/DD/YYYY") =>
  moment(moment.unix(timestamp)).format(format);

export const timeFromTimestamp = (timestamp: number, format = "LT") =>
  moment(moment.unix(timestamp)).format(format);

export const datetimeFromTimestamp = (timestamp: number, format = "LLL") =>
  moment(moment.unix(timestamp)).format(format);

export const timeDifference = (start, end) => {
  const now = end;
  const then = start;
  const ms = moment(moment.unix(now), "DD/MM/YYYY HH:mm:ss").diff(
    moment(moment.unix(then), "DD/MM/YYYY HH:mm:ss")
  );
  const d = moment.duration(ms);
  return `${d.hours()} hr ${d.minutes()} min`;
};
export const getCurrentTimeStamp = () => new Date().toUTCString();
export const getUnixTimeStamp = () => moment().unix();
export const convertToUnixTS = (dt) => moment(dt).unix();
export const getUTCTimeStamp = () => new Date();
export const timeDifferenceTS = (start) => {
  const now = moment().unix();
  const then = start;
  const ms = moment(moment.unix(now), "DD/MM/YYYY HH:mm:ss").diff(
    moment(moment.unix(then), "DD/MM/YYYY HH:mm:ss")
  );
  const d = moment.duration(ms);
  return `${d.minutes()} : ${d.seconds()}`;
};
export const addMonths = (duration: number) => moment().add(duration, "M");
