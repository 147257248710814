import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LABS } from "../components/labs-tree/labs-data";
import { HttpClient } from "@angular/common/http";
import { Logger } from "src/constants/logger";
import {
  pickBy,
  isObject,
  isFunction,
  transform,
  isEmpty,
  isArray,
} from "lodash";
export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  treeData: any[];
  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
    // this.initialize();
  }

  initialize() {
    // file node as children.
    const data = this.buildFileTree(LABS, 0);
    // Notify the change.
    this.dataChange.next(data);
  }
  loadLabsFromJSON(labsData) {
    let set: any = decodeURIComponent(labsData);
    set = eval(`(${set})`);
    this.treeData = set;
    Logger.Log("tree data", this.treeData);
    const data = this.buildFileTree(set, 0);
    // Notify the change.
    this.dataChange.next(data);
  }
  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: object, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;

      if (value != null) {
        if (typeof value === "object") {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  filter(filterText: string) {
    const filteredTreeData = this.deepPickBy(
      this.treeData,
      (value, key: string) => {
        return key
          .toLowerCase()
          .trim()
          .includes(filterText.toLowerCase().trim());
      }
    );

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    const data = this.buildFileTree(filteredTreeData, 0);
    // Notify the change.
    this.dataChange.next(data);
  }
  deepPickBy(obj, predicate) {
    if (!isObject(obj)) {
      throw new Error(
        "expected the first argument to be an object or an array"
      );
    }

    if (!isFunction(predicate)) {
      throw new Error("expected the second argument to be a function");
    }

    return transform(obj, (memo, val, key) => {
      var include = predicate(val, key);
      if (!include && isObject(val)) {
        val = this.deepPickBy(val, predicate);
        include = !isEmpty(val);
      }
      if (include) {
        if (isArray(obj)) {
          memo.push(val);
        } else {
          memo[key] = val;
        }
      }
    });
  }
}
