import { FormControl } from "@angular/forms";
import { parsePhoneNumberFromString as parseMobile } from "libphonenumber-js/mobile";

export function phoneValidator(ctrl: FormControl) {
  if (ctrl.value !== "" && ctrl.value !== null) {
    try {
      const phoneNumber = parseMobile(ctrl.value);
      if (!phoneNumber.isValid()) {
        throw "invalid format";
      }
      return null;
    } catch (error) {
      return { validatePhoneNumber: true };
    }
  } else {
    return null;
  }
}
