<mat-dialog-content>
  <div class="d-flex flex-column align-items-center justify-content-between">
    <i class="fas fa-paper-plane fa-3x mb-4"></i>
    <h4>Thank you, for your visit.</h4>
    <p>
      Your visit summary will be emailed to you on your registered email
      address.
    </p>
    <button mat-dialog-close color="primary" mat-flat-button [autofocus]="true">
      Ok
    </button>
  </div>
</mat-dialog-content>
