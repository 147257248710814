<div class="row-g" *ngIf="user">
  <div class="edit-button" *ngIf="enableEdit">
    <small (click)="openDialog()"><i class="fas fa-pencil-alt"></i> Edit</small>
  </div>
  <div class="col-md-12">
    <div>
      Patient Name :
      <strong>{{ user?.displayName | titlecase }}</strong>
    </div>
  </div>
  <div class="col-md-12 d-flex justify-content-between">
    <div>
      MRN : <strong>{{ user?.mrn }}</strong>
    </div>
    <div *ngIf="!hasUnix">
      DOB : <strong>{{ user?.dob | dateConverter }}</strong>
    </div>
    <div *ngIf="hasUnix">
      DOB : <strong>{{ user?.dob | dobUnix }}</strong>
    </div>
  </div>
  <div class="col-md-12 d-flex justify-content-between">
    <div>
      Gender : <strong>{{ user?.gender | titlecase }}</strong>
    </div>
    <div *ngIf="!hasUnix">
      Age : <strong>{{ user?.dob | dob }}</strong>
    </div>
    <div *ngIf="hasUnix">
      Age : <strong>{{ user?.dob | dobUnix | dob }}</strong>
    </div>
  </div>
</div>
