<div class="d-flex align-items-center">
  <div
    class="d-flex flex-column mx-3"
    *ngFor="let ratingId of ratingArr; index as i"
  >
    <button
      [matTooltip]="ratingDesc[i]"
      [matTooltipDisabled]="hideTooltip"
      mat-icon-button
      [color]="color"
      [id]="'star_' + i"
      (click)="onClick(i + 1)"
    >
      <mat-icon>
        {{ showIcon(i) }}
      </mat-icon>
    </button>
    <small class="text-muted">{{ ratingDesc[i] | titlecase }}</small>
  </div>
  <!-- <span class="font-weight-lighter ml-2 text-muted" *ngIf="count && count > 0"
    >({{ count }})</span
  > -->
</div>
