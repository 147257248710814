<mat-dialog-content>
  <h3 matDialogTitle>
    You have one more symptoms of possible coronavirus infection, would like to
    connect with a doctor now ?
  </h3>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    color="primary"
    mat-flat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Start Virtual Visit
  </button>
  <button mat-flat-button [mat-dialog-close]="false">No, Thank You</button>
</mat-dialog-actions>
