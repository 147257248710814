import { createAction, props } from "@ngrx/store";

enum actionTypes {
  SET_FLOW = "[Flow] Set Flow",
  CLEAR_FLOW = "[Flow] Clear Flow",
}

export const setFlow = createAction(
  actionTypes.SET_FLOW,
  props<{ data: string }>()
);

export const clearFlow = createAction(actionTypes.CLEAR_FLOW);
