import { InjectionToken, TemplateRef } from "@angular/core";

export class ToastData {
  type: ToastType;
  text?: string;
  template?: TemplateRef<any>;
  templateContext?: {};
  title?: string;
}

export type ToastType = "error" | "warning" | "info" | "success";
export const TOAST_COLORS = {
  ERROR: "#e53935",
  WARNING: "#ffc107",
  SUCCESS: "#4caf50",
  INFO: "#3f51b5",
};
export const TOAST_ICONS = {
  ERROR: '<i class="fas fa-times fa-2x"></i>',
  WARNING: '<i class="fas fa-exclamation-circle fa-2x"></i>',
  SUCCESS: '<i class="fas fa-check-circle fa-2x"></i>',
  INFO: '<i class="fas fa-info-circle fa-2x"></i>',
};

export interface ToastConfig {
  position?: {
    top: number;
    right: number;
  };
  animation?: {
    fadeOut: number;
    fadeIn: number;
  };
  duration?: number;
}

export const defaultToastConfig: ToastConfig = {
  position: {
    top: 20,
    right: 20,
  },
  animation: {
    fadeOut: 2500,
    fadeIn: 300,
  },
  duration: 3500,
};

export const TOAST_CONFIG_TOKEN = new InjectionToken("toast-config");
