<footer class="footer bg-sm">
  <div class="container py-5">
    <div class="row">
      <div class="col-12">
        <div class="text">
          The virtual visits services made available through
          {{ config?.name | titlecase }} are provided by licensed doctors
          practicing within a group of independently owned professional
          practices collectively known as “{{
            config?.name | titlecase
          }}
          Professionals”. These professional practices provide services via the
          {{ config?.name | titlecase }} virtual visits platform.
          {{ config?.name | titlecase }} does not itself provide any doctors,
          mental health or other healthcare provider services.
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 pl-1">
        <div class="d-flex align-items-left flex-column">
          <div class="image-wrap">
            <!-- <img src="/assets/_logo.png" class="img-fluid" alt="" /> -->
            <img
              [src]="config?.logo | safeImage"
              [alt]="config?.name"
              class="img-fluid"
            />
          </div>
          <div class="text pl-2 copyright">
            © 2020 {{ config?.name | titlecase }}, All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
