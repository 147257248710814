import { ActionReducer, MetaReducer, Action } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";
// localstorage keys
const keys = ["appConfig", "features", "selectedFeature", "user"];
// session storage keys
const sessionkeys = [
  "doctors",
  "flow",
  "selectedDoctor",
  "selectedPatient",
  "selectedRecord",
  "corona",
];
function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys,
    rehydrate: true,
    storage: localStorage,
  })(reducer);
}
function sessionStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: sessionkeys,
    rehydrate: true,
    storage: sessionStorage,
  })(reducer);
}
export const metaReducers: Array<MetaReducer<any, Action>> = [
  localStorageSyncReducer,
  sessionStorageSyncReducer,
];
