import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "safeImage",
})
export class SafeImagePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(value: any) {
    if (!value) {
      return null;
    }
    return this.domSanitizer.bypassSecurityTrustUrl(value);
  }
}
