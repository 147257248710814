import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Store } from "@ngrx/store";
import { SubSink } from "subsink";
import { LoaderService } from "src/app/loader";
import { ToastService } from "src/app/toast";
import { Logger } from "src/constants/logger";
import { map, take } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { updateUser } from "../../store/auth.action";
import { PaymentService } from "../../services/payment.service";
import { FormControl, FormGroup } from "@angular/forms";
import { HeaderService } from "src/app/core/services/header.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  providers: [PaymentService],
})
export class ProfileComponent implements OnInit, OnDestroy {
  user: any;
  private sink = new SubSink();
  cardDetails: Object;
  paymentFields = true;
  insuranceFields = true;
  personalFields = true;
  profilePicture = new FormControl(null);
  insuranceDocs = new FormGroup({
    front: new FormControl(null),
    back: new FormControl(null),
  });
  constructor(
    private authService: AuthService,
    private loader: LoaderService,
    private toast: ToastService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private store: Store<{ user: any }>,
    private payment: PaymentService,
    private header: HeaderService
  ) {}

  ngOnInit(): void {
    this.header.showPoweredBy();
    this.getCardDetails();
    this.getUser();
    this.sink.add(
      this.profilePicture.valueChanges.subscribe((p) => {
        if (p) {
          this.authService
            .updateUserDetails(this.user.uid, { photoUrl: p })
            .then((d) => {
              this.store.dispatch(updateUser({ data: { photoUrl: p } }));
            })
            .catch((e) => {});
        }
      })
    );
  }
  ngOnDestroy() {
    this.header.hidePoweredBy();
    this.sink.unsubscribe();
  }
  getUser() {
    this.store
      .select("user")
      .pipe(take(1))
      .subscribe((user) => (this.user = user));
  }
  async getCardDetails() {
    try {
      this.loader.showLoader();
      this.cardDetails = await this.payment.getUserCardDetails();
      this.loader.hideLoader();
    } catch (e) {
      Logger.Log("error fetching card", e);
      this.loader.hideLoader();
      this.toast.showError("Error fetching card details");
    }
  }
  navigateBack() {
    this.activeRoute.queryParamMap.pipe(take(1)).subscribe((d) => {
      let lastPage = "/primary-care";
      if (d.has("returnUrl")) {
        lastPage = d.get("returnUrl");
      }
      this.router.navigate([lastPage]);
    });
  }

  async updateDetails(data) {
    Logger.Log("data updated", data);
    try {
      this.personalFields = this.insuranceFields = false;
      this.loader.showLoader();
      await this.authService.updateUserDetails(this.user.uid, data);
      this.toast.showSuccess("Details updated successfully");
      this.store.dispatch(updateUser({ data }));
      this.personalFields = this.insuranceFields = true;
      window.location.reload();
    } catch (e) {
      Logger.Log("save error", e);
      this.toast.showError("Error updating details");
    } finally {
      this.loader.hideLoader();
    }
  }
  async updateCard(data) {
    try {
      this.paymentFields = false;
      this.loader.showLoader();
      await this.payment.updateUserCard(
        data.card,
        data.expiryMonth,
        data.expiryYear,
        data.cvc
      );
      this.toast.showSuccess("Card Details updated successfully");
      this.paymentFields = true;
    } catch (e) {
      Logger.Log("save error", e);
      this.toast.showError("Invalid Card details");
    } finally {
      this.loader.hideLoader();
    }
  }
}
