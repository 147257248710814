export const LABS = {
  "COVID'19": {
    "COVID'19 (Coronavirus) PCR Testing": null,
    "COVID'19 (Coronavirus) IgG Antibody Testing": null,
  },
  "General Lab Tests": {
    "Amylase/Lipase": null,
    CMP: null,
    "Blood Cultures": null,
    BUN: null,
    "Celiac Disease Panel": {
      "Cholesterol (Lipid) Panel": null,
    },
    CBC: null,
    Creatinine: null,
    "Focal Occult Blood test": null,
    Ferritin: null,
    "Free Thyroxine (Free T4)": null,
    Glucose: null,
    "Hemoglobin A1c": null,
    "H. Pylori": null,
    "INR, Prothrombin time": null,
    "Iron (Iron and TIBC)": null,
    "Liver Function Test": null,
    Magnesium: null,
    Calcium: null,
    Potassium: null,
    "Prostate Specific Antigen (PSA)": null,
    "Serum HCG": null,
    "Thyroid Stimulating Hormone (TSH)": null,
    "Uric Acid": null,
    "Urinalysis (UA)": null,
    "Urine Microalbumin": null,
  },
  "Antibody/Immunity Tests": {
    "Hep A Ab Total": null,
    "Hep B Ab, Total": null,
    "Measles, Mumps, Rubella (MMR) Immunity Profile": null,
    "Varicella Zoster Virus (VZV) Antibodies, IgG": null,
  },
  "Inflammatory/Autoimmune Tests": {
    "C'Reactive Protein (CRP)": null,
    "Erythrocyte Sedimentation Rate (ESR)": null,
    hsCRP: null,
    "Rheumatoid Factor (RF)": null,
  },
  "Vitamin Tests": {
    B12: null,
    Folate: null,
    "Vitamin D": null,
  },
  "Infectious Disease/STI Tests": {
    Chlamydia: null,
    Gonorrhea: null,
    "Hep C": null,
    HIV: null,
    "Lyme Disease Ab": null,
    Mono: null,
    "Quantiferon Gold or Tb Test": null,
    "Eapid Plasma Reagin (RPR)": null,
  },
  Cultures: {
    "Stool Cultures": null,
    "Blood Cultures": null,
    Strep: null,
    "Urine Cultures": null,
    "Wound Cultures": null,
  },
  "Hormone Tests": {
    Cortisol: null,
    Estradiol: null,
    "Follicle Stimulation Hormone (FSH)": null,
    "Leuteinizing Hormone (LH)": null,
    Prolactin: null,
    Testosterone: null,
  },
  Imaging: {
    "CT Scan": null,
    "CT Angiogram": null,
    MRI: null,
    "MR Angiogram": null,
    "X'Ray": null,
    Ultrasound: null,
    Echocardiogram: null,
    EKG: null,
  },
};
