import { Logger } from "src/constants/logger";

export const safeUnescape = (str) => {
  try {
    let t = decodeURI(str).replace(/\n/gi, "\n").trim();
    t = JSON.stringify(t);
    return t.substring(1, t.length - 1);
  } catch {
    return str;
  }
};
export const safeParseText = (str) => {
  try {
    let t = str.trim();
    // t = escape(t);
    t = safeUnescape(t);
    return t;
  } catch {
    return str;
  }
};

export function replaceAll(string, search, replace) {
  return string.split(search).join(replace);
}

export function removeComma(text: string) {
  try {
    let str = text.trim();
    str = str[str.length - 1] === "," ? str.slice(0, -1) : str;
    return str;
  } catch {
    return text;
  }
}
export function properCase(text: string) {
  try {
    let str: any = text.trim();
    str = str.split(",");
    str = str.map((s: string) => titleCase(s.trim().toLowerCase()));
    str = str.join(", ");
    str = removeComma(str);
    return str;
  } catch (e) {
    Logger.Log("propercase", e);
    return text;
  }
}
const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0) !== "o"
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word;
    })
    .join(" ");
};

export const getSelected = (data: any[]) => {
  const selected = data.filter((d) => d.selected);
  return selected.map((d) => d.key).toString();
};
