<!-- BOOTSTRAP FIELDS -->
<div class="form-group" *ngIf="template === 'bootstrap'">
  <label>{{ label | titlecase }}</label>
  <input
    *ngIf="type !== 'multiline'; else multiline"
    [type]="type"
    [formControl]="ctrl"
    [ngClass]="class"
    [name]="name"
    [placeholder]="placeholderText"
    [required]="ctrl.getError('required')"
  />
  <ng-template #multiline>
    <textarea
      [formControl]="ctrl"
      [ngClass]="class"
      [name]="name"
      [placeholder]="placeholderText"
      [required]="ctrl.getError('required')"
    ></textarea>
  </ng-template>
  <app-show-errors [ctrl]="ctrl" [template]="template"></app-show-errors>
</div>
<!-- MATERIAL FIELDS -->
<div *ngIf="template === 'material'" class="form-group row">
  <mat-form-field
    class="col-md-12"
    *ngIf="type !== 'multiline'; else multilineMat"
  >
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      [type]="type"
      [formControl]="ctrl"
      [ngClass]="class"
      [name]="name"
      [required]="ctrl.getError('required')"
      [maxLength]="maxlength"
      [placeholder]="placeholderText"
    />
  </mat-form-field>
  <ng-template #multilineMat>
    <mat-form-field class="col-md-12">
      <mat-label>{{ label }}</mat-label>
      <textarea
        matInput
        [placeholder]="placeholder"
        [formControl]="ctrl"
        [ngClass]="class"
        [name]="name"
        [required]="ctrl.getError('required')"
        [placeholder]="placeholderText"
      ></textarea>
    </mat-form-field>
  </ng-template>
  <div class="col-md-12">
    <app-show-errors [ctrl]="ctrl" [template]="template"></app-show-errors>
  </div>
</div>
