import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable()
export class PrimaryCareService {
  constructor(private afs: AngularFirestore) {}

  storeCoronaData(data) {
    return this.afs.collection("corona").add({ ...data });
  }
}
