<!-- <div class="form-group row">
  <label>{{ label }}</label>
  <input
    type="file"
    [formControl]="ctrl"
    [name]="name"
    class="col-md-12"
    (change)="onFileChange($event)"
  />
</div> -->

<div
  class="uploadfilecontainer d-flex align-items-center justify-content-center flex-column p-1"
  (click)="fileInput.click()"
  appFileDragDrop
  (onFileDropped)="onFileChange($event)"
>
  <i class="fas fa-cloud-upload-alt fa-2x"></i>
  <span>Upload {{ label }}</span>
  <input
    hidden
    type="file"
    [formControl]="ctrl"
    [name]="name"
    #fileInput
    (change)="onFileChange($event.target.files[0])"
  />
</div>
<div class="files-list" *ngIf="file !== ''">
  <span>{{ file }}</span>
  <button class="delete-file" (click)="deleteAttachment()">
    <i class="fas fa-times"></i>
  </button>
</div>
