import {
  Component,
  OnInit,
  OnDestroy,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { SubSink } from "subsink";
import { setFlow, selectFeature } from "src/app/app-config";
import { take } from "rxjs/operators";
import { ConfigModel } from "src/app/app-config/actions";
import { LoaderService } from "src/app/loader";
import { Router, ActivatedRoute } from "@angular/router";
import { Logger } from "src/constants/logger";
import { HeaderService } from "src/app/core/services/header.service";
import { AuthService } from "src/app/user";
import { ThankYouPopupComponent } from "../../components/thank-you-popup/thank-you-popup.component";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-primary-care",
  templateUrl: "./primary-care.component.html",
  styleUrls: ["./primary-care.component.scss"],
})
export class PrimaryCareComponent implements OnInit, OnDestroy, AfterViewInit {
  isLoggedIn = false;
  private sink = new SubSink();
  allFeatures: any[];
  config: ConfigModel;
  name: any;
  @ViewChildren("home,whatwetreat,whatwedonttreat,pricing,howitworks", {
    read: ElementRef,
  })
  links: QueryList<ElementRef>;
  constructor(
    private router: Router,
    private headerService: HeaderService,
    public dialog: MatDialog,
    private authService: AuthService,
    private store: Store<{
      user: any;
      router: any;
      features: any[];
      appConfig: ConfigModel;
    }>,
    private loader: LoaderService,
    private activeRoute: ActivatedRoute,
    private translocoService: TranslocoService
  ) {}

  get showTabs() {
    return window.location.host !== "ssmhealth.sevaro.com";
  }

  ngOnInit(): void {
    this.headerService.hideHeader();
    this.loader.showLoader();
    this.getUser();
    this.getFeatures();
    this.getConfig();
    this.showThankYou();
    this.getLanguage();
  }
  getLanguage() {
    this.sink.add(
      this.activeRoute.queryParamMap.subscribe((par) => {
        if (par.has("lang")) {
          this.translocoService.setActiveLang(par.get("lang"));
        }
      })
    );
  }
  showThankYou() {
    this.sink.add(
      this.activeRoute.queryParamMap.subscribe((par) => {
        if (
          par.has("message") &&
          par.has("type") &&
          par.has("status") &&
          par.get("message") === "done" &&
          par.get("type") === "patient" &&
          (par.get("status") === "finished" || par.get("status") === "drafted")
        ) {
          this.dialog.open(ThankYouPopupComponent);
        }
      })
    );
  }
  ngAfterViewInit() {
    this.sink.add(
      this.activeRoute.queryParamMap.subscribe((par) => {
        if (par.has("scroll")) {
          const ele = this.links.find(
            (d) => d.nativeElement.id === par.get("scroll")
          );
          if (ele) {
            this.scroll(ele.nativeElement, null);
          }
        }
      })
    );
  }
  ngOnDestroy() {
    this.sink.unsubscribe();
    this.headerService.showHeader();
  }
  signOut() {
    this.authService.signOut();
    this.router.navigate(["/"]);
  }
  getConfig() {
    this.sink.add(
      this.store
        .select((d) => d.appConfig)
        .pipe(take(1))
        .subscribe(
          (r) => {
            if (r && r.appId) {
              this.config = r;
            } else {
              this.router.navigate(["/"]);
              this.loader.hideLoader();
            }
          },
          (e) => {
            Logger.Log("config", e);
            this.loader.hideLoader();
          }
        )
    );
  }
  getUser() {
    this.sink.add(
      this.store
        .select("user")
        // .pipe(take(1))
        .subscribe((user) => {
          this.isLoggedIn = !!user;

          if (user) {
            this.name = user.displayName;
            const roles = !!user.role ? user.role : user.roles;
            Logger.Log("roles", roles);
            if (roles && roles.includes("doctor")) {
              this.router.navigate(["/doctor", "dashboard"]);
            }
          }
        })
    );
  }
  getFeatures() {
    this.sink.add(
      this.store
        .select("features")
        .pipe(take(1))
        .subscribe(
          (d) => {
            this.allFeatures = d;
            this.loader.hideLoader();
          },
          () => this.loader.hideLoader()
        )
    );
  }
  openDialog(type): void {
    this.store.dispatch(setFlow({ data: type }));
    if (this.config["master"]) {
      this.router.navigate(["/master"]);
    } else {
      this.router.navigate(["/doctor", "selector"]);
    }
    // const dialogRef = this.dialog.open(PrimaryCareDialogComponent, {
    //   width: "400px",
    //   data: {},
    //   hasBackdrop: true,
    //   panelClass: "custom-panel",
    // });

    //dialogRef.afterClosed().subscribe((result) => {});
  }
  doctorLogin() {
    this.router.navigate(["/login"], { queryParams: { type: "doctor" } });
  }
  patientLogin() {
    this.router.navigate(["/login"], { queryParams: { type: "patient" } });
  }
  specialCase() {
    const data = this.allFeatures.find((d) => d.featureId === 8);
    this.store.dispatch(selectFeature({ data }));
    // this.store.dispatch(setFlow({ data: "primary care" }));
    this.router.navigate(["/corona-virus"]);
  }
  scroll(el, event) {
    if (event) {
      event.preventDefault();
    }
    const element = el;
    const offset = 130;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
  navigateToProfile() {
    this.sink.add(
      this.store
        .select("router")
        .pipe(take(1))
        .subscribe((r) => {
          const lastPage = r.state.url.split("?")[0];
          this.router.navigate(["/user", "profile"], {
            queryParams: { returnUrl: lastPage },
          });
        })
    );
  }
}
