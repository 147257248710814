import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { LoaderService } from "./services/loader.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoaderInterceptor } from "./services/loader-interceptor.service";
import { MainLoaderComponent } from "./pages/main-loader/main-loader.component";

@NgModule({
  declarations: [SpinnerComponent, MainLoaderComponent],
  imports: [CommonModule],
  exports: [SpinnerComponent],
})
export class LoaderModule {
  static forRoot(): ModuleWithProviders<LoaderModule> {
    return {
      ngModule: LoaderModule,
      providers: [
        LoaderService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true,
        },
      ],
    };
  }
}
