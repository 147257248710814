<div class="container mt-4">
  <mat-card class="custome-panel py-2 px-4">
    <mat-card-content>
      <div
        class="d-flex align-items-center justify-content-center flex-column my-2"
      >
        <app-gravatar
          height="100"
          [gravatarImage]="user?.photoUrl"
          [control]="profilePicture"
        ></app-gravatar>
        <strong class="mt-2">Profile</strong>
      </div>
      <div class="row">
        <div class="col-md-6">
          <app-personal-details
            [personal]="user"
            (save)="updateDetails($event)"
            [disableFields]="personalFields"
          ></app-personal-details>
        </div>
        <div class="col-md-6">
          <app-payment-details
            [payment]="cardDetails"
            (save)="updateCard($event)"
            [disableFields]="paymentFields"
          ></app-payment-details>
          <app-insurance-details
            [doc]="user?.documents"
            [insurance]="user?.insurance"
            (save)="updateDetails($event)"
            [disableFields]="insuranceFields"
          ></app-insurance-details>
          <!-- <div class="d-flex align-items-center justify-content-between">
            <app-file-preview
              [control]="insuranceDocs.get('front')"
              type="front picture"
            ></app-file-preview>
            <app-file-preview
              [control]="insuranceDocs.get('back')"
              type="rear picture"
            ></app-file-preview>
          </div> -->
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions
      class="d-flex justify-content-center align-items-center pb-4"
    >
      <button mat-flat-button (click)="navigateBack()" class="mr-2">
        Back
      </button>
      <button mat-flat-button color="primary" (click)="navigateBack()">
        Done
      </button>
    </mat-card-actions>
  </mat-card>
</div>
