import { NgModule, ModuleWithProviders } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
  CONFIG,
} from "@angular/fire/analytics";
import { StoreModule as NgrxStoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { routerReducer } from "@ngrx/router-store";
import { environment } from "src/environments/environment";
import { metaReducers } from "./meta-reducer";
import { FileStorageService } from "./services/file-storage.service";
import { HeaderService } from "./services/header.service";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { MessagingService } from "./services/messaging.service";
import { TokenInterceptorService } from "./services/token-interceptor.service";
import { NotificationService } from "./services/notification.service";
import { BannerService } from "./services/banner.service";
import {
  AngularFirePerformanceModule,
  PerformanceMonitoringService,
} from "@angular/fire/performance";

@NgModule({
  imports: [
    HttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    NgrxStoreModule.forRoot({ router: routerReducer }, { metaReducers }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
        })
      : [],
    EffectsModule.forRoot([]),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule.enablePersistence(),
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
  ],
  exports: [
    HttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    AngularFireModule,
    AngularFireStorageModule,
    NgrxStoreModule,
    AngularFirestoreModule,
    AngularFireMessagingModule,
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
  ],
})
export class CoreModule {
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        FileStorageService,
        HeaderService,
        MessagingService,
        BannerService,
        NotificationService,
        PerformanceMonitoringService,
        ScreenTrackingService,
        UserTrackingService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptorService,
          multi: true,
        },
        {
          provide: CONFIG,
          useValue: {
            send_page_view: true,
            allow_ad_personalization_signals: false,
            anonymize_ip: true,
          },
        },
      ],
    };
  }
}
