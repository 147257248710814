<div class="d-flex flex-row-reverse align-items-center mx-4">
  <div class="top-buttons">
    <button
      *ngIf="signUpEnabled"
      mat-button
      [ngClass]="{ 'border-bottom border-info': mode === 'signup' }"
      type="button"
      (click)="changeMode('signup')"
    >
      Sign Up
    </button>
    <button
      mat-button
      [ngClass]="{ 'border-bottom border-info': mode === 'login' }"
      type="button"
      (click)="changeMode('login')"
    >
      Login
    </button>
  </div>
</div>
