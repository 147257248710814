import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
@Pipe({
  name: "timeDifference",
})
export class TimeDifferencePipe implements PipeTransform {
  transform(startTime: any, endTime: any): string {
    if (!startTime || !endTime) {
      return "-";
    } else {
      const now = endTime;
      const then = startTime;
      const ms = moment(moment.unix(now), "DD/MM/YYYY HH:mm:ss").diff(
        moment(moment.unix(then), "DD/MM/YYYY HH:mm:ss")
      );
      const d = moment.duration(ms);
      return `${d.hours()} hr ${d.minutes()} min`;
    }
  }
}
