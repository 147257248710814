import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
} from "@angular/common/http";
import { finalize, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LoggerService } from "./logger.service";

@Injectable()
export class HttpLoggerInterceptor implements HttpInterceptor {
  constructor(private logger: LoggerService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const startTime = Date.now();
    let status: string;

    return next.handle(req).pipe(
      tap(
        (event) => {
          status = "";
          if (event instanceof HttpResponse) {
            status = "succeeded";
          }
        },
        (error) => {
          status = "failed";
        }
      ),
      finalize(() => {
        const elapsedTime = Date.now() - startTime;
        const message = `${req.method} ${req.urlWithParams} ${status} in ${elapsedTime}ms`;
      })
    );
  }
}
