import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { SubSink } from "subsink";
import { AuthService } from "src/app/user";
import { Logger } from "src/constants/logger";
import { FormControl } from "@angular/forms";
import { take, map, debounce } from "rxjs/operators";
import { Router } from "@angular/router";
import { interval } from "rxjs";
import { BannerService } from "src/app/core/services/banner.service";
import { HeaderService } from "src/app/core/services/header.service";
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private sink = new SubSink();
  user: any = null;
  showProfile = true;
  status = new FormControl(false);
  bannerMsg = "";
  isMenuVisible: boolean;
  constructor(
    private store: Store<{ user: any; router: any }>,
    private bannerService: BannerService,
    private headerService: HeaderService,
    private auth: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}
  get name() {
    if (this.user) {
      if (this.user.displayName) {
        return this.user.displayName;
      } else if (this.user.personal) {
        return `${this.user.personal.name.firstName} ${this.user.personal.name.lastName}`;
      }
    }
    return "";
  }
  ngOnInit(): void {
    this.getBannerMsg();
    this.getMenuVisibility();
    this.sink.add(
      this.store
        .select((d) => d.user)
        .subscribe(
          (u) => {
            this.user = u;
            if (this.user) {
              const roles = !!this.user.roles
                ? this.user.roles
                : this.user.role;
              Logger.Log("roles", roles);
              if (roles && roles.includes("doctor")) {
                this.showProfile = false;
                this.getDoctorStatus();
              } else {
                this.showProfile = true;
              }
            }
          },
          (e) => {}
        )
    );
  }
  getBannerMsg() {
    this.sink.add(
      this.bannerService.banner$.subscribe((msg) => {
        this.bannerMsg = msg;

        this.cdr.detectChanges();
      })
    );
  }
  getMenuVisibility() {
    this.sink.add(
      this.headerService.menuObservable.subscribe((visible) => {
        this.isMenuVisible = visible;
        this.cdr.detectChanges();
      })
    );
  }
  ngOnDestroy() {
    this.sink.unsubscribe();
  }
  async logout() {
    try {
      // if (this.user) {
      //   const roles = !!this.user.roles ? this.user.roles : this.user.role;
      //   const { uid } = this.user;
      //   Logger.Log("roles", roles);
      // }
      if (this.headerService.isLogoutEnabled) {
        this.auth.signOut();
      }
      this.router.navigate(["/"]);
    } catch (e) {
      Logger.Log("error", e);
    }
  }
  getDoctorStatus() {
    this.sink.add(
      this.auth.getDoctor(this.user.uid).subscribe(
        (d) => {
          Logger.Log("doctor", d);
          if (d["available"]) {
            this.status.setValue(d["available"]);
          }
          this.monitorStatus();
        },
        (e) => Logger.Log("error", e)
      )
    );
  }
  monitorStatus() {
    this.sink.add(
      this.status.valueChanges
        .pipe(
          debounce(() => interval(1000)),
          map((d) => {
            Logger.Log(d, this.status.value);
            return d;
          })
        )
        .subscribe((s) => {
          if (this.user) {
            this.changeStatus(s);
          }
        })
    );
  }
  changeStatus(status) {
    Logger.Log("status changed");
    this.auth
      .updateOnlineStatus(this.user.uid, status)
      .then((d) => {})
      .catch((e) => {});
  }

  navigateToProfile() {
    this.sink.add(
      this.store
        .select("router")
        .pipe(take(1))
        .subscribe((r) => {
          const lastPage = r.state.url;
          this.router.navigate(["/user", "profile"], {
            queryParams: { returnUrl: lastPage },
          });
        })
    );
  }
}
