import { Action, createReducer, on } from "@ngrx/store";
import { setFlow, clearFlow } from "../actions/flow.actions";

export const flowKey = "flow";

export const initialState: string = null;

const _reducer = createReducer(
  initialState,
  on(setFlow, (state, { data }) => data),
  on(clearFlow, (state) => null)
);

export function flowReducer(state: string, action: Action) {
  return _reducer(state, action);
}
