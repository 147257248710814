import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ConfigModel } from "src/app/app-config/actions";
import { take } from "rxjs/operators";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  config: ConfigModel;
  constructor(private store: Store<{ appConfig: ConfigModel }>) {}

  ngOnInit(): void {
    this.store
      .select("appConfig")
      .pipe(take(1))
      .subscribe((config) => (this.config = config));
  }
}
