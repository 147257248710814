import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./pages/login/login.component";
import { AuthService } from "./services/auth.service";
import { SharedModule } from "../shared/shared.module";
import { FormsyModule } from "../formsy";
import { LoginButtonsComponent } from "./components/login-buttons/login-buttons.component";
import { LoginFormComponent } from "./components/login-form/login-form.component";
import { StoreModule } from "@ngrx/store";
import { authReducer, featureKey } from "./store/auth.reducer";
import { ConsentDialogComponent } from "./components/consent-dialog/consent-dialog.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { PersonalDetailsComponent } from "./components/personal-details/personal-details.component";
import { PaymentDetailsComponent } from "./components/payment-details/payment-details.component";
import { InsuranceDetailsComponent } from "./components/insurance-details/insurance-details.component";
import { RouterModule } from "@angular/router";
import { AuthGuard } from "./services/auth.guard";
import { PasswordPopupComponent } from "./components/password-popup/password-popup.component";
import { RoleGuard } from "./services/role.guard";
import { PaymentService } from "./services/payment.service";
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

@NgModule({
  declarations: [
    LoginComponent,
    LoginButtonsComponent,
    LoginFormComponent,
    ConsentDialogComponent,
    ProfileComponent,
    PersonalDetailsComponent,
    PaymentDetailsComponent,
    InsuranceDetailsComponent,
    PasswordPopupComponent,
    ResetPasswordComponent,
  ],
  imports: [
    SharedModule,
    FormsyModule,
    RouterModule,
    StoreModule.forFeature(featureKey, authReducer),
  ],
})
export class UserModule {
  public static forRoot(): ModuleWithProviders<UserModule> {
    return {
      ngModule: UserModule,
      providers: [AuthService, AuthGuard, RoleGuard],
    };
  }
}
