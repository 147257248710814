import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable()
export class PaymentService {
  constructor(private http: HttpClient, private afStore: AngularFirestore) {}

  verifyCard(
    card_number: string,
    exp_month: number,
    exp_year: number,
    cvc: string
  ) {
    return this.http
      .post<any>(`${environment.v1}/payment/verify`, {
        card_number,
        exp_month,
        exp_year,
        cvc,
      })
      .toPromise();
  }
  createHold(patient, speciality) {
    return this.http
      .post<any>(`${environment.v1}/payment/charge`, { patient, speciality })
      .toPromise();
  }
  finalizeCharge(session: string) {
    return this.http
      .put(`${environment.v1}/payment/charge`, { session })
      .toPromise();
  }
  getUserCardDetails() {
    return this.http.get<any>(`${environment.v1}/payment`).toPromise();
  }
  updateUserCard(cardNumber, expiryMonth, expiryYear, cvc) {
    const ep = `${environment.v1}/payment`;
    const data = {
      card: cardNumber,
      expiryMonth,
      expiryYear,
      cvc,
    };
    return this.http.put(ep, data).toPromise();
  }
  getCurrentTransaction(id) {
    return this.afStore
      .collection("payments", (r) =>
        r.where("transaction_id", "==", id).limit(1)
      )
      .get()
      .toPromise();
  }
}
