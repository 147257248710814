import { Injectable, Injector, Inject } from "@angular/core";
import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";

import { ToastComponent } from "./toast.component";
import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig } from "./toast-config";
import { ToastRef } from "./toast-ref";
import { MatSnackBar } from "@angular/material/snack-bar";
import { InfoComponent } from "./components/info/info.component";

@Injectable()
export class ToastService {
  private lastToast: ToastRef;

  constructor(
    private overlay: Overlay,
    private snack: MatSnackBar,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
  ) {}

  show(data: ToastData) {
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy });
    const toastRef = new ToastRef(overlayRef);
    this.lastToast = toastRef;
    const injector = this.getInjector(data, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);
    overlayRef.attach(toastPortal);
    return toastRef;
  }
  getPositionStrategy() {
    return this.overlay
      .position()
      .global()
      .top(this.getPosition())
      .right(this.toastConfig.position.right + "px");
  }
  getPosition() {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    const position = lastToastIsVisible
      ? this.lastToast.getPosition().bottom
      : this.toastConfig.position.top;

    return position + "px";
  }

  getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
    const tokens = new WeakMap();

    tokens.set(ToastData, data);
    tokens.set(ToastRef, toastRef);

    return new PortalInjector(parentInjector, tokens);
  }

  showSuccess(message) {
    this.snack.openFromComponent(InfoComponent, {
      data: { message, icon: "check_circle", color: "#81c784" },
      duration: this.toastConfig.duration,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
  showError(message) {
    this.snack.openFromComponent(InfoComponent, {
      data: { message, icon: "report", color: "#e57373" },
      duration: this.toastConfig.duration,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
  showInfo(message) {
    this.snack.openFromComponent(InfoComponent, {
      data: { message, icon: "info", color: "#64b5f6" },
      duration: this.toastConfig.duration,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
  showWarn(message) {
    this.snack.openFromComponent(InfoComponent, {
      data: { message, icon: "warning", color: "#ffd54f" },
      duration: this.toastConfig.duration,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
}
