<div>
  <mat-form-field class="w-100">
    <input
      matInput
      placeholder="Search Labs"
      (input)="filterChanged($event.target.value)"
    />
  </mat-form-field>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodeToggle
      matTreeNodePadding
    >
      <button mat-icon-button disabled></button>
      <mat-checkbox
        color="primary"
        class="checklist-leaf-node"
        [checked]="checklistSelection.isSelected(node)"
        (change)="checklistSelection.toggle(node)"
        >{{ node.item }}</mat-checkbox
      >
    </mat-tree-node>

    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
    >
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'toggle ' + node.filename"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <mat-checkbox
        color="primary"
        [checked]="descendantsAllSelected(node)"
        [indeterminate]="descendantsPartiallySelected(node)"
        (change)="todoItemSelectionToggle(node)"
        >{{ node.item }}</mat-checkbox
      >
    </mat-tree-node>
  </mat-tree>
  <section>
    <mat-checkbox
      [formControl]="other"
      color="primary"
      class="checklist-leaf-node"
      >Other</mat-checkbox
    >
    <input
      type="text"
      class="form-control"
      [formControl]="otherLabs"
      *ngIf="other.value"
    />
  </section>
</div>
