import { Validators } from "@angular/forms";
import { TEMPLATE_ENGINES, FormConfig } from "src/app/formsy";
import {
  creditCardValidator,
  cvcValidator,
} from "src/app/formsy/validators/payment.validators";
import { phoneValidator } from "src/app/formsy/validators/phone-validator";
import { emailValidator } from "src/app/formsy/validators/email-validator";
import { nameValidator } from "src/app/formsy/validators/name.validator";
export const RELATIONSHIPS = [
  "Self",
  "Mother",
  "Father",
  "Daughter",
  "Son",
  "Sister",
  "Brother",
  "Auntie",
  "Uncle",
  "Niece",
  "Nephew",
  "Cousin (female)",
  "Cousin (male)",
  "Grandmother",
  "Grandfather",
  "Granddaughter",
  "Grandson",
  "Stepsister",
  "Stepbrother",
  "Stepmother",
  "Stepfather",
  "Stepdaughter",
  "Stepson",
  "Sister-in-law",
  "Brother-in-law",
  "Mother-in-law",
  "Father-in-law",
  "Daughter-in-law",
  "Son-in-law",
  "Sibling (gender neutral)",
  "Parent (gender neutral)",
  "Child (gender neutral)",
  "Sibling of Parent (gender neutral)",
  "Child of Sibling (gender neutral)",
  "Cousin (gender neutral)",
  "Grandparent (gender neutral)",
  "Grandchild (gender neutral)",
  "Step-sibling (gender neutral)",
  "Step-parent (gender neutral)",
  "Stepchild (gender neutral)",
  "Sibling-in-law (gender neutral)",
  "Parent-in-law (gender neutral)",
  "Child-in-law (gender neutral)",
  "Family member (gender neutral)",
];
export const PaymentDetailsFormModel: FormConfig = {
  templateEngine: TEMPLATE_ENGINES.MATERIAL,
  controls: [
    {
      controlType: "control",
      name: "cardNumber",
      label: "Card Number",
      type: "text",
      placeholder: "XXXX-XXXX-XXXX-XXXX",
      validators: [Validators.required, creditCardValidator],
      ctrlSize: 6,
      maxlength: 20,
    },
    {
      controlType: "control",
      name: "expiry",
      label: "MM/YYYY",
      type: "monthYearCalendar",
      validators: [Validators.required],
      ctrlSize: 2,
    },
    {
      controlType: "control",
      name: "cvv",
      label: "CVV",
      type: "text",
      validators: [Validators.required, cvcValidator],
      ctrlSize: 2,
      maxlength: 4,
    },
    {
      controlType: "control",
      name: "promoCode",
      label: "Promo Code",
      type: "text",
      ctrlSize: 2,
    },
  ],
};
export const PersonalDetailsFormModel: FormConfig = {
  templateEngine: TEMPLATE_ENGINES.MATERIAL,
  controls: [
    {
      controlType: "control",
      name: "firstName",
      label: "First Name",
      type: "text",
      validators: [Validators.required, nameValidator],
      ctrlSize: 3,
    },
    {
      controlType: "control",
      name: "middleName",
      label: "Middle Name",
      validators: [nameValidator],
      type: "text",
      ctrlSize: 3,
    },
    {
      controlType: "control",
      name: "lastName",
      label: "Last Name",
      type: "text",
      validators: [Validators.required, nameValidator],
      ctrlSize: 3,
    },
    {
      controlType: "control",
      name: "dob",
      label: "DOB",
      placeholder: "MM/DD/YYYY",
      type: "calendar",
      validators: [Validators.required],
      ctrlSize: 3,
    },
    {
      controlType: "control",
      name: "gender",
      label: "Gender",
      type: "radio",
      inline: true,
      ctrlSize: 4,
      options: [
        { key: "male", value: "Male" },
        { key: "female", value: "Female" },
      ],
      validators: [Validators.required],
    },
    {
      controlType: "control",
      name: "contact",
      label: "Phone Number",
      type: "phone",
      options: ["us", "in"],
      validators: [Validators.required, phoneValidator],
      ctrlSize: 4,
    },
    {
      controlType: "control",
      name: "email",
      label: "Email",
      type: "text",
      validators: [Validators.required, emailValidator],
      ctrlSize: 4,
    },
    // {
    //   name: "consulting",
    //   type: "dropdown",
    //   controlType: "control",
    //   label: "",
    //   validators: [Validators.required],
    //   options: [
    //     { key: 1, value: "Lifepoint Health" },
    //     { key: 2, value: "ABC Health" },
    //     { key: 3, value: "XYZ Health" },
    //   ],
    //   value: 1,
    //   ctrlSize: 6,
    // },
  ],
};
export const InsuranceDetailsFormModel: FormConfig = {
  templateEngine: TEMPLATE_ENGINES.MATERIAL,
  controls: [
    {
      controlType: "control",
      name: "company",
      label: "Company Name",
      type: "text",
      ctrlSize: 3,
    },
    {
      controlType: "control",
      name: "groupId",
      type: "text",
      label: "Group ID",
      ctrlSize: 3,
    },
    {
      controlType: "control",
      name: "insuredName",
      label: "Insured Name",
      validators: [nameValidator],
      type: "text",
      ctrlSize: 3,
    },
    {
      controlType: "control",
      name: "relationship",
      label: "Relationship",
      type: "dropdown",
      ctrlSize: 3,
      options: RELATIONSHIPS.map((r) => ({ key: r, value: r })),
    },
  ],
};
