<h1 mat-dialog-title>Patient Verification Consent</h1>
<mat-dialog-content>
  <h4>Sevaro Telehealth Patient Consent</h4>
  <div>
    <p>
      Having a condition requiring health care, or having responsibility for an
      individual(patient) requiring health care, I hereby consent to diagnosis
      and the provision of such care via video conference. I recognize that
      while this treatment approach provides the benefits of convenient access
      to care and cost and allows treatment at home or another location of my
      choosing, there are inherent limitations to this approach. I acknowledge
      that the telehealth medical provider will not be in the room with me to
      conduct a physical examination. I also acknowledge that the effectiveness
      of this treatment interaction will depend on how a full disclosure of a
      medical history and symptoms to the telehealth medical provider, and that
      failure to do so could result in adverse drug reaction, allergic reactions
      or other medical problems. I acknowledge that the video conference
      interface may not give the telehealth medical provider the same
      information that would be available during an in-person examination, and
      that this could limit or affect the telehealth medical provider's
      assessment or diagnosis of a condition and recommended treatment
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-center">
  <button mat-button [mat-dialog-close]="true">
    Return <i class="fas fa-chevron-circle-right"></i>
  </button>
</mat-dialog-actions>
