import { Action, createReducer, on } from "@ngrx/store";
import { setId, clearId } from "./corona.actions";

export const initialState: any = null;

const _reducer = createReducer(
  initialState,
  on(setId, (state, { data }) => data),
  on(clearId, (state) => null)
);

export function coronaReducer(state: any, action: Action) {
  return _reducer(state, action);
}
