import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { ToastService } from "src/app/toast";

@Component({
  selector: "app-file-preview",
  templateUrl: "./file-preview.component.html",
  styleUrls: ["./file-preview.component.scss"],
})
export class FilePreviewComponent implements OnInit, OnChanges {
  filePreview: string | ArrayBuffer = null;
  @Input("control") ctrl: FormControl;
  @Input() file: string | ArrayBuffer;
  @Input() type: string = "document";
  @Input() height: number = 50;
  // isLoading: boolean;
  constructor(
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.file && changes.file.currentValue) {
      this.filePreview = changes.file.currentValue;
    }
  }
  clearCtrl() {
    this.ctrl.setValue(null);
    this.filePreview = this.file = null;
  }
  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.validateFile(file);
      // this.previewFile(file);
    }
  }
  validateFile(file) {
    if (file.size > 1024 * 1024) {
      this.toastService.showError("Upload file size should be less than 1MB");
      this.ctrl.setValue(null);
    } else {
      this.previewFile(file);
    }
  }
  previewFile(file) {
    // this.isLoading = true;
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        this.filePreview = reader.result;
        this.ctrl.setValue(this.filePreview);
        // this.isLoading = false;
        this.cdr.detectChanges();
      },
      false
    );
    // reader.addEventListener("error", () => (this.isLoading = false));

    if (file) {
      reader.readAsDataURL(file);
    } else {
      // this.isLoading = false;
    }
  }
}
