import { Injectable } from "@angular/core";
import moment from "moment";
import { AngularFirestore } from "@angular/fire/firestore";
@Injectable()
export class LoggerService {
  constructor(private afs: AngularFirestore) {}

  async logError(...errors) {
    try {
      await this.afs.collection("app-errors").add({
        data: errors.toString(),
        timestamp: moment().format("LLLL"),
      });
    } catch (e) {
      console.log("error occured", e);
    }
  }
}
