import { NgModule, ModuleWithProviders } from "@angular/core";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatIconModule } from "@angular/material/icon";

import { ToastComponent } from "./toast.component";
import { defaultToastConfig, TOAST_CONFIG_TOKEN } from "./toast-config";
import { ToastService } from "./toast.service";
import { CommonModule } from "@angular/common";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SuccessComponent } from './components/success/success.component';
import { ErrorComponent } from './components/error/error.component';
import { InfoComponent } from './components/info/info.component';
import { WarnComponent } from './components/warn/warn.component';

@NgModule({
  imports: [OverlayModule, MatIconModule, CommonModule, MatSnackBarModule],
  declarations: [ToastComponent, SuccessComponent, ErrorComponent, InfoComponent, WarnComponent],
  entryComponents: [ToastComponent],
})
export class ToastModule {
  public static forRoot(config = defaultToastConfig): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [
        ToastService,
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...config },
        },
      ],
    };
  }
}
