import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { ToastService } from "src/app/toast";

@Component({
  selector: "app-gravatar",
  templateUrl: "./gravatar.component.html",
  styleUrls: ["./gravatar.component.scss"],
})
export class GravatarComponent implements OnChanges {
  @Input() gravatarImage: string;
  @Input() height: number = 50;
  @Input("control") ctrl: FormControl;
  @Input() uploadMode = true;
  filePreview: string | ArrayBuffer;
  constructor(private toastService: ToastService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.gravatarImage && changes.gravatarImage.currentValue) {
      this.filePreview = changes.gravatarImage.currentValue;
    }
  }
  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      // this.previewFile(file);
      this.validateFile(file);
    }
  }
  validateFile(file) {
    if (file.size > 1024 * 1024) {
      this.toastService.showError("Upload file size should be less than 1MB");
      this.ctrl.setValue(null);
    } else {
      this.previewFile(file);
    }
  }
  previewFile(file) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        this.filePreview = reader.result;
        this.ctrl.setValue(this.filePreview);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }
}
