import { Component, OnInit, OnDestroy } from "@angular/core";
import { ConfigModel } from "src/app/app-config/actions";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { SubSink } from "subsink";
import { Router } from "@angular/router";

@Component({
  selector: "app-logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"],
})
export class LogoComponent implements OnInit, OnDestroy {
  logo: string;
  store$: Observable<ConfigModel>;
  sink = new SubSink();
  constructor(
    private store: Store<{ appConfig: ConfigModel }>,
    private router: Router
  ) {
    this.store$ = this.store.pipe(select("appConfig"));
  }
  navToHome() {
    this.router.navigate(["/"]);
  }
  ngOnInit(): void {
    this.sink.add(
      this.store$.subscribe(
        (d) => {
          if (d !== null) {
            this.logo = d.logo;
          }
        },
        (err) => {}
      )
    );
  }
  ngOnDestroy() {
    this.sink.unsubscribe();
  }
}
