import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  private loadingStatus: boolean = false;
  private showBackdrop: boolean = true;
  private loadingSubject = new Subject<boolean>();
  loadingObservable = this.loadingSubject.asObservable();
  constructor() {}
  get backdrop() {
    return this.showBackdrop;
  }
  showLoader() {
    this.loadingStatus = true;
    this.showBackdrop = true;
    this.loadingSubject.next(this.loadingStatus);
  }
  showLoaderWithoutBackdrop() {
    this.loadingStatus = true;
    this.showBackdrop = false;
    this.loadingSubject.next(this.loadingStatus);
  }
  hideLoader() {
    this.loadingStatus = false;
    this.showBackdrop = true;
    this.loadingSubject.next(this.loadingStatus);
  }
}
