import { Validators } from "@angular/forms";

type FieldTypes =
  | "text"
  | "maskedtextbox"
  | "password"
  | "email"
  | "number"
  | "dropdown"
  | "radio"
  | "checkbox"
  | "multiline"
  | "calendar"
  | "phone"
  | "monthYearCalendar";

export type FieldUpdates = "blur" | "change" | "submit";

export class FieldConfig {
  controlType?: "control" | "group" | "array" = "control";
  type?: FieldTypes = "text";
  name: string;
  controls?: FieldConfig | FieldConfig[];
  label?: string;
  placeholder?: string;
  value?: any = null;
  classes?: string | string[];
  validators?: Validators[];
  options?: FieldOptions[] | string[];
  updateOn?: FieldUpdates = "blur";
  ctrlSize?: number = 12;
  maxlength?: number = 50;
  mask?: string;
  inline?: boolean;
}

class FieldOptions {
  key: any;
  value: any;
}
