import { Component, OnInit } from "@angular/core";
import { FormConfig, TEMPLATE_ENGINES } from "src/app/formsy";
import { Validators, FormGroup } from "@angular/forms";
import { emailValidator } from "src/app/formsy/validators/email-validator";
import { phoneValidator } from "src/app/formsy/validators/phone-validator";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-user-meta-form",
  templateUrl: "./user-meta-form.component.html",
  styleUrls: ["./user-meta-form.component.scss"],
})
export class UserMetaFormComponent implements OnInit {
  userForm: FormConfig = null;
  form: FormGroup;
  constructor(private dialogRef: MatDialogRef<UserMetaFormComponent>) {
    this.userForm = {
      templateEngine: TEMPLATE_ENGINES.MATERIAL,
      controls: [
        {
          type: "text",
          controlType: "control",
          ctrlSize: 12,
          label: "Name",
          name: "name",
          validators: [Validators.required],
        },
        {
          type: "text",
          controlType: "control",
          ctrlSize: 12,
          label: "Email",
          name: "email",
          validators: [Validators.required, emailValidator],
        },
        {
          type: "text",
          controlType: "control",
          ctrlSize: 12,
          label: "Contact",
          name: "contact",
          validators: [Validators.required, phoneValidator],
        },
      ],
    };
  }

  ngOnInit(): void {}

  getForm(form: FormGroup) {
    this.form = form;
  }

  submit() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    } else {
      this.form.markAllAsTouched();
    }
  }
}
