import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class HeaderService {
  private _logoutEnabled = true;

  private _showHeader = true;
  private headerSubject = new BehaviorSubject<boolean>(this._showHeader);
  headerObservable = this.headerSubject.asObservable();

  private _showMenu = true;
  private menuSubject = new BehaviorSubject<boolean>(this._showMenu);
  menuObservable = this.menuSubject.asObservable();

  private _poweredBy: boolean = false;
  private poweredBySubject = new BehaviorSubject<boolean>(this._poweredBy);
  poweredbyObservable = this.poweredBySubject.asObservable();

  constructor() {}
  get isLogoutEnabled() {
    return this._logoutEnabled;
  }
  enableLogout() {
    this._logoutEnabled = true;
  }
  disableLogout() {
    this._logoutEnabled = false;
  }
  showHeader() {
    this._showHeader = true;
    this.headerSubject.next(true);
  }
  hideHeader() {
    this._showHeader = false;
    this.headerSubject.next(false);
  }
  showMenu() {
    this._showMenu = true;
    this.menuSubject.next(true);
  }
  hideMenu() {
    this._showMenu = false;
    this.menuSubject.next(false);
  }
  showPoweredBy() {
    this._poweredBy = true;
    this.poweredBySubject.next(true);
  }
  hidePoweredBy() {
    this._poweredBy = false;
    this.poweredBySubject.next(false);
  }
}
