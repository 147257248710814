<mat-form-field class="col-md-12 p-0" [appearance]="appearance">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [matDatepicker]="dp"
    [formControl]="ctrl"
    [disabled]="disabled"
    [min]="todayDate"
    (focus)="dp.open()"
  />
  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker
    #dp
    startView="multi-year"
    (yearSelected)="chosenYearHandler($event)"
    (monthSelected)="chosenMonthHandler($event, dp)"
    panelClass="example-month-picker"
  >
  </mat-datepicker>
</mat-form-field>
