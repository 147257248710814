import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { TEMPLATE_ENGINES } from "../../models/form-config.model";

@Component({
  selector: "app-file",
  templateUrl: "./file.component.html",
  styleUrls: ["./file.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileComponent implements OnInit {
  @Input() label: string;
  @Input() name: string;
  @Input() class: string;
  @Input() ctrl: FormControl;
  @Input() placeholder: string;
  @Input() template: TEMPLATE_ENGINES;
  @Output() fileSelect = new EventEmitter();
  file = "";
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}
  onFileChange(event) {
    const file = event;
    this.file = file.name;
    this.fileSelect.emit(file);
    this.cdr.markForCheck();
  }
  deleteAttachment() {
    this.file = "";
    this.fileSelect.emit(null);
  }
}
