export const environment = {
  production: false,
  howlerAudio:
    "https://cdnjs.cloudflare.com/ajax/libs/howler/2.2.0/howler.min.js",
  v1: "https://sevaro-dcbd6.uc.r.appspot.com",
  //v1: "http://localhost:3000",
  waitTimeout: 5,
  autoClose: 15,
  search: "2b5dcc4003243b5c1bd73f20249d6a15", // "75bf9be732f9fd6afe48ec28596e9626", //"f836cf5de006a943c496850c3e530909",
  appId: "JQSVR045G5", //"6Y99TX1R9A" //"MMPINSZO58",
  firebaseConfig: {
    apiKey: "AIzaSyBrzi6GygHg4xwYY1Ufwzocpg9pc2bH_-k",
    authDomain: "sevaro-dcbd6.firebaseapp.com",
    databaseURL: "https://sevaro-dcbd6.firebaseio.com",
    projectId: "sevaro-dcbd6",
    storageBucket: "sevaro-dcbd6.appspot.com",
    messagingSenderId: "191213195702",
    appId: "1:191213195702:web:33c40936ff59ad3f1a3117",
    measurementId: "G-JFSKKYXG1H",
    functions: "https://us-central1-sevaro-6b823.cloudfunctions.net",
  },
};
