import {
  Component,
  OnChanges,
  ViewEncapsulation,
  Input,
  SimpleChanges,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from "@angular/core";
import { NgLog } from "src/app/logger";

@Component({
  selector: "app-check-list",
  templateUrl: "./check-list.component.html",
  styleUrls: ["./check-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
@NgLog()
export class CheckListComponent implements OnChanges {
  @Input() data: any[];
  @Input() size: number = 4;
  checklistArr: { selected: boolean; value: any; key: any }[];
  @Output() selected = new EventEmitter();
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.data &&
      changes.data.currentValue &&
      changes.data.currentValue.length > 0
    ) {
      if (!changes.data.currentValue[0].key) {
        this.checklistArr = changes.data.currentValue.map((d) => ({
          key: d,
          value: d,
          selected: false,
          addnData: null,
        }));
      } else {
        this.checklistArr = [...changes.data.currentValue];
      }
    }
  }
  updateData(field, checked) {
    if (field.key === "none") {
      this.noneSelector();
    } else {
      this.checklistArr = this.checklistArr.map((p) => {
        if (p.key === "none") {
          return { ...p, selected: false };
        }
        return p;
      });
      this.checklistArr = this.checklistArr.map((p) => {
        if (p.key === field.key) {
          return { ...p, selected: checked };
        }
        return p;
      });
      this.selected.emit(this.checklistArr);
    }
  }
  noneSelector() {
    this.checklistArr = this.checklistArr.map((d) => {
      if (d.key !== "none") {
        return {
          ...d,
          selected: false,
        };
      } else {
        return {
          ...d,
          selected: true,
        };
      }
    });

    this.checklistArr.find((p) => p.key === "none").selected = true;
    this.selected.emit(this.checklistArr);
  }
}
