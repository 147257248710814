import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { TEMPLATE_ENGINES } from "../../models/form-config.model";

@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"],
})
export class PasswordComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() name: string;
  @Input() class: string;
  @Input() ctrl: FormControl;
  @Input() placeholder: string;
  @Input() template: TEMPLATE_ENGINES;
  placeholderText: any;
  type = "password";
  passwordIcon = "visibility";
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && !!changes.placeholder && changes.placeholder.currentValue) {
      this.placeholderText = changes.placeholder.currentValue;
    }
  }
  showHide() {
    if (this.passwordIcon === "visibility_off") {
      this.type = "password";
      this.passwordIcon = "visibility";
    } else {
      this.type = "text";
      this.passwordIcon = "visibility_off";
    }
  }
}
