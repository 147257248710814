import { Action, createReducer, on } from "@ngrx/store";
import { storeFeatures, clearFeatures, FeaturesModel } from "../actions";

export const featureKey = "features";

export const initialState: FeaturesModel = null;

const _featureReducer = createReducer(
  initialState,
  on(storeFeatures, (state, { data }) => data),
  on(clearFeatures, (state) => null)
);

export function featureReducer(state: FeaturesModel, action: Action) {
  return _featureReducer(state, action);
}
