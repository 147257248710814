<div class="d-flex text-info justify-content-between align-items-center mb-2">
  <span>Personal Details</span>
  <button
    mat-icon-button
    color="primary"
    (click)="enableEdit()"
    *ngIf="disabled"
  >
    <i class="fas fa-pencil-alt"></i>
  </button>
  <div class="d-flex" *ngIf="!disabled">
    <button class="btn btn-info btn-sm px-1 py-0 mr-2" (click)="saveDetails()">
      Save
    </button>
    <button
      class="btn btn-outline-dark btn-sm px-1 py-0"
      (click)="cancelEdit()"
    >
      Cancel
    </button>
  </div>
</div>
<form class="row p-0">
  <mat-form-field class="col-md-12" *ngIf="disabled; else nameFields">
    <mat-label>Full Name</mat-label>
    <input matInput [disabled]="disabled" [value]="fullName" />
  </mat-form-field>

  <ng-template #nameFields>
    <div class="col-md-12">
      <app-form-builder
        [model]="nameModel"
        (onLoad)="getNameForm($event)"
      ></app-form-builder>
    </div>
  </ng-template>

  <div class="col-md-6 form-inline">
    <label class="mr-2">Gender</label>
    <mat-radio-group
      aria-label="Select an option"
      class="d-flex"
      color="primary"
      [disabled]="disabled"
      [formControl]="personalDetails.get('gender')"
    >
      <mat-radio-button value="male" class="mr-2">Male</mat-radio-button>
      <mat-radio-button value="female">Female</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="col-md-6">
    <mat-form-field class="w-100">
      <input
        matInput
        [matDatepicker]="picker"
        placeholder="DOB"
        [disabled]="disabled"
        [max]="today"
        [formControl]="personalDetails.get('dob')"
        (focus)="picker.open()"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <app-show-errors
      [ctrl]="personalDetails.get('dob')"
      template="material"
    ></app-show-errors>
  </div>
  <mat-form-field class="col-md-12" *ngIf="disabled; else editContact">
    <mat-label>Cell Phone Number</mat-label>
    <input matInput [value]="personal?.contact" disabled />
  </mat-form-field>
  <ng-template #editContact>
    <div class="col-md-12">
      <!-- <mat-form-field class="w-100">
        <mat-label>Cell Phone Number</mat-label>
        <input matInput [formControl]="personalDetails.get('phone')" />
      </mat-form-field>
      <app-show-errors
        [ctrl]="personalDetails.get('phone')"
        template="material"
      ></app-show-errors> -->
      <app-phone
        [ctrl]="personalDetails.get('phone')"
        label="Cell Phone Number"
        template="material"
      ></app-phone>
    </div>
  </ng-template>

  <!-- <div class="d-flex align-items-center justify-content-between col-md-12">
    <mat-form-field class="col px-0">
      <mat-label>Password</mat-label>
      <input matInput type="password" disabled value="password_hidden" />
    </mat-form-field>
    <button class="btn btn-info btn-sm px-1 py-0 float-right">
      Change Password
    </button>
  </div> -->
</form>
<!-- <pre>{{ personalDetails.value | json }}</pre> -->
