import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Logger } from "src/constants/logger";

@Injectable()
export class NotificationService {
  constructor(private http: HttpClient) {}

  sendMessage(type, doctor, patient, client) {
    return this.http
      .post(`${environment.v1}/messages`, {
        type,
        doctor,
        patient,
        client,
      })
      .toPromise();
  }
  sendPreNotification(doctor, client) {
    Logger.Log("pre notification called");
    return this.http
      .post(`${environment.v1}/messages/pre`, {
        doctor,
        client,
      })
      .toPromise();
  }
  sendPostMessage(session) {
    Logger.Log("post notification called");
    return this.http
      .post(`${environment.v1}/messages/post`, {
        session,
      })
      .toPromise();
  }
  sendPatientJoined(session) {
    Logger.Log("patient joined notification called");
    return this.http
      .post(`${environment.v1}/messages/patientjoined`, {
        session,
      })
      .toPromise();
  }
  sendWaitingMessage(session) {
    return this.http
      .post(`${environment.v1}/messages/waiting`, {
        session,
      })
      .toPromise();
  }
  sendCompletionMessage(session) {
    return this.http
      .post(`${environment.v1}/messages/complete`, {
        session,
      })
      .toPromise();
  }
  sendDocJoinedMessage(session) {
    return this.http
      .post(`${environment.v1}/messages/joined`, {
        session,
      })
      .toPromise();
  }
}
