import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { ConfigModel } from "src/app/app-config/actions";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { ToastService } from "src/app/toast";
import { getAppFeatures, selectFeature } from "src/app/app-config";
import { SubSink } from "subsink";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit, OnDestroy {
  store$: Observable<ConfigModel>;
  features$: Observable<any[]>;
  allFeatures$: Observable<any[]>;
  private sink = new SubSink();
  allFeatures: any[];
  constructor(
    private store: Store<{ features: any[] }>,
    private router: Router,
    private toast: ToastService
  ) {
    this.features$ = this.store.select(getAppFeatures);
    this.allFeatures$ = this.store.select("features");
  }

  ngOnInit(): void {
    this.sink.add(
      this.allFeatures$.subscribe(
        (d) => (this.allFeatures = d),
        (e) => {}
      )
    );
  }
  ngOnDestroy() {
    this.sink.unsubscribe();
  }
  navigateToPage(feature: any) {
    this.selectFeature(feature.value);
    if (feature.selected && feature.key === 8) {
      this.router.navigate(["/"]);
    } else if (feature.selected) {
      this.router.navigate(["/login"]);
    } else {
      this.toast.showError("Feature Not Available");
    }
  }
  selectFeature(f: string) {
    const data = this.allFeatures.find((d) => d.featureName === f);
    this.store.dispatch(selectFeature({ data }));
  }
}
