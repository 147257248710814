<div class="w-100">
  <div class="row h-100">
    <div class="col-md-7 d-flex flex-column clearfix p-0">
      <img src="/assets/login.png" alt="login" class="img-thumbnail" />
    </div>
    <div
      class="col-md-5 d-flex align-items-center justify-content-between login-box py-5"
    >
      <app-login-form
        class=""
        [type]="type"
        [socials]="featureSelected?.socials"
        *ngIf="loginModel !== null"
      ></app-login-form>
    </div>
  </div>
</div>
