import { Pipe, PipeTransform } from "@angular/core";
import { parsePhoneNumberFromString } from "libphonenumber-js";
@Pipe({
  name: "formatPhone",
})
export class FormatPhonePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return null;
    } else {
      const p = parsePhoneNumberFromString(value);
      return `+${p.countryCallingCode} ${p.formatNational()}`;
    }
  }
}
