import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ErrorPageComponent } from "./error";
// import { MainComponent } from "./pages/main/main.component";
import { LoginComponent } from "./user";
import { ProfileComponent } from "./user/pages/profile/profile.component";
import { AuthGuard } from "./user/services/auth.guard";
import { CoronaVirusComponent } from "./primary-care/pages/corona-virus/corona-virus.component";
import { PrimaryCareComponent } from "./primary-care/pages/primary-care/primary-care.component";
import { MasterListComponent } from "./app-config/pages/master-list/master-list.component";
import { MasterGuard } from "./app-config/services/master.guard";
import { ResetPasswordComponent } from "./user/pages/reset-password/reset-password.component";
import { NetworkAwarePreloadStrategy } from "./core/services/network-aware-preloading-strategy.service";

const routes: Routes = [
  // index/config route
  {
    path: "",
    // redirectTo: "primary-care",
    component: PrimaryCareComponent,
    pathMatch: "full",
    data: { title: "Sevaro" },
  },
  // home page
  // { path: "home", component: MainComponent, canActivate: [] },
  // login page
  {
    path: "login",
    component: LoginComponent,
    canActivate: [],
    data: { checkSelectedFeature: true, title: "Login" },
  },
  // master page
  {
    path: "master",
    component: MasterListComponent,
    data: { checkSelectedFeature: true, title: "Practices" },
    canActivate: [MasterGuard],
  },
  // profile page
  {
    path: "user/profile",
    component: ProfileComponent,
//     loadChildren: () =>
//       import("./patient-profile/patient-profile.module").then(
//         (m) => m.PatientProfileModule
//       ),
    canActivate: [AuthGuard],
    data: { title: "Profile" },
  },
  // reset password
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    canActivate: [],
    data: { title: "Reset Password" },
  },
  // primary care
  // {
  //   path: "primary-care",
  //   component: PrimaryCareComponent,
  //   data: { title: "Primary Care" },
  // },
  {
    path: "corona-virus",
    component: CoronaVirusComponent,
    data: { title: "Corona Virus" },
  },
  // video
  {
    path: "video",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./video/video.module").then((m) => m.VideoModule),
  },
  // doctor
  {
    path: "doctor",
    loadChildren: () =>
      import("./doctor/doctor.module").then((m) => m.DoctorModule),
  },
  // patient feature
  {
    path: "patient",
    loadChildren: () =>
      import("./patient/patient.module").then((m) => m.PatientModule),
    canActivate: [AuthGuard],
    data: { checkSelectedFeature: true },
  },
  // 404 Route
  { path: "**", component: ErrorPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NetworkAwarePreloadStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
