import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormConfig } from "src/app/formsy";
import { LoginFormModel, SignupFormModel } from "../../models/login-form.model";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { SubSink } from "subsink";
import { HeaderService } from "src/app/core/services/header.service";
import { FeaturesModel, FeaturesMeta } from "src/app/app-config/actions";
import { take } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginModel: FormConfig = null;
  mode = "login";
  private sink = new SubSink();
  featureSelected: FeaturesMeta;
  allFeatures: FeaturesModel = null;
  type: any;

  constructor(
    private header: HeaderService,
    private store: Store<{ features: FeaturesModel }>,
    private activeRoute: ActivatedRoute
  ) {
    this.loginModel = LoginFormModel;
  }
  ngOnInit(): void {
    this.header.showPoweredBy();
    this.sink.add(
      this.store
        .select("features")
        .pipe(take(1))
        .subscribe((f) => {
          this.allFeatures = f;
          this.getType();
        })
    );
  }
  ngOnDestroy() {
    this.header.hidePoweredBy();
    this.sink.unsubscribe();
  }
  getType() {
    this.sink.add(
      this.activeRoute.queryParamMap.subscribe((par) => {
        if (par.has("mode")) {
          this.changeMode(par.get("mode"));
        }
        this.checkUserType(par.get("type"));
      })
    );
  }
  checkUserType(type) {
    if (type) {
      this.type = type;
      this.featureSelected = this.allFeatures[type];
    } else {
      this.featureSelected = this.allFeatures.patient;
    }
  }
  changeMode(type) {
    this.mode = type;
    if (type === "signup") {
      this.loginModel = SignupFormModel;
    } else {
      this.loginModel = LoginFormModel;
    }
  }
}
