import { Pipe, PipeTransform } from "@angular/core";
import { removeComma } from "src/app/utils/text";

@Pipe({
  name: "trimComma",
})
export class TrimCommaPipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return null;
    }
    return removeComma(value);
  }
}
