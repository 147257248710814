import { Component, OnInit } from "@angular/core";
import { HeaderService } from "src/app/core/services/header.service";

@Component({
  selector: "app-powered-by",
  templateUrl: "./powered-by.component.html",
  styleUrls: ["./powered-by.component.scss"],
})
export class PoweredByComponent implements OnInit {
  poweredBy$ = null;
  constructor(private headerService: HeaderService) {
    this.poweredBy$ = this.headerService.poweredbyObservable;
  }

  ngOnInit(): void {}
}
