<!-- BOOTSTRAP FIELDS -->
<div class="form-group" *ngIf="template === 'bootstrap'">
  <label>{{ label | titlecase }}</label>
  <input
    [type]="type"
    [formControl]="ctrl"
    [ngClass]="class"
    [name]="name"
    [placeholder]="placeholderText"
    [required]="ctrl.getError('required')"
  />

  <app-show-errors [ctrl]="ctrl" [template]="template"></app-show-errors>
</div>
<!-- MATERIAL FIELDS -->
<div *ngIf="template === 'material'" class="form-group row">
  <mat-form-field class="col-md-12">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      [type]="type"
      [formControl]="ctrl"
      [ngClass]="class"
      [name]="name"
      [required]="ctrl.getError('required')"
      [placeholder]="placeholderText"
    />
    <button mat-icon-button matSuffix (click)="showHide()" type="button">
      <mat-icon>{{ passwordIcon }}</mat-icon>
    </button>
  </mat-form-field>

  <div class="col-md-12">
    <app-show-errors [ctrl]="ctrl" [template]="template"></app-show-errors>
  </div>
</div>
