import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatTabsModule } from "@angular/material/tabs";
import { NgpSortModule } from "ngp-sort-pipe";
import { FileStorageUrlPipe } from "./pipes/file-storage-url.pipe";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { GravatarComponent } from "./components/gravatar/gravatar.component";
import { ImgFallbackDirective } from "./directives/img-fallback.directive";
import { RatingComponent } from "./components/rating/rating.component";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatStepperModule } from "@angular/material/stepper";
import { StepProgressComponent } from "./components/step-progress/step-progress.component";
import { MatMenuModule } from "@angular/material/menu";
import { DobPipe } from "./pipes/dob.pipe";
import { MatChipsModule } from "@angular/material/chips";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { DateConverterPipe } from "./pipes/date-converter.pipe";
import { MatExpansionModule } from "@angular/material/expansion";
import { FilterPipe } from "./pipes/filter.pipe";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { PatientMetaComponent } from "./components/patient-meta/patient-meta.component";
import { SafePipe } from "./pipes/safe.pipe";
import { LabsTreeComponent } from "../video/components/labs-tree/labs-tree.component";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { MatTreeModule } from "@angular/material/tree";
import { SafeImagePipe } from "./pipes/safe-image.pipe";
import { TextOLowerPipe } from "./pipes/text-o-lower.pipe";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LogoComponent } from "../ui/components/logo/logo.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DobUnixPipe } from "./pipes/dob-unix.pipe";
import { FormatPhonePipe } from "./pipes/format-phone.pipe";
import { TranslocoModule } from "@ngneat/transloco";
import { TimeDifferencePipe } from "./pipes/time-difference.pipe";
import { LazyImgDirective } from "./directives/lazy-img.directive";
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { TrimCommaPipe } from "./pipes/trim-comma.pipe";
import { ProperCasePipe } from "./pipes/proper-case.pipe";
import { PatientMetaEditsComponent } from './components/patient-meta-edits/patient-meta-edits.component';

const MODULES = [
  MatButtonToggleModule,
  NgxMatMomentModule,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  TranslocoModule,
  CommonModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  NgpSortModule,
  ReactiveFormsModule,
  MatAutocompleteModule,
  MatMenuModule,
  FormsModule,
  MatInputModule,
  MatFormFieldModule,
  MatButtonModule,
  MatDialogModule,
  MatCardModule,
  MatSelectModule,
  MatTabsModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatRadioModule,
  MatCheckboxModule,
  MatDividerModule,
  MatIconModule,
  MatStepperModule,
  MatChipsModule,
  MatSlideToggleModule,
  MatTableModule,
  CdkTableModule,
  CdkTreeModule,
  MatTreeModule,
  MatTooltipModule,
];
const COMPONENTS = [
  FileStorageUrlPipe,
  LogoComponent,
  GravatarComponent,
  ImgFallbackDirective,
  RatingComponent,
  StepProgressComponent,
  PatientMetaComponent,
  LabsTreeComponent,
];
const DIRECTIVES = [ImgFallbackDirective, LazyImgDirective];
const PIPES = [
  SafeImagePipe,
  TextOLowerPipe,
  SafePipe,
  DobPipe,
  DateConverterPipe,
  FilterPipe,
  DobUnixPipe,
  FormatPhonePipe,
  TimeDifferencePipe,
  TrimCommaPipe,
  ProperCasePipe,
];
@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES, PatientMetaEditsComponent],
  imports: [...MODULES],
  exports: [...MODULES, ...COMPONENTS, ...PIPES, LogoComponent],
})
export class SharedModule {}
