import { createAction, props } from "@ngrx/store";

export enum ActionTypes {
  SELECT_PATIENT = "[Doctor] Select Patient",
  DESELECT_PATIENT = "[Doctor] Clear Selected Patient",
}

export const selectPatient = createAction(
  ActionTypes.SELECT_PATIENT,
  props<{ data: any }>()
);

export const deselectPatient = createAction(ActionTypes.DESELECT_PATIENT);
