import { Action, createReducer, on } from "@ngrx/store";
import { ConfigModel, addConfig, removeConfig } from "../actions";

export const configFeatureKey = "appConfig";

export const initialState: ConfigModel = null;

const _configReducer = createReducer(
  initialState,
  on(addConfig, (state, { data }) => data),
  on(removeConfig, (state) => null)
);

export function configReducer(state: ConfigModel, action: Action) {
  return _configReducer(state, action);
}
