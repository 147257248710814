import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { DateConverterPipe } from "src/app/shared/pipes/date-converter.pipe";
import { DatePipe } from "@angular/common";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { FormConfig, TEMPLATE_ENGINES } from "src/app/formsy";
import { phoneValidator } from "src/app/formsy/validators/phone-validator";
import { LoaderService } from "src/app/loader";
import { ToastService } from "src/app/toast";
import { AuthService } from "../../services/auth.service";
import { Logger } from "src/constants/logger";
import { Store } from "@ngrx/store";
import { updateUser } from "../../store/auth.action";
import { nameValidator } from "src/app/formsy/validators/name.validator";

@Component({
  selector: "app-personal-details",
  templateUrl: "./personal-details.component.html",
  styleUrls: ["./personal-details.component.scss"],
  providers: [DatePipe, DateConverterPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalDetailsComponent implements OnInit, OnChanges {
  @Input() personal: any;
  @Input() uid: string;
  @Output() save = new EventEmitter();
  @Input() disableFields: boolean;
  disabled = true;
  personalDetails = new FormGroup({
    gender: new FormControl(null, [Validators.required]),
    dob: new FormControl(null, [Validators.required]),
    phone: new FormControl(null, [Validators.required, phoneValidator]),
  });
  nameModel: FormConfig = null;
  today = new Date();
  constructor(
    private dateConverter: DateConverterPipe,
    private toast: ToastService
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.personal && changes.personal.currentValue) {
      const data = changes.personal.currentValue;
      this.personalDetails.patchValue({
        gender: data.gender,
        phone: data.contact,
        dob: new Date(this.dateConverter.transform(data.dob)),
      });
      this.createModel();
    }
    if (changes && changes.disableFields) {
      this.disabled = changes.disableFields.currentValue;
    }
  }

  createModel() {
    this.nameModel = {
      templateEngine: TEMPLATE_ENGINES.MATERIAL,
      controls: [
        {
          name: "firstName",
          controlType: "control",
          ctrlSize: 4,
          label: "First Name",
          validators: [Validators.required, nameValidator],
          value: this.personal.name.firstName,
        },
        {
          name: "middleName",
          controlType: "control",
          ctrlSize: 4,
          label: "Middle Name",
          validators: [nameValidator],
          value: this.personal.name.middleName || "",
        },
        {
          name: "lastName",
          controlType: "control",
          ctrlSize: 4,
          label: "Last Name",
          validators: [Validators.required, nameValidator],
          value: this.personal.name.lastName,
        },
      ],
    };
  }

  get fullName() {
    if (this.personal && this.personal.name) {
      return `${this.personal.name.firstName} ${this.personal.name.lastName}`;
    }
    return "";
  }

  get dob() {
    if (this.personal.dob) {
      return new Date(this.dateConverter.transform(this.personal.dob));
    }
    return null;
  }
  enableEdit() {
    this.disabled = !this.disabled;
    if (this.disabled === false) {
      this.personalDetails.patchValue({ phone: this.personal.contact });
    }
  }
  cancelEdit() {
    this.disabled = true;
    this.personalDetails.patchValue({
      dob: new Date(this.dateConverter.transform(this.personal.dob)),
    });
  }
  getNameForm(form: FormGroup) {
    this.personalDetails.addControl("name", form);
  }
  saveDetails() {
    if (this.personalDetails.valid) {
      const { name, gender, dob, phone } = this.personalDetails.value;
      const uname = `${name.firstName} ${name.lastName}`;
      const data = {
        name,
        displayName: uname,
        gender,
        dob,
        contact: phone,
      };
      Logger.Log("data", data);
      this.save.emit(data);
    } else {
      // this.toast.showError("Please fill in all the fields");
    }
  }
}
