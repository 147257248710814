<nav
  class="navbar-header mat-elevation-z6 d-flex align-items-center justify-content-between"
>
  <div class="d-flex align-items-center">
    <app-logo></app-logo>
    <img src="/assets/HIPAA.svg" alt="HIPAA" class="ml-2" />
  </div>
  <div
    class="d-flex flex-row-reverse align-items-center"
    style="height: 65px;"
    *ngIf="!showProfile && user !== null"
  >
    <ul class="nav" *ngIf="isMenuVisible">
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/doctor', 'dashboard']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >Waiting Room</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/doctor', 'records']"
          routerLinkActive="active"
          >Patient's Records</a
        >
      </li>
    </ul>
  </div>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <button mat-flat-button [matMenuTriggerFor]="appMenu" *ngIf="user !== null">
      <span>{{ name | titlecase }}</span>
      <mat-icon>expand_more</mat-icon>
    </button>
    <mat-slide-toggle
      class="mr-2"
      *ngIf="!showProfile && user !== null"
      [formControl]="status"
    >
      <span *ngIf="status.value; else offline" class="text-success"
        >Available</span
      >
      <ng-template #offline
        ><span class="text-muted">Offline</span></ng-template
      >
    </mat-slide-toggle>
  </div>
</nav>
<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)="navigateToProfile()" *ngIf="showProfile">
    Profile
  </button>
  <button mat-menu-item (click)="logout()">Logout</button>
</mat-menu>
<div
  *ngIf="bannerMsg && bannerMsg !== ''"
  class="alert alert-info alert-dismissible fade show py-1 px-3"
  role="alert"
  style="border-radius: 0px; margin-bottom: 0px;"
>
  <small [innerHTML]="bannerMsg"></small>
  <button
    type="button"
    class="close py-1 px-3"
    data-dismiss="alert"
    aria-label="Close"
  >
    <small aria-hidden="true">&times;</small>
  </button>
</div>
