import { FormControl } from '@angular/forms';

export function emailValidator(ctrl: FormControl) {
  if (ctrl.value !== '' && ctrl.value !== null) {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(ctrl.value)) {
      return { email: true };
    } else {
      return null;
    }
  } else {
    return null;
  }
}
