import { createAction, props } from "@ngrx/store";

enum ActionTypes {
  UPDATE_STATUS = "[Patient Intake] Update Status",
  CLEAR_FORM = "[Patient Intake] Clear Form",
}

export const updateFormStatus = createAction(
  ActionTypes.UPDATE_STATUS,
  props<{ field: string; data: any }>()
);

export const clearIntakeForm = createAction(ActionTypes.CLEAR_FORM);
