import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { auth } from "firebase/app";
import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import * as moment from "moment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LoaderService } from "src/app/loader";
import { environment } from "src/environments/environment";
import { Logger } from "src/constants/logger";
import { v4 as uuidv4 } from "uuid";
import { Store } from "@ngrx/store";
import { updateUser, signOut } from "../store/auth.action";
import { deselectDoctor } from "src/app/doctor/store/selected-doctor.actions";
import { deselectPatient } from "src/app/doctor/store/selected-patient.actions";
import { clearId } from "src/app/primary-care/store/corona.actions";
import firebase from "firebase/app";
import { getCurrentTimeStamp } from "src/app/utils/time";
import { StorageService } from "src/app/core/services/storage.service";
import { clearIntakeForm } from "src/app/patient/store/intake.actions";
@Injectable()
export class AuthService {
  authState: any = null;
  _authState;
  private _accessToken: string;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private http: HttpClient,
    private afStore: AngularFirestore,
    private loader: LoaderService,
    private store: Store,
    private storageService: StorageService
  ) {
    this.afAuth.authState.subscribe((auth) => {
      this._authState = auth;
      // this.userToken();
    });
  }

  // Returns true if user is logged in
  get authenticated(): boolean {
    return this._authState !== null;
  }

  // Returns current user data
  get currentUser() {
    return this.afAuth.authState;
  }

  // Returns
  get currentUserObservable(): any {
    return this.afAuth.authState;
  }

  // Returns current user UID
  get currentUserId(): string {
    return this.authenticated ? this.authState.uid : "";
  }

  // Anonymous User
  get currentUserAnonymous(): boolean {
    return this.authenticated ? this.authState.isAnonymous : false;
  }

  // Returns current user display name or Guest
  get currentUserDisplayName(): string {
    if (!this._authState) {
      return "Guest";
    } else if (this.currentUserAnonymous) {
      return "Anonymous";
    } else {
      return this.authState["displayName"] || "User without a Name";
    }
  }

  get userAccessToken() {
    return this.afAuth.idToken;
  }

  //// Social Auth ////
  githubLogin() {
    const provider = new auth.GithubAuthProvider();
    return this.socialSignIn(provider);
  }

  googleLogin() {
    const provider = new auth.GoogleAuthProvider();
    return this.socialSignIn(provider);
  }

  facebookLogin() {
    const provider = new auth.FacebookAuthProvider();
    return this.socialSignIn(provider);
  }

  twitterLogin() {
    const provider = new auth.TwitterAuthProvider();
    return this.socialSignIn(provider);
  }
  //// Anonymous Auth ////
  anonymousLogin() {
    return this.afAuth
      .signInAnonymously()
      .then((user) => {
        this._authState = user;
        //this.updateUserData();
      })
      .catch((error) => Logger.Log(error, "AuthService", "anonymouslogin"));
  }
  //// Email/Password Auth ////
  async emailSignUp(email: string, password: string) {
    try {
      const res = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      const {
        email: uemail,
        displayName,
        photoURL,
        uid,
        refreshToken,
      } = res.user;
      this.authState = {
        email: uemail,
        displayName,
        photoURL,
        uid,
        refreshToken,
      };
      const data = await res.user.getIdToken();
      this._accessToken = data;
      this.authState.accessToken = this._accessToken;
      const d = await this.updateUserData(res.user.uid);
      return Promise.resolve({ ...this.authState, exists: d });
    } catch (e) {
      return Promise.reject(e);
    }
  }
  emailLogin(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        const { email, displayName, photoURL, uid, refreshToken } = res.user;
        this.authState = { email, displayName, photoURL, uid, refreshToken };
        return res.user.getIdToken();
      })
      .then((data) => {
        this._accessToken = data;
        this.authState.accessToken = this._accessToken;
        return Promise.resolve(this.authState);
      });
  }
  //// Sign Out ////
  signOut(): void {
    this.store.dispatch(deselectDoctor());
    this.store.dispatch(deselectPatient());
    this.store.dispatch(clearIntakeForm());
    this.store.dispatch(clearId());
    this.store.dispatch(signOut());
    this.storageService.clearAll();
    this.afAuth.signOut();
  }
  //// Helpers ////
  getUserData(uid) {
    return this.afStore.doc(`/users/${uid}`).valueChanges();
  }
  private createMRN() {
    const buffer = new Array();
    let d = uuidv4(null, buffer, 0);
    d = d.join("").substring(0, 10);
    return d;
  }
  private async updateUserData(uid) {
    try {
      this.loader.showLoader();
      const path = `/users/${uid}`;
      const doc = await this.afStore.doc(path).get().toPromise();
      const { email, displayName, photoURL } = this.authState;
      let data = {};
      if (doc.exists) {
        data = { name: displayName, displayName, photo: photoURL };
      } else {
        data = {
          name: displayName,
          photo: photoURL,
          email,
          roles: ["user", "patient"],
          createdAt: moment().unix(),
          createdAtTimestamp: new Date().toUTCString(),

          status: "pending",
        };
      }
      const res = await this.afStore.doc(path).set(data, { merge: true });
      this.loader.hideLoader();
      return Promise.resolve({ exists: doc.exists });
    } catch (e) {
      this.loader.hideLoader();
      return Promise.reject(e);
    }
  }
  async updateExtendedUserData(uid, data) {
    const path = `users/${uid}`;
    const name = `${data.personal.name.firstName} ${data.personal.name.lastName}`;
    const mrn = this.createMRN();
    const d = { ...data, status: "done", name, displayName: name, mrn };
    this.store.dispatch(updateUser({ data: d }));
    return this.afStore
      .doc(path)
      .set(
        { ...data, status: "done", name, displayName: name, mrn },
        { merge: true }
      );
  }
  private userToken() {
    if (this.afAuth.currentUser) {
      this.afAuth.currentUser
        .then((user) => {
          return user.getIdToken();
        })
        .then((data) => {
          this._accessToken = data;
          this.store.dispatch(
            updateUser({ data: { accessToken: this._accessToken } })
          );
        })
        .catch((er) => {});
    }
  }
  public get accessToken(): string {
    return this._accessToken;
  }
  ////Verify card details////
  verifyCard(name, email, cardNumber, expiryMonth, expiryYear, cvc) {
    const api = `${environment.firebaseConfig.functions}/verifyCard`;
    const data = {
      name,
      email,
      card: cardNumber,
      expiryMonth,
      expiryYear,
      cvc,
    };
    return this.http.post(api, data);
  }
  // get Card details
  getCardDetails(token) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http
      .get(`${environment.firebaseConfig.functions}/getCard`, { headers })
      .toPromise();
  }
  // update card details
  updateCardDetails(name, cardNumber, expiryMonth, expiryYear, cvc, token) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const ep = `${environment.firebaseConfig.functions}/updateCard`;
    const data = {
      name,
      card: cardNumber,
      expiryMonth,
      expiryYear,
      cvc,
    };
    return this.http.post(ep, data, { headers }).toPromise();
  }
  getUserByUID(id) {
    return this.afStore.doc("/users/" + id).get();
  }
  getDoctor(id) {
    return this.afStore.doc("/doctor/" + id).valueChanges();
  }

  sendSMS(no, msg, token) {
    return this.http
      .post(
        `${environment.firebaseConfig.functions}/sendSMS`,
        {
          no,
          msg,
        }
        // { headers }
      )
      .toPromise();
  }
  // new funtions
  async emailRegister(email: string, password: string) {
    try {
      const status = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      const token = await status.user.getIdToken();
      const data = await this.addUserToDB(status.user.uid, {
        displayName: status.user.displayName,
        email: status.user.email,
        photoUrl: status.user.photoURL,
      });
      return Promise.resolve({ ...data, accessToken: token });
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async userLogin(email: string, password: string) {
    try {
      const result = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );
      const token = await result.user.getIdToken();
      const userDetails = await (
        await this.getUserDetails(result.user.uid)
      ).data();
      return Promise.resolve({
        ...userDetails,
        uid: result.user.uid,
        accessToken: token,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
  addUserToDB(id, user, status = "pending") {
    const roles = ["patient", "user"];
    const createdAt = getCurrentTimeStamp();
    return this.afStore
      .doc(`/users/${id}`)
      .set({ ...user, roles, status, createdAt }, { merge: true })
      .then((d) =>
        Promise.resolve({ ...user, uid: id, roles, status, createdAt })
      );
  }
  getUserDetails(id) {
    return this.afStore.doc(`/users/${id}`).get().toPromise();
  }
  updateOnlineStatus(id, available = true) {
    Logger.Log("online", id, available);
    return this.afStore
      .doc(`/doctor/${id}`)
      .set({ available }, { merge: true });
  }
  getUser(id) {
    return this.afStore.doc(`/users/${id}`).get().toPromise();
  }
  private async socialSignIn(provider) {
    try {
      const res = await this.afAuth.signInWithPopup(provider);
      const { email, displayName, uid } = res.user;
      const token = await res.user.getIdToken();
      let data = await this.getUser(uid);
      if (!data.exists) {
        data = await this.addUserToDB(uid, {
          email,
          displayName,
        });
        return Promise.resolve({ ...data, uid, accessToken: token });
      } else {
        const d = data.data();
        delete d["card"];
        return Promise.resolve({ ...d, uid, accessToken: token });
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }
  verifyResetCode(code) {
    return this.afAuth.verifyPasswordResetCode(code);
  }
  updatePassword(password, verificationCode) {
    return this.afAuth.confirmPasswordReset(verificationCode, password);
  }
  resetPassword(email: string, type: string) {
    return this.http
      .post(`${environment.v1}/users/reset`, { email, type })
      .toPromise();
  }
  doctorVerification(uid, client) {
    return this.afStore
      .doc(`/doctor/${uid}`)
      .get()
      .toPromise()
      .then((d) => {
        if (d.exists) {
          const clients: string[] = d.data().clientId;
          if (clients && clients.includes(client)) {
            return Promise.resolve("practice doctor");
          } else {
            return Promise.reject("doctor not mapped to current practice");
          }
        } else {
          return Promise.reject("no doctor found");
        }
      });
  }
  updateUserDetails(uid, data) {
    const path = `users/${uid}`;
    return this.afStore.doc(path).set({ ...data }, { merge: true });
  }
}
