import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { ConfigModel } from "../actions";
import { map, take } from "rxjs/operators";

@Injectable()
export class MasterGuard implements CanActivate {
  constructor(
    private store: Store<{ appConfig: ConfigModel }>,
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select("appConfig").pipe(
      take(1),
      map((d) => {
        if (d.master) {
          return true;
        } else {
          this.router.navigate(["/"]);
          return false;
        }
      })
    );
  }
}
