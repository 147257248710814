<form [formGroup]="form" *ngIf="form !== null" class="row">
  <ng-container *ngFor="let ctrl of model.controls">
    <!-- ///////////////////////////////////////////////////////////////////////////////////// -->
    <!-- ROOT LEVEL FORM CONTROLS -->
    <div
      *ngIf="ctrl.controlType === 'control'"
      [class]="'col-md-' + ctrl.ctrlSize"
    >
      <ng-container [ngSwitch]="ctrl?.type">
        <!-- MONTH/YEAR CALENDAR -->
        <app-month-year-calendar
          [name]="ctrl.name"
          [label]="ctrl.label"
          [ctrl]="form.get(ctrl.name)"
          [template]="model.templateEngine"
          *ngSwitchCase="'monthYearCalendar'"
        ></app-month-year-calendar>
        <!-- CALENDAR -->
        <app-calendar
          [name]="ctrl.name"
          [label]="ctrl.label"
          [ctrl]="form.get(ctrl.name)"
          [template]="model.templateEngine"
          *ngSwitchCase="'calendar'"
        ></app-calendar>
        <!-- RADIO -->
        <app-radio
          [name]="ctrl.name"
          [label]="ctrl.label"
          [ctrl]="form.get(ctrl.name)"
          [template]="model.templateEngine"
          [options]="ctrl.options"
          [inline]="ctrl.inline"
          *ngSwitchCase="'radio'"
        ></app-radio>
        <!-- DROP DOWN -->
        <app-drop-down
          [name]="ctrl.name"
          [label]="ctrl.label"
          [ctrl]="form.get(ctrl.name)"
          [template]="model.templateEngine"
          [options]="ctrl.options"
          *ngSwitchCase="'dropdown'"
        ></app-drop-down>
        <!-- MASKED TEXT-->
        <app-masked-textbox
          [name]="ctrl.name"
          type="text"
          [label]="ctrl.label"
          [ctrl]="form.get(ctrl.name)"
          [mask]="ctrl.mask"
          [template]="model.templateEngine"
          *ngSwitchCase="'maskedtextbox'"
        ></app-masked-textbox>
        <!-- PHONE -->
        <app-phone
          [name]="ctrl.name"
          [label]="ctrl.label"
          [preferredCountries]="ctrl.options"
          [ctrl]="form.get(ctrl.name)"
          [template]="model.templateEngine"
          *ngSwitchCase="'phone'"
        ></app-phone>
        <!-- PASSWORD -->
        <app-password
          [name]="ctrl.name"
          [label]="ctrl.label"
          [ctrl]="form.get(ctrl.name)"
          [template]="model.templateEngine"
          *ngSwitchCase="'password'"
        ></app-password>
        <!-- TEXT/EMAIL/NUMBER/DATE/MULTILINE -->
        <app-textbox
          [name]="ctrl.name"
          [type]="ctrl.type"
          [label]="ctrl.label"
          [maxlength]="ctrl.maxlength"
          [ctrl]="form.get(ctrl.name)"
          [template]="model.templateEngine"
          *ngSwitchDefault
        ></app-textbox>
      </ng-container>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////////////////// -->
    <!-- NESTED FORM GROUP -->
    <ng-container *ngIf="ctrl.controlType === 'group'">
      <div
        *ngFor="let c of ctrl.controls"
        [formGroupName]="ctrl.name"
        [class]="'col-md-' + ctrl.ctrlSize"
      >
        <ng-container [ngSwitch]="ctrl?.type">
          <!-- MONTH/YEAR CALENDAR -->
          <app-month-year-calendar
            [name]="ctrl.name"
            [label]="ctrl.label"
            [ctrl]="form.get(ctrl.name)"
            [template]="model.templateEngine"
            *ngSwitchCase="'monthYearCalendar'"
          ></app-month-year-calendar>
          <!-- CALENDAR -->
          <app-calendar
            [name]="ctrl.name"
            [label]="ctrl.label"
            [ctrl]="form.get(ctrl.name)"
            [template]="model.templateEngine"
            *ngSwitchCase="'calendar'"
          ></app-calendar>
          <!-- RADIO -->
          <app-radio
            [name]="ctrl.name"
            [label]="ctrl.label"
            [ctrl]="form.get(ctrl.name)"
            [inline]="ctrl.inline"
            [template]="model.templateEngine"
            [options]="ctrl.options"
            *ngSwitchCase="'radio'"
          ></app-radio>
          <!-- PHONE -->
          <app-phone
            [name]="ctrl.name"
            [type]="ctrl.type"
            [label]="ctrl.label"
            [preferredCountries]="ctrl.options"
            [ctrl]="form.get(ctrl.name)"
            [template]="model.templateEngine"
            *ngSwitchCase="'phone'"
          ></app-phone>
          <!-- DROPDOWN -->
          <app-drop-down
            [name]="ctrl.name"
            [label]="ctrl.label"
            [ctrl]="form.get(ctrl.name)"
            [template]="model.templateEngine"
            [options]="ctrl.options"
            *ngSwitchCase="'dropdown'"
          ></app-drop-down>
          <!-- MASKED TEXT-->
          <app-masked-textbox
            [name]="ctrl.name"
            type="text"
            [label]="ctrl.label"
            [ctrl]="form.get(ctrl.name)"
            [template]="model.templateEngine"
            [mask]="ctrl.mask"
            *ngSwitchCase="'maskedtextbox'"
          ></app-masked-textbox>
          <!-- PASSWORD -->
          <app-password
            [name]="ctrl.name"
            [label]="ctrl.label"
            [ctrl]="form.get(ctrl.name)"
            [template]="model.templateEngine"
            *ngSwitchCase="'password'"
          ></app-password>
          <!-- TEXT/EMAIL/NUMBER/DATE/MULTILINE -->
          <app-textbox
            [name]="c.name"
            [type]="c.type"
            [label]="c.label"
            [ctrl]="form.get(ctrl.name).get(c.name)"
            [template]="model.templateEngine"
            *ngSwitchDefault
          ></app-textbox>
        </ng-container>
      </div>
    </ng-container>
    <!-- ///////////////////////////////////////////////////////////////////////////////////// -->
  </ng-container>
  <ng-content></ng-content>
</form>
<!-- <pre>
    {{ form?.value | json }}
</pre> -->
