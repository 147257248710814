<div
  class="d-flex align-items-center justify-content-center poweredby p-2"
  *ngIf="poweredBy$ | async"
>
  <div class="d-flex flex-column mr-3">
    <small style="font-size: 7px; color: #737373;">Powered By</small>
    <img src="/assets/sevaro.png" style="height: 25px;" />
  </div>
  <img src="/assets/hippa.png" style="height: 40px;" />
</div>
