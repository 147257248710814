import { Directive, OnInit, Renderer2, ElementRef } from "@angular/core";
import { NgLog } from "src/app/logger";

@Directive({
  selector: "[required]",
})
@NgLog()
export class MarkAsteriskDirective implements OnInit {
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    const parent = this.renderer.parentNode(this.el.nativeElement);

    if (
      parent.getElementsByTagName("LABEL").length &&
      !parent.getElementsByClassName("required-asterisk").length
    ) {
      parent.getElementsByTagName("LABEL")[0].innerHTML +=
        '<span class="required-asterisk text-danger">*</span>';
    }
  }
}
