<div class="form-group" *ngIf="template === 'bootstrap'">
  <label>{{ label | titlecase }}</label>
  <select
    class="form-control"
    [formControl]="ctrl"
    [name]="name"
    [class]="class"
    [compareWith]="compareFn"
  >
    <option value="">select</option>
    <option [value]="o.key" *ngFor="let o of options">{{
      o.value | titlecase
    }}</option>
  </select>
  <app-show-errors [ctrl]="ctrl" [template]="template"></app-show-errors>
</div>

<mat-form-field *ngIf="template === 'material'" class="col-md-12 p-0">
  <mat-label>{{ label | titlecase }}</mat-label>
  <mat-select [compareWith]="compareFn" [formControl]="ctrl">
    <mat-option [value]="o.key" *ngFor="let o of options">{{
      o.value | titlecase
    }}</mat-option>
  </mat-select>
  <!-- <app-show-errors [ctrl]="ctrl" [template]="template"></app-show-errors> -->
</mat-form-field>
