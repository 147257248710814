import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-primary-care-dialog",
  templateUrl: "./primary-care-dialog.component.html",
  styleUrls: ["./primary-care-dialog.component.scss"],
})
export class PrimaryCareDialogComponent implements OnInit {
  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<PrimaryCareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}
  goToPage(type) {
    if (type === "doctor") {
      this.router.navigate(["/doctor", "selector"]);
    }
    this.dialogRef.close();
  }
}
