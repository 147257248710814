import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TEMPLATE_ENGINES } from "../../models/form-config.model";

@Component({
  selector: "app-radio",
  templateUrl: "./radio.component.html",
  styleUrls: ["./radio.component.scss"],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent implements OnInit {
  @Input() label: string;
  @Input() name: string;
  @Input() ctrl: FormControl;
  @Input() inline: boolean;
  @Input() options: { key: any; value: string }[];
  @Input() template: TEMPLATE_ENGINES;
  fields: any;
  constructor() {}
  get labelText() {
    if (this.label) {
      if (this.ctrl.hasError("required")) {
        return `${this.label} <span class="required-asterisk">*</span>`;
      }
      return this.label;
    }
    return "";
  }

  ngOnInit(): void {}
  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes && changes.data && changes.data.currentValue) {
  //     this.fields = changes.data.currentValue.map((d) => ({
  //       ...d,
  //       selected: false,
  //     }));
  //     this.ctrl.setValue(this.fields);
  //   }
  // }
  // updateData(field, checked) {
  //   this.fields.find((p) => p.key === field.key).selected = checked;
  //   this.ctrl.setValue(this.fields);
  // }
}
