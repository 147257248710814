import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { Logger } from "src/constants/logger";
import { Store } from "@ngrx/store";
import { take, map } from "rxjs/operators";

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(private store: Store<{ user: any }>, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    Logger.Log("router data", next.data);
    return this.store.select("user").pipe(
      take(1),
      map((u) => {
        if (u) {
          const userRoles = !!u.role ? u.role : u.roles;
          const allowedRoles = next.data.roles;
          const exists = userRoles.some((role) => allowedRoles.includes(role));
          Logger.Log("roles exist", exists, userRoles, allowedRoles);
          if (exists) {
            return true;
          } else {
            this.router.navigate(["/"]);
            return false;
          }
        }
        return false;
      })
    );
  }
}
