import { Action, createReducer, on } from "@ngrx/store";
import { ConfigModel } from "../actions";
import {
  selectFeature,
  deselectFeature,
} from "../actions/selected-feature.actions";

export const selectedFeatureKey = "selectedFeature";

export const initialState: any = null;

const _reducer = createReducer(
  initialState,
  on(selectFeature, (state, { data }) => data),
  on(deselectFeature, (state) => null)
);

export function selectedFeatureReducer(state: ConfigModel, action: Action) {
  return _reducer(state, action);
}
