<div class="container p-5">
  <div class="row">
    <div
      class="col-md-3 my-2 d-flex flex-column align-items-center"
      *ngFor="let f of features$ | async"
    >
      <div
        class="feature-circle rounded-circle"
        (click)="navigateToPage(f)"
      ></div>
      <h3 class="text-center mt-4">{{ f.value }}</h3>
    </div>
  </div>
</div>
