import { Component, OnInit, OnDestroy } from "@angular/core";
import { LoaderService } from "../../services/loader.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"]
})
export class SpinnerComponent implements OnInit, OnDestroy {
  showLoader: boolean = false;
  loader$: Subscription;
  constructor(private loader: LoaderService) {}

  ngOnInit(): void {
    this.loader$ = this.loader.loadingObservable.subscribe(
      status => (this.showLoader = status),
      err => {}
    );
  }
  ngOnDestroy() {
    this.loader$.unsubscribe();
  }
}
