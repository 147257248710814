import { FormConfig, TEMPLATE_ENGINES } from "src/app/formsy";
import { Validators } from "@angular/forms";

export const SignupFormModel: FormConfig = {
  templateEngine: TEMPLATE_ENGINES.MATERIAL,
  controls: [
    {
      name: "email",
      type: "text",
      controlType: "control",
      label: "Email",
      placeholder: "johnsmith@gmail.com",
      validators: [Validators.required],
      ctrlSize: 12,
    },
    {
      name: "password",
      type: "password",
      controlType: "control",
      label: "Password",
      validators: [Validators.required, Validators.minLength(6)],
      ctrlSize: 12,
    },
  ],
};

export const LoginFormModel: FormConfig = {
  templateEngine: TEMPLATE_ENGINES.MATERIAL,
  updateOn: "change",
  controls: [
    {
      name: "email",
      type: "text",
      controlType: "control",
      label: "Email",
      placeholder: "johnsmith@gmail.com",
      validators: [Validators.required],
      ctrlSize: 12,
    },
    {
      name: "password",
      type: "password",
      controlType: "control",
      label: "Password",
      validators: [Validators.required],
      ctrlSize: 12,
    },
    // {
    //   name: "consulting",
    //   type: "dropdown",
    //   controlType: "control",
    //   label: "",
    //   validators: [Validators.required],
    //   ctrlSize: 12,
    //   options: [
    //     { key: 1, value: "Lifepoint Health" },
    //     { key: 2, value: "ABC Health" },
    //     { key: 3, value: "XYZ Health" },
    //   ],
    //   value: 1,
    // },
  ],
};
