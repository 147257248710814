<div class="form-group row">
  <mat-form-field class="col-md-12">
    <input
      matInput
      [ngxMatDatetimePicker]="picker"
      [placeholder]="label"
      [formControl]="ctrl"
      [max]="maxDate"
      [disabled]="disabled"
      [required]="ctrl.getError('required')"
      (focus)="picker.open()"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker
      #picker
      [showSpinners]="showSpinners"
      [enableMeridian]="enableMeridian"
    >
    </ngx-mat-datetime-picker>
  </mat-form-field>
  <div class="col-md-12">
    <app-show-errors [ctrl]="ctrl"></app-show-errors>
  </div>
</div>
