import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "./core";
import { ToastModule } from "./toast";
import { ErrorModule } from "./error";
import { LoggerModule } from "./logger";
import { LoaderModule } from "./loader";
import { AppConfigModule } from "./app-config";
import { MainComponent } from "./pages/main/main.component";
import { UiModule } from "./ui/ui.module";
import { UserModule } from "./user";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { PrimaryCareModule } from "./primary-care/primary-care.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { TranslocoRootModule } from "./transloco-root.module";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
  declarations: [AppComponent, MainComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UiModule,
    PrimaryCareModule,
    AppRoutingModule,
    AppConfigModule.forRoot(),
    CoreModule.forRoot(),
    ErrorModule.forRoot(),
    LoggerModule.forRoot(),
    LoaderModule.forRoot(),
    ToastModule.forRoot({ duration: 3000 }),
    StoreRouterConnectingModule.forRoot(),
    UserModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    HttpClientModule,
    TranslocoRootModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
