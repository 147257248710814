<div mat-dialog-content class="p-4">
  <p class="border-bottom">Update User Details</p>
  <div class="pt-3">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="data.name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>DOB</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="data.dob" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <div class="d-flex flex-column">
      <label>Gender</label>
      <mat-radio-group aria-label="Gender" [(ngModel)]="data.gender">
        <mat-radio-button value="male" color="primary" class="mr-2"
          >Male</mat-radio-button
        >
        <mat-radio-button value="female" color="primary"
          >Female</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </div>
</div>
<div mat-dialog-actions class="border-top">
  <button mat-button (click)="closeDialog()">Cancel</button>
  <button mat-raised-button [mat-dialog-close]="data" color="primary">
    Update
  </button>
</div>
