<div class="d-flex text-info justify-content-between align-items-center mb-2">
  <span>Insurance Details</span>
  <button
    mat-icon-button
    color="primary"
    (click)="enableEdit()"
    *ngIf="disabled"
  >
    <i class="fas fa-pencil-alt"></i>
  </button>
  <div class="d-flex" *ngIf="!disabled">
    <button class="btn btn-info btn-sm px-1 py-0 mr-2" (click)="saveDetails()">
      Save
    </button>
    <button
      class="btn btn-outline-dark btn-sm px-1 py-0"
      (click)="enableEdit()"
    >
      Cancel
    </button>
  </div>
</div>
<form class="row">
  <ng-container *ngIf="disabled; else dropdown">
    <mat-form-field class="col-md-6">
      <mat-label>Company Name</mat-label>
      <input matInput [disabled]="disabled" [value]="insurance?.company" />
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>Group ID</mat-label>
      <input matInput [disabled]="disabled" [value]="insurance?.groupId" />
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>Insured Name</mat-label>
      <input matInput [disabled]="disabled" [value]="insurance?.insuredName" />
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>Relationship</mat-label>
      <input matInput [disabled]="disabled" [value]="insurance?.relationship" />
    </mat-form-field>
  </ng-container>
  <ng-template #dropdown>
    <mat-form-field class="col-md-6">
      <mat-label>Company Name</mat-label>
      <input
        matInput
        [formControl]="insuranceForm.get('company')"
        maxlength="50"
      />
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-label>Group ID</mat-label>
      <input
        matInput
        [formControl]="insuranceForm.get('groupId')"
        maxlength="50"
      />
    </mat-form-field>
    <div class="col-md-6">
      <mat-form-field class="w-100">
        <mat-label>Insured Name</mat-label>
        <input
          matInput
          [formControl]="insuranceForm.get('insuredName')"
          maxlength="50"
        />
      </mat-form-field>
      <app-show-errors
        [ctrl]="insuranceForm.get('insuredName')"
      ></app-show-errors>
    </div>
    <mat-form-field class="col-md-6">
      <mat-label>Relationship</mat-label>
      <mat-select [formControl]="insuranceForm.get('relationship')">
        <mat-option value="">Select</mat-option>
        <mat-option [value]="o" *ngFor="let o of options">{{
          o | titlecase
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>
  <div *ngIf="disabled; else preview" class="col-md-12 d-flex flex-column">
    <span *ngIf="doc?.front || doc?.back">Documents</span>
    <span class="text-muted" *ngIf="!doc?.front && !doc?.back"
      >No documents uploaded yet</span
    >
    <div class="d-flex">
      <img [src]="doc?.front" *ngIf="doc?.front" class="img-thumbnail" />
      <img [src]="doc?.back" *ngIf="doc?.back" class="img-thumbnail" />
    </div>
  </div>
  <ng-template #preview>
    <div class="col-md-12 d-flex">
      <app-file-preview
        [control]="documentForm.get('front')"
        [file]="doc?.front"
        type="front picture"
      ></app-file-preview>
      <app-file-preview
        [control]="documentForm.get('back')"
        [file]="doc?.back"
        type="rear picture"
      ></app-file-preview>
    </div>
  </ng-template>
</form>
