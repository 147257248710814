import {
  Component,
  AfterViewInit,
  OnInit,
  OnDestroy,
  DoCheck,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { SubSink } from "subsink";
import { filter, map, mergeMap, take } from "rxjs/operators";
import { Title } from "@angular/platform-browser";
import { HeaderService } from "./core/services/header.service";
import { Observable } from "rxjs";
import { MessagingService } from "./core/services/messaging.service";
import { AuthService } from "./user";
import { Store } from "@ngrx/store";
import { ConfigModel } from "./app-config/actions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  private sink = new SubSink();
  showHeader: boolean;
  appName: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private headerService: HeaderService,
    private cd: ChangeDetectorRef,
    private messaging: MessagingService,
    private auth: AuthService,
    private store: Store<{ user: any; appConfig: ConfigModel }>
  ) {
    // translate.setDefaultLang("en");
    // translate.use("en");
  }

  messagingSub() {
    this.sink.add(
      this.store
        .select("user")
        .pipe(
          filter((user) => !!user),
          take(1)
        )
        .subscribe((user) => {
          if (user) {
            this.messaging.requestPermission(user);
            this.messaging.monitorRefresh(user);
            this.messaging.receiveMessage();
          }
        })
    );
  }
  ngOnInit() {
    // this.messagingSub();
    this.headerSub();
    this.getAppName();
    this.setTitles();
  }
  getAppName() {
    this.sink.add(
      this.store.select("appConfig").subscribe((config) => {
        if (config) {
          this.appName = config.name;
        }
      })
    );
  }
  headerSub() {
    this.sink.add(
      this.headerService.headerObservable.subscribe(
        (b) => {
          this.showHeader = b;
          this.cd.detectChanges();
        },
        (e) => {}
      )
    );
  }
  setTitles() {
    this.sink.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) route = route.firstChild;
            return route;
          }),
          filter((route) => route.outlet === "primary"),
          mergeMap((route) => route.data)
        )
        .subscribe((event) =>
          this.titleService.setTitle(`${event["title"]} | ${this.appName}`)
        )
    );
  }
  ngOnDestroy() {
    this.sink.unsubscribe();
  }
  ngAfterViewInit() {
    document.body.style.background =
      "url('/assets/background-flow.jpg') no-repeat fixed";
    // const div = document.createElement("div");
    // div.className =
    //   "d-flex align-items-center justify-content-center poweredby p-2";
    // div.innerHTML = `<div class="d-flex flex-column mr-3"><small style="font-size:7px;color:#737373">Powered By</small>
    // <img src="/assets/sevaro.png" style="height:25px"/></div><img src="/assets/hippa.png" style="height:40px" />`;
    // document.body.appendChild(div);
  }
}
