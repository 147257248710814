import { Pipe, PipeTransform } from "@angular/core";
import { Logger } from "src/constants/logger";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(
    arr: any[],
    column: string,
    value: any,
    type: "multiple" | "single" = "multiple"
  ) {
    if (!arr || arr.length <= 0) {
      return null;
    } else if (!column || !value || column === "" || value === "") {
      return arr;
    } else {
      let res;
      if (type === "multiple") {
        res = arr.filter((d) => d[column] === value);
      } else {
        res = arr.find((d) => d[column] === value);
      }
      // Logger.Log("filter", res, arr, column, value);
      return res;
    }
  }
}
