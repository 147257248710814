<div *ngIf="shouldShowErrors()">
  <ng-container *ngIf="template === 'bootstrap'; else mat">
    <small *ngFor="let error of listOfErrors()" class="text-danger">
      {{ error }}
    </small>
  </ng-container>
</div>

<ng-template #mat>
  <mat-error *ngFor="let error of listOfErrors()">{{ error }}</mat-error>
</ng-template>
