<div class="container">
  <div class="d-flex align-items-center h-100">
    <button class="btn btn-info" [routerLink]="['/doctor', 'selector']">
      Select from our Doctors
    </button>
    <div class="vseparator">
      <div class="line"></div>
      <div class="wordwrapper">
        <div class="word">or</div>
      </div>
    </div>
    <div class="apps h-100">
      <h3 class="my-4">Select a Practice to Proceed</h3>
      <div class="row">
        <div
          class="col-md-4 mb-4"
          *ngFor="let p of practices | sortBy: 'appName'"
        >
          <div class="card mat-elevation-z2" (click)="goToPractice(p)">
            <img
              [src]="p['background'] | safeImage"
              class="card-img-top"
              [appImgFallback]="background"
            />
            <div class="card-body">
              <h5 class="card-title">{{ p["appName"] | titlecase }}</h5>
              <small class="card-text">
                {{ p["address"] }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
