import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "textOLower",
})
export class TextOLowerPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (!value) {
      return null;
    } else {
      return value.replace("O", "o");
    }
  }
}
