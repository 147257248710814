import { Action, createReducer, on } from "@ngrx/store";
import { selectDoctor, deselectDoctor } from "./selected-doctor.actions";

export const selectedFeatureKey = "selectedFeature";

export const initialState = null;

const _reducer = createReducer(
  initialState,
  on(selectDoctor, (state, { data }) => data),
  on(deselectDoctor, (state) => null)
);

export function selectedDoctorReducer(state, action: Action) {
  return _reducer(state, action);
}
