import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
@Pipe({
  name: "dateConverter",
})
export class DateConverterPipe implements PipeTransform {
  constructor(private dt: DatePipe) {}
  transform(value: any, format: string = "MM/dd/yyyy"): any {
    if (!value) return null;
    if (value) {
      try {
        const date = value instanceof Date ? value : new Date(value);
        return this.dt.transform(date, format);
      } catch (e) {
        if (value["seconds"]) {
          return this.dt.transform(new Date(value["seconds"] * 1000), format);
        }
        if (value["_seconds"]) {
          return this.dt.transform(new Date(value["_seconds"] * 1000), format);
        }
        return value;
      }
    }
  }
}
