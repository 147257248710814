import { createAction, props } from "@ngrx/store";
import { ConfigModel } from ".";

export enum ConfigActionTypes {
  ADD_CONFIG = "[Config] Add App Config",
  REMOVE_CONFIG = "[Config] Remove App Config",
}

export const addConfig = createAction(
  ConfigActionTypes.ADD_CONFIG,
  props<{ data: ConfigModel }>()
);

export const removeConfig = createAction(ConfigActionTypes.REMOVE_CONFIG);
