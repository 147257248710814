<mat-dialog-content>
  <h1 matDialogTitle>Enter details to proceed</h1>
  <app-form-builder
    [model]="userForm"
    (onLoad)="getForm($event)"
  ></app-form-builder>
</mat-dialog-content>
<mat-dialog-actions>
  <button color="primary" mat-flat-button (click)="submit()" cdkFocusInitial>
    Ok
  </button>
  <button mat-flat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
