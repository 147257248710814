import { NgModule } from "@angular/core";
import { PrimaryCareComponent } from "./pages/primary-care/primary-care.component";
import { SharedModule } from "../shared/shared.module";
// import { PrimaryCareRoutingModule } from "./primary-care-routing.module";
import { PrimaryCareDialogComponent } from "./components/primary-care-dialog/primary-care-dialog.component";
import { FormsyModule } from "../formsy";
import { CoronaVirusComponent } from "./pages/corona-virus/corona-virus.component";
import { CoronaFormComponent } from "./components/corona-form/corona-form.component";
import { UserMetaFormComponent } from "./components/user-meta-form/user-meta-form.component";
import { CoronaConsentDialogComponent } from "./components/corona-consent-dialog/corona-consent-dialog.component";
import { PrimaryCareService } from "./services/primary-care.service";
import { FlowPopupComponent } from "./components/flow-popup/flow-popup.component";
import { StoreModule } from "@ngrx/store";
import { coronaReducer } from "./store/corona.reducer";
import { RouterModule } from "@angular/router";
import { ThankYouPopupComponent } from "./components/thank-you-popup/thank-you-popup.component";
import { UiModule } from "../ui/ui.module";

@NgModule({
  declarations: [
    PrimaryCareComponent,
    PrimaryCareDialogComponent,
    CoronaVirusComponent,
    CoronaFormComponent,
    UserMetaFormComponent,
    CoronaConsentDialogComponent,
    FlowPopupComponent,
    ThankYouPopupComponent,
  ],
  imports: [
    SharedModule,
    RouterModule,
    // PrimaryCareRoutingModule,
    FormsyModule,
    UiModule,
    StoreModule.forFeature("corona", coronaReducer),
  ],
  exports: [CoronaFormComponent],
  providers: [PrimaryCareService],
})
export class PrimaryCareModule {}
