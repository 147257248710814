import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgLog } from "src/app/logger";

@Component({
  selector: "app-checklist-grid",
  templateUrl: "./checklist-grid.component.html",
  styleUrls: ["./checklist-grid.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
//@NgLog()
export class ChecklistGridComponent implements OnInit {
  @Input() ctrl: FormControl;
  @Input() label: string;
  @Input() name: string;
  @Input() data: { key: any; value: string }[];
  @Input() ctrlSize: number = 12;
  checkBoxFields = [];
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && changes.data.currentValue) {
      this.checkBoxFields = changes.data.currentValue.map((d) => ({
        ...d,
        selected: false,
      }));
      this.ctrl.setValue(this.checkBoxFields);
    }
  }
  updateData(field, checked) {
    this.checkBoxFields.find((p) => p.key === field.key).selected = checked;
    this.ctrl.setValue(this.checkBoxFields);
  }
}
