<nav
  class="navbar-header mat-elevation-z6 d-flex align-items-center justify-content-between"
>
  <div class="d-flex align-items-center">
    <app-logo></app-logo>
    <img src="/assets/HIPAA.svg" alt="HIPAA" class="ml-2" />
  </div>
  <!-- <app-login-buttons
    (changeType)="changeMode($event)"
    [signUpEnabled]="!!featureSelected?.signup"
  ></app-login-buttons> -->
</nav>

<div class="w-100">
  <div class="row h-100">
    <div class="col-md-7 d-flex flex-column clearfix p-0">
      <img src="/assets/login.png" alt="login" class="img-thumbnail" />
    </div>
    <div
      class="col-md-5 d-flex align-items-center justify-content-center login-box py-5"
    >
      <mat-card class="bg-white rounded mat-elevation-z1 p-5">
        <mat-card-content>
          <app-form-builder
            [model]="resetModel"
            (onLoad)="getForm($event)"
          ></app-form-builder>
          <mat-error *ngIf="resetForm.touched && resetForm.hasError('compare')"
            >Password & Confirm Password mismatch</mat-error
          >
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-flat-button
            class="btn btn-info btn-block p-2"
            type="submit"
            color="primary"
            (click)="resetPassword()"
            *ngIf="!status; else loader"
          >
            Reset Password
          </button>
          <ng-template #loader>
            <button mat-flat-button class="btn btn-info btn-block p-2" disabled>
              <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </ng-template>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
