<div class="p-1">
  <mat-card>
    <h2 mat-card-subtitle>Answer the following COVID-19 related questions.</h2>
    <app-form-builder
      [model]="coronaForm"
      (onLoad)="getForm($event)"
    ></app-form-builder>
  </mat-card>
  <button mat-raised-button class="float-right mt-4" (click)="openDialog()">
    Submit
  </button>
</div>
