import { FormControl } from "@angular/forms";

export function nameValidator(ctrl: FormControl) {
  if (ctrl.value !== "" && ctrl.value !== null) {
    const regex = /^[A-Za-z\s]+$/;
    if (!regex.test(ctrl.value)) {
      return { alpha: true };
    } else {
      return null;
    }
  } else {
    return null;
  }
}
