<div class="row" *ngIf="ctrl !== null && checkBoxFields.length > 0">
  <label style="display: block;">{{ label }}</label>
  <div [class]="'col-md-' + ctrlSize" *ngFor="let f of checkBoxFields">
    <mat-checkbox (change)="updateData(f, $event.checked)" color="primary"
      ><span class="text-dark">{{ f.value | titlecase }}</span></mat-checkbox
    >
  </div>
  <div [class]="'col-md-' + ctrlSize">
    <ng-content></ng-content>
  </div>
</div>
<!-- <pre>{{ checkBoxFields | json }}</pre> -->
