import { Action, createReducer, on } from "@ngrx/store";
import { selectPatient, deselectPatient } from "./selected-patient.actions";

export const initialState = null;

const _reducer = createReducer(
  initialState,
  on(selectPatient, (state, { data }) => data),
  on(deselectPatient, (state) => null)
);

export function selectedPatientReducer(state, action: Action) {
  return _reducer(state, action);
}
