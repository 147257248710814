import { Component, OnInit, OnDestroy } from "@angular/core";
import { HeaderService } from "src/app/core/services/header.service";
import { FormConfig, TEMPLATE_ENGINES } from "src/app/formsy";
import { FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastService } from "src/app/toast";
import { Logger } from "src/constants/logger";
import { take } from "rxjs/operators";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  status = false;
  resetModel: FormConfig = {
    templateEngine: TEMPLATE_ENGINES.MATERIAL,
    controls: [
      {
        name: "password",
        type: "password",
        controlType: "control",
        label: "New Password",
        validators: [Validators.required],
        ctrlSize: 12,
      },
      {
        name: "cpassword",
        type: "password",
        controlType: "control",
        label: "Confirm Password",
        ctrlSize: 12,
        validators: [Validators.required],
      },
    ],
  };
  resetForm: FormGroup;
  code: string;
  type: string = "patient";
  constructor(
    private activeRoute: ActivatedRoute,
    private header: HeaderService,
    private authService: AuthService,
    private router: Router,
    private toast: ToastService
  ) {}
  ngOnInit(): void {
    this.header.hideHeader();
    this.header.showPoweredBy();
    this.activeRoute.queryParamMap.pipe(take(1)).subscribe((par) => {
      if (par.has("type")) {
        this.type = par.get("type");
      }
      if (!par.has("oobCode")) {
        this.router.navigate(["/login"], { queryParams: { type: this.type } });
      } else {
        this.code = par.get("oobCode");
        this.verifyResetCode(this.code);
      }
    });
  }
  ngOnDestroy() {
    this.header.hidePoweredBy();
    this.header.showHeader();
  }
  async verifyResetCode(code) {
    try {
      const d = await this.authService.verifyResetCode(code);
      Logger.Log("code verified", d);
    } catch (e) {
      Logger.Log("reset code expired", e);
      this.toast.showInfo(
        "Your password reset code has expired, try generating a new one again"
      );
      this.router.navigate(["/login"]);
    }
  }
  getForm(form: FormGroup) {
    this.resetForm = form;
    this.resetForm.setValidators(this.checkPasswords);
  }
  checkPasswords(group: FormGroup) {
    let pass = group.get("password").value;
    let confirmPass = group.get("cpassword").value;
    return pass === confirmPass ? null : { compare: true };
  }
  async resetPassword() {
    if (this.resetForm.invalid) {
      this.resetForm.markAllAsTouched();
    } else {
      try {
        this.status = true;
        await this.authService.updatePassword(
          this.resetForm.value.password,
          this.code
        );
        this.toast.showSuccess(
          "Password reset successful. Please login to continue."
        );
        this.status = false;
        this.router.navigate(["/login"], { queryParams: { type: this.type } });
      } catch (e) {
        this.toast.showError("Password reset has failed");
        this.status = false;
      }
    }
  }
}
