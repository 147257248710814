import { Component, OnInit, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TEMPLATE_ENGINES } from "../../models/form-config.model";

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit {
  @Input() label: string;
  @Input() ctrl: FormControl;
  @Input() template: TEMPLATE_ENGINES;
  @Input() name: string;
  today = new Date();
  constructor() {}

  ngOnInit(): void {}
}
