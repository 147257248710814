import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { TEMPLATE_ENGINES } from "../../models/form-config.model";

@Component({
  selector: "app-drop-down",
  templateUrl: "./drop-down.component.html",
  styleUrls: ["./drop-down.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropDownComponent implements OnInit {
  @Input() label: string;
  @Input() name: string;
  @Input() class: string;
  @Input() ctrl: FormControl;
  @Input() options: { key: any; value: any }[];
  @Input() template: TEMPLATE_ENGINES;
  constructor() {}

  ngOnInit(): void {}
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.key == c2.key : c1 === c2;
  }
}
