import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  OnDestroy,
  ViewEncapsulation,
  ChangeDetectorRef,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { SubSink } from "subsink";
import { StorageService } from "src/app/core/services/storage.service";
import { NgLog } from "src/app/logger";
import { Logger } from "src/constants/logger";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-checklist-with-none",
  templateUrl: "./checklist-with-none.component.html",
  styleUrls: ["./checklist-with-none.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
@NgLog()
export class ChecklistWithNoneComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() data: any[];
  @Input("control") ctrl: FormControl;
  @Input("other-control") otherCtrl: FormControl = new FormControl();
  @Input() showOther = false;
  @Input() isMultiline = false;
  @Input() colSize = 3;
  @Input() otherColSize = 3;
  @Input() value: string;
  @Input() otherValue: string;
  @Input() storageKey: string;
  @Output() dataChange = new EventEmitter();
  @Output() otherChange = new EventEmitter();
  checklistArr: any[] = [];
  disableChange: any;
  private sink = new SubSink();
  constructor(
    private storageService: StorageService,
    private cdr: ChangeDetectorRef
  ) {}

  get classDef() {
    return `col-md-${this.colSize}`;
  }
  get otherClassDef() {
    return `col-md-${this.otherColSize}`;
  }
  ngOnInit(): void {
    this.patchValuesFromStore();
  }
  getSelected(type: any[]) {
    if (type !== null && type.length > 0) {
      const filtered = type.filter((t) => t["selected"] === true);
      return filtered.map((d) => d["value"]);
    }
    return [];
  }
  updateData(field, checked) {
    if (field.key.toLowerCase() === "none") {
      this.noneSelector();
    } else {
      this.checklistArr = this.checklistArr.map((p) => {
        if (p.key.toLowerCase() === "none") {
          return { ...p, selected: false };
        }
        return p;
      });
      this.checklistArr = this.checklistArr.map((p) => {
        if (p.key === field.key) {
          return { ...p, selected: checked };
        }
        return p;
      });
      this.ctrl.setValue(this.checklistArr);
    }
  }
  noneSelector() {
    this.checklistArr = this.checklistArr.map((d) => ({
      ...d,
      selected: false,
    }));
    this.checklistArr.find(
      (p) => p.key.toLowerCase() === "none"
    ).selected = true;
    this.ctrl.setValue(this.checklistArr);
    this.otherCtrl.setValue(null);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.data &&
      changes.data.currentValue &&
      !this.disableChange
    ) {
      const data = changes.data.currentValue.filter((f) => f !== null);
      this.addControls(data);
    }
    if (
      changes &&
      changes.value &&
      changes.value.currentValue &&
      !this.disableChange
    ) {
      this.findOther();
      this.selectCheckBoxes();
    }
    if (
      changes &&
      changes.otherValue &&
      changes.otherValue.currentValue &&
      !this.disableChange
    ) {
      this.otherCtrl.setValue(changes.otherValue.currentValue);
    }
    if (changes && changes.ctrl && changes.ctrl.currentValue) {
      this.subValueChanges();
    }
  }
  storeData() {
    if (this.storageKey && this.ctrl) {
      let data = this.checklistArr.filter((d) => d.key !== "other");
      this.storageService.setItem(this.storageKey, [
        ...data,
        {
          key: "other",
          value: this.otherCtrl.value,
          selected: !!this.otherCtrl.value,
        },
      ]);
    }
  }
  findOther() {
    try {
      if (!this.disableChange) {
        let arr = this.data;
        arr = arr.map((v) => v.toLowerCase().trim());
        let vals = this.value.trim().split(",");
        vals = vals.map((v) => v.toLowerCase().trim());
        let difference = vals.filter((x) => !arr.includes(x.trim()));
        Logger.Log("DIFF", arr, vals, difference);
        if (difference.length > 0) {
          this.otherCtrl.setValue(difference.toString());
        }
      }
    } catch {}
  }
  patchValuesFromStore() {
    if (this.storageKey && this.ctrl) {
      this.storageService
        .getItem(this.storageKey)
        .then((data: any) => {
          if (data) {
            this.disableChange = true;
            this.checklistArr = data.filter((d) => d.key !== "other");
            this.dataChange.emit(
              this.getSelected([
                ...this.checklistArr,
                {
                  key: "other",
                  value: this.otherCtrl.value,
                  selected: !!this.otherCtrl.value,
                },
              ]).toString()
            );
            const ot = data.find((d) => d.key === "other");
            if (ot) {
              this.otherCtrl.setValue(ot.value);
            }
            this.ctrl.setValue(data);
          }
        })
        .catch((e) => {});
    }
  }
  subValueChanges() {
    this.sink.add(
      this.ctrl.valueChanges.pipe(debounceTime(500)).subscribe((v) => {
        this.storeData();
        this.emitData();
      })
    );
    this.sink.add(
      this.otherCtrl.valueChanges.pipe(debounceTime(500)).subscribe((v) => {
        this.storeData();
        this.emitData();
      })
    );
  }
  emitData() {
    this.otherChange.emit(this.otherCtrl.value);
    this.dataChange.emit(
      this.getSelected([
        ...this.checklistArr,
        {
          key: "other",
          value: this.otherCtrl.value,
          selected: !!this.otherCtrl.value,
        },
      ]).toString()
    );
    this.cdr.detectChanges();
  }
  addControls(list) {
    list.forEach((d) => {
      // if (d.key !== "other") {
      this.checklistArr.push({
        key: d,
        value: d,
        selected: false,
        addnData: null,
      });
      // }
    });
  }
  selectNone(field) {
    if (field.value !== "") {
      this.noneSelector();
    } else if (field.value === "") {
      this.checklistArr = this.checklistArr.map((d) => {
        return {
          ...d,
          selected: false,
        };
      });
      this.ctrl.setValue(this.checklistArr);
    }
  }
  selectCheckBoxes() {
    try {
      if (this.value) {
        Logger.Log("SELECTED VALUES", this.value);
        const v = this.value.toLowerCase().split(",");
        this.checklistArr = this.checklistArr.map((d) => {
          if (v.includes(d.key.toLowerCase())) {
            d.selected = true;
          }
          return d;
        });
        this.ctrl.setValue(this.checklistArr);
      }
    } catch (e) {
      Logger.Log("select check boxes", e);
    }
  }
  isNoneSelected() {
    if (this.checklistArr) {
      try {
        return this.checklistArr.find((f) => f.key.toLowerCase() === "none")
          .selected;
      } catch {
        return false;
      }
    }
    return false;
  }
  deselectNone() {
    this.checklistArr = this.checklistArr.map((d) => {
      if (d.key.toLowerCase() === "none") {
        return { ...d, selected: false };
      } else {
        return d;
      }
    });
    this.ctrl.setValue(this.checklistArr);
  }
  ngOnDestroy() {
    this.sink.unsubscribe();
  }
}
