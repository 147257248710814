import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-login-buttons",
  templateUrl: "./login-buttons.component.html",
  styleUrls: ["./login-buttons.component.scss"],
})
export class LoginButtonsComponent implements OnInit {
  @Output() changeType = new EventEmitter();
  @Input() signUpEnabled: boolean;
  mode: string = "login";
  constructor() {}

  ngOnInit(): void {}
  changeMode(type) {
    this.mode = type;
    this.changeType.emit(type);
  }
}
