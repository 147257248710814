export interface FeaturesModel {
  patient: FeaturesMeta;
  doctor: FeaturesMeta;
}
export interface FeaturesMeta {
  socials?: string[];
  login: boolean;
  signup: boolean;
}
export interface ConfigModel {
  appId: string;
  basePrice: number;
  name: string;
  contact: string;
  logo: string;
  email: string;
  address: Address;
  speciality: Speciality[];
  creditCard: boolean;
  url: string;
  sendSMS: boolean;
  status: string;
  master?: boolean;
}

export interface Address {
  city: string;
  line1: string;
  state: string;
  country: string;
  zipcode: string;
  line2: string;
}

export interface Speciality {
  name: string;
  pricing: string;
}

export * from "./config.actions";
export * from "./feature.actions";
