<div
  class="col-md-12 p-0 d-flex"
  *ngIf="template === 'material'"
  [ngClass]="{ 'flex-column': !inline, 'flex-row align-items-center': inline }"
>
  <label class="mr-2" [innerHTML]="labelText"> </label>
  <mat-radio-group
    aria-label="Select an option"
    class="radio-group"
    [formControl]="ctrl"
  >
    <mat-radio-button
      [value]="o.key"
      *ngFor="let o of options"
      class="radio-button"
      color="primary"
      >{{ o.value }}</mat-radio-button
    >
  </mat-radio-group>
</div>
<app-show-errors [ctrl]="ctrl"></app-show-errors>
