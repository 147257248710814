import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "src/app/user";
import { environment } from "src/environments/environment";
import { take, switchMap } from "rxjs/operators";
import { Logger } from "src/constants/logger";
import * as moment from "moment-timezone";
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.v1)) {
      return this.authService.userAccessToken.pipe(
        take(1),
        switchMap((token) => {
          if (token) {
            const request = req.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
                "X-CTz": moment.tz.guess(),
              },
            });
            return next.handle(request);
          } else {
            return next.handle(req);
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
