<div class="row" *ngIf="checklistArr && checklistArr.length > 0">
  <div [ngClass]="'col-md-' + size" *ngFor="let f of checklistArr">
    <mat-checkbox
      (change)="updateData(f, $event.checked)"
      color="primary"
      [checked]="f.selected"
      ><span class="text-dark">{{
        f.value | titlecase | textOLower
      }}</span></mat-checkbox
    >
  </div>
</div>
