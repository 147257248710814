import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { SubSink } from "subsink";

@Component({
  selector: "app-multi-select",
  templateUrl: "./multi-select.component.html",
  styleUrls: ["./multi-select.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input("control") ctrl = new FormControl();
  @Input() label;
  @Input() data: any[];
  @Output() change = new EventEmitter();
  @Input("class") cls = "";
  @Input() selected = [];
  private sink = new SubSink();
  constructor() {}
  ngOnDestroy() {
    this.sink.unsubscribe();
  }
  ngOnInit(): void {
    this.sink.add(this.ctrl.valueChanges.subscribe((v) => this.change.emit(v)));
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.selected && changes.selected.currentValue) {
      this.ctrl.setValue(changes.selected.currentValue);
    }
  }
}
