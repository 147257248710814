import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpLoggerInterceptor } from "./services/http-logger-interceptor.service";
import { LoggerService } from "./services/logger.service";

@NgModule({
  imports: [CommonModule],
})
export class LoggerModule {
  static forRoot(): ModuleWithProviders<LoggerModule> {
    return {
      ngModule: LoggerModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpLoggerInterceptor,
          multi: true,
        },
        { provide: LoggerService, useClass: LoggerService },
      ],
    };
  }
}
