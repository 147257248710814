<div style="position: relative">
  <a *ngIf="filePreview !== null" class="clear-btn" (click)="clearCtrl()"
    ><i class="fas fa-times-circle text-danger"></i
  ></a>
  <div
    class="main-box bg-light rounded p-1 border mr-4 d-flex align-items-center justify-content-between"
    (click)="file.click()"
    [ngClass]="{ 'border-danger': ctrl.invalid && ctrl.touched }"
  >
    <input
      type="file"
      hidden
      #file
      required
      (change)="onFileChange($event)"
      accept="image/*"
    />
    <span
      *ngIf="filePreview === null; else preview"
      [ngClass]="{
        'text-muted': ctrl.untouched || ctrl.valid,
        'text-danger': ctrl.invalid && ctrl.touched
      }"
      class="d-flex flex-column align-items-center justify-content-center"
      style="color: #016e95 !important"
    >
      <i class="fas fa-upload"></i>
      <small> UPLOAD {{ type | uppercase }}</small>
    </span>
    <ng-template #preview>
      <img
        [src]="filePreview | safeImage"
        [style]="{ height: height + 'px' }"
      />
    </ng-template>
  </div>
</div>
