<div class="container-fluid px-0 bg-white">
  <!-- <div class="top--strip bg-primary">
    <div class="d-flex pd-7 align-items-center">
      <div class="d-flex mx-auto align-items-center">
        <div class="text mx-auto pl-2 pl-md-0 font-8">
          Are you concerned about Coronavirus? We can help you!
        </div>
        <div class="btn-ref mx-3">
          <a
            class="btn btn-danger text-white btn-sm font-8"
            style="white-space: nowrap"
            type="button"
            routerLink="/corona-virus"
            >Learn More</a
          >
        </div>
      </div>
    </div>
  </div> -->
  <header class="header--container sticky-top bg-white">
    <div class="container-fluid top-strip px-md-3">
      <div
        class="d-flex flex-row justify-content-md-between align-content-center container-lg"
      >
        <span class="d-block my-auto d-md-none hamburger-menu">
          <span class="burger-1"></span>
          <span class="burger-2"></span>
          <span class="burger-3"></span>
        </span>
        <div class="logo mx-auto mx-md-0 py-1 py-md-0 d-flex flex-nowrap">
          <a href="/"
            ><img [src]="config?.logo | safeImage" [alt]="config?.name"
          /></a>
          <a href="https://compliancy-group.com/hipaa-compliance-verification/"
            ><img src="assets/hippa.png" style="max-height: 50px" alt=""
          /></a>
        </div>
        <div
          class="contact-info d-none d-md-flex flex-column align-items-left my-auto"
        >
          <div class="d-flex flex-row">
            <!-- <i class="material-icons">phone</i> -->
            <div class="type">Corporate Contact</div>
            <div class="contact ml-3 d-flex flex-column">
              <a href="tel:{{ config?.contact }}" class="text-dark">{{
                config?.contact | formatPhone
              }}</a>
            </div>
          </div>
          <div class="d-flex flex-row">
            <!-- <i class="material-icons">email</i> -->
            <div class="type">Email</div>
            <div class="contact ml-3">
              <a href="mailto:{{ config?.email }}" class="text-dark">{{
                config?.email
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid d-none d-md-block" id="sidebar-div">
      <div class="container d-flex flex-md-row flex-column">
        <ul class="d-flex flex-md-row flex-column list-unstyled mr-auto">
          <li data-attr="home" class="active">
            <a href="/#home" (click)="scroll(home, $event)">Home</a>
          </li>
          <li data-attr="what-we-treat">
            <a href="/#what-we-treat" (click)="scroll(whatwetreat, $event)"
              >What We Treat</a
            >
          </li>
          <li data-attr="what-we-dont-treat">
            <a
              href="/#what-we-dont-treat"
              (click)="scroll(whatwedonttreat, $event)"
              >What We Don't Treat</a
            >
          </li>
          <li data-attr="pricing">
            <a href="/#pricing" (click)="scroll(pricing, $event)">Pricing</a>
          </li>
          <li data-attr="how-it-works">
            <a href="/#how-it-works" (click)="scroll(howitworks, $event)"
              >How it works</a
            >
          </li>
          <li data-attr="doctors">
            <a routerLink="/doctor/selector" *ngIf="!isLoggedIn"
              >Meet our Doctors</a
            >
            <a routerLink="/doctor/selector" *ngIf="isLoggedIn"
              >Select a Doctor</a
            >
          </li>
        </ul>
        <div class="d-flex align-items-center" *ngIf="!isLoggedIn; else logout">
          <a class="btn btn-link btn-sm btn--links" (click)="patientLogin()">
            Patient Login / Sign Up
          </a>
          <a
            class="btn btn-link btn-sm ml-4 btn--links"
            (click)="doctorLogin()"
          >
            Doctor's Login
          </a>
        </div>
        <ng-template #logout>
          <div class="d-flex align-items-center">
            <a
              class="btn btn-link btn-sm ml-4 btn--links"
              style="cursor: pointer"
              (click)="navigateToProfile()"
              >{{ name | titlecase }}</a
            >
            <button class="btn btn-logout btn-sm ml-2" (click)="signOut()">
              Logout
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </header>
  <section class="top--section" id="home" #home>
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-lg-6 my-auto">
          <div
            data-aos="fade-in"
            class="d-flex flex-column align-items-left bg-card"
          >
            <div class="head mb-3" data-aos="fade-down">
              Virtual Visits Made Easy!
            </div>
            <div class="text mb-2" data-aos="fade-up">
              At {{ config?.name | titlecase }} we now offer access to our
              doctors through telemedicine.
              <div class="color-primary">
                You can see a doctor starting at only ${{ config?.basePrice }}.
              </div>
            </div>
            <div class="def-btn mt-4" data-aos="fade-up">
              <a [routerLink]="['/doctor', 'selector']" class="def-btn_a"
                >See a Doctor Now</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="what-we-treat" class="wwt--section" #whatwetreat>
    <div class="container">
      <div class="row">
        <div class="col-12 text-left"></div>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <div class="d-flex flex-column" data-aos="fade-up">
            <div class="head mb-4 underline-p">What We Treat</div>
            <div class="text mb-3 pt-3">
              We offer virtual visit services for a wide range of problems.
              Please have a look at our range of services.
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div
            class="tabs--container wo-bg flex-5 mt-5 mt-lg-0"
            data-aos="fade-in"
          >
            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a
                  class="nav-item nav-link active"
                  id="tab1-tab"
                  data-toggle="tab"
                  href="#tab1"
                >
                  Neurology
                </a>
                <ng-container *ngIf="showTabs">
                  <a
                    class="nav-item nav-link"
                    id="tab2-tab"
                    data-toggle="tab"
                    href="#tab2"
                  >
                    Urgent Care
                  </a>
                  <a
                    class="nav-item nav-link"
                    id="tab3-tab"
                    data-toggle="tab"
                    href="#tab3"
                  >
                    Behavioral Health
                  </a>
                  <a
                    class="nav-item nav-link"
                    id="tab4-tab"
                    data-toggle="tab"
                    href="#tab4"
                  >
                    Preventive Health
                  </a>
                  <a
                    class="nav-item nav-link"
                    id="tab5-tab"
                    data-toggle="tab"
                    href="#tab5"
                  >
                    Chronic Care
                  </a>
                </ng-container>
              </div>
            </nav>
            <div
              class="tab-content flex-5 block-padding bg-light"
              id="nav-tabContent"
            >
              <div
                class="tab-pane active show"
                id="tab1"
                role="tabpanel"
                aria-labelledby="tab1-tab"
              >
                <div class="row" data-aos="fade-up">
                  <div class="col-sm-6">
                    <ul>
                      <li>Headaches</li>
                      <li>Blurry vision</li>
                      <li>Numbness</li>
                    </ul>
                  </div>
                  <div class="col-sm-6">
                    <ul>
                      <li>Tremors</li>
                      <li>Weakness</li>
                      <li>Epilepsy</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="tab2"
                role="tabpanel"
                aria-labelledby="tab2-tab"
              >
                <div class="row" data-aos="fade-up">
                  <div class="col-sm-6">
                    <ul>
                      <li>Cold & flu</li>
                      <li>Cough</li>
                      <li>Nausea & vomiting</li>
                      <li>Headaches</li>
                      <li>Rashes/Acne/Allergies</li>
                      <li>Fatigue</li>
                      <li>Joint pain</li>
                    </ul>
                  </div>
                  <div class="col-sm-6">
                    <ul>
                      <li>Asthma</li>
                      <li>Fever</li>
                      <li>Sinus infections</li>
                      <li>UTIs</li>
                      <li>Migranes</li>
                      <li>IBS</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="tab3"
                role="tabpanel"
                aria-labelledby="tab3-tab"
              >
                <div class="row" data-aos="fade-up">
                  <div class="col-sm-6">
                    <ul>
                      <li>Anxiety</li>
                      <li>Stress</li>
                      <li>Insomnia</li>
                      <li>Restlessness</li>
                      <li>Panic attacks</li>
                      <li>Substance abuse disorders</li>
                    </ul>
                  </div>
                  <div class="col-sm-6">
                    <ul>
                      <li>Bipolar</li>
                      <li>Mood swings</li>
                      <li>Postpartum depression</li>
                      <li>Physical abuse</li>
                      <li>PTSD</li>
                      <li>Depression</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="tab4"
                role="tabpanel"
                aria-labelledby="tab4-tab"
              >
                <div class="row" data-aos="fade-up">
                  <div class="col-sm-6">
                    <ul>
                      <li>Family medicine</li>
                      <li>Women's wellness</li>
                      <li>Men's wellness</li>
                    </ul>
                  </div>
                  <div class="col-sm-6">
                    <ul>
                      <li>Diet & nutrition</li>
                      <li>Medication management</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="tab5"
                role="tabpanel"
                aria-labelledby="tab5-tab"
              >
                <div class="row" data-aos="fade-up">
                  <div class="col-sm-6">
                    <ul>
                      <li>Asthma</li>
                      <li>Cholesterol</li>
                      <li>Diabetes</li>
                      <li>Medication management</li>
                      <li>Heart palpitations</li>
                    </ul>
                  </div>
                  <div class="col-sm-6">
                    <ul>
                      <li>High blood pressure</li>
                      <li>Diet and nutrition</li>
                      <li>Weight management</li>
                      <li>Thyroid issues</li>
                      <li>Obesity</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="what-we-dont-treat" class="wwt--section bg-red" #whatwedonttreat>
    <div class="container">
      <div class="row">
        <div class="col-12 text-left"></div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-column" data-aos="fade-up">
            <div class="head mb-4 underline-p">What We Don't Treat</div>
            <div class="text mb-3 pt-3">
              While our doctors are able to treat the top issues you would go
              see a doctor about, there are conditions that we don’t treat.
              Please see an in-person doctor or hospital if you experience any
              of the following:
            </div>
            <div
              class="wwdt-row d-flex flex-md-row flex-column justify-content-center mt-4"
              data-aos="fade-up"
            >
              <div class="col-12 col-md-6">
                <ul>
                  <li>Traumatic brain or spinal cord injury</li>
                  <li>Chest pain and/or numbness</li>
                  <li>Vomiting or coughing blood</li>
                  <li>Lacerations</li>
                  <li>Sudden paralysis</li>
                </ul>
              </div>
              <div class="col-12 col-md-6">
                <ul>
                  <li>Loss of consciousness</li>
                  <li>Broken bones</li>
                  <li>Severe burns</li>
                  <li>Pediatric ear infections</li>
                  <li>Inability to speak</li>
                </ul>
              </div>
            </div>
            <div class="text mt-5 font-weight-bold">
              Please call 911 or consult your nearest doctor in case of medical
              emergencies.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="pricing" class="wwt--section bg-sm" #pricing>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 order-lg-1 order-2">
          <div class="tabs--container wo-bg mt-5 mt-lg-0" data-aos="fade-in">
            <nav>
              <div class="nav nav-tabs flex-50" id="nav-tab" role="tablist">
                <a
                  class="nav-item bg-sm nav-link active"
                  id="tab1-tab"
                  data-toggle="tab"
                  href="#tab-w"
                >
                  With Insurance
                </a>
                <a
                  class="nav-item bg-sm nav-link"
                  id="tab2-tab"
                  data-toggle="tab"
                  href="#tab-wo"
                >
                  Without Insurance
                </a>
              </div>
            </nav>
            <div class="tab-content block-padding bg-light" id="nav-tabContent">
              <div
                class="tab-pane active show"
                id="tab-w"
                role="tabpanel"
                aria-labelledby="tab1-tab"
              >
                <div class="row">
                  <div class="d-flex flex-column px-md-4 w-100">
                    <div class="title mb-4 mb-md-0">
                      We accept most major insurance providers
                    </div>
                    <div
                      class="d-flex flex-md-row flex-wrap justify-content-center mt-4 pt-md-3 text-center"
                    >
                      <div class="mb-3 pb-2 col-md-4 col-sm-4 col-6">
                        <div class="mtitle">Neurology</div>
                        <div class="price">$50</div>
                      </div>
                      <div class="mb-3 pb-2 col-md-4 col-sm-4 col-6">
                        <div class="mtitle">Urgent Care</div>
                        <div class="price">$50</div>
                      </div>
                      <div class="mb-3 pb-2 col-md-4 col-sm-4 col-6">
                        <div class="mtitle">Chronic Care</div>
                        <div class="price">$50</div>
                      </div>
                      <div class="mb-3 pb-2 col-md-6 col-sm-6 col-6">
                        <div class="mtitle">Behavioral Health</div>
                        <div class="price">$50</div>
                      </div>
                      <div class="mb-3 pb-2 col-md-6 col-sm-6 col-6">
                        <div class="mtitle">Preventive Health</div>
                        <div class="price">$50</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="tab-wo"
                role="tabpanel"
                aria-labelledby="tab2-tab"
              >
                <div class="row">
                  <div class="d-flex flex-column px-md-4 w-100">
                    <div class="title mb-4 mb-md-0">
                      Our Prices for Various Services
                    </div>
                    <div
                      class="d-flex flex-md-row flex-wrap justify-content-center mt-4 pt-md-3 text-center"
                    >
                      <div class="mb-3 pb-2 col-md-4 col-sm-4 col-6">
                        <div class="mtitle">Neurology</div>
                        <div class="price">$495</div>
                      </div>
                      <div class="mb-3 pb-2 col-md-4 col-sm-4 col-6">
                        <div class="mtitle">Urgent Care</div>
                        <div class="price">$79</div>
                      </div>
                      <div class="mb-3 pb-2 col-md-4 col-sm-4 col-6">
                        <div class="mtitle">Chronic Care</div>
                        <div class="price">$79</div>
                      </div>
                      <div class="mb-3 pb-2 col-md-6 col-sm-6 col-6">
                        <div class="mtitle">Behavioral Health</div>
                        <div class="price">$79</div>
                      </div>
                      <div class="mb-3 pb-2 col-md-6 col-sm-6 col-6">
                        <div class="mtitle">Preventive Health</div>
                        <div class="price">$79</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 order-lg-2 order-1">
          <div class="d-flex flex-column pl-md-4 mt-md-1">
            <div class="head mb-3" data-aos="fade-up">Affordable Pricing</div>
            <div class="text mb-3" data-aos="fade-up">
              At Princeton Virtual Health, we are trying to bring you high
              quality healthcare at affordable prices.
            </div>
            <!-- <a class="my-2 a-ref d-flex align-items-center" href="http://princetonvirtualhealth.sevaro.com"  data-aos="fade-up">
                              <span>Learn More</span>
                              <i class="material-icons ml-3">
                                  arrow_right_alt
                              </i>
                          </a> -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="how-it-works" class="hiw--section bg-xs" #howitworks>
    <div class="container">
      <div class="row">
        <div class="col-12 text-left" data-aos="fade-up">
          <div class="head mb-3 underline-p wp-10">How it Works</div>
        </div>
        <section class="hiw--container pb-0 pb-sm-5">
          <div class="container px-lg-0 pb-5 px-4">
            <div class="row mt-md-5 mt-4 pt-md-4 pt-4 pb-4">
              <div class="col-lg-3 px-lg-0">
                <div
                  class="hiw--container_item no-animate d-flex flex-lg-column flex-row pb-lg-2 pb-5"
                >
                  <div class="">
                    <div class="line"></div>
                    <div class="circle">
                      <div class="mask">
                        <div class="pulse pulse-1"></div>
                        <div class="pulse pulse-2"></div>
                        <div class="pulse pulse-3"></div>
                      </div>
                      <div class="c">1</div>
                    </div>
                  </div>
                  <div class="content">
                    <h5>{{ "home.selectDoctorTitle" | transloco }}</h5>
                    <div class="text">
                      {{ "home.selectDoctorText" | transloco }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 px-lg-0">
                <div
                  class="hiw--container_item no-animate d-flex flex-lg-column flex-row pb-lg-2 pb-5"
                >
                  <div class="">
                    <div class="line"></div>
                    <div class="circle">
                      <div class="mask">
                        <div class="pulse pulse-1"></div>
                        <div class="pulse pulse-2"></div>
                        <div class="pulse pulse-3"></div>
                      </div>
                      <div class="c">2</div>
                    </div>
                  </div>
                  <div class="content">
                    <h5>{{ "home.registerYourselfTitle" | transloco }}</h5>
                    <div class="text">
                      {{ "home.registerYourselfText" | transloco }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 px-lg-0">
                <div
                  class="hiw--container_item no-animate d-flex flex-lg-column flex-row pb-lg-2 pb-5"
                >
                  <div class="">
                    <div class="line"></div>
                    <div class="circle">
                      <div class="mask">
                        <div class="pulse pulse-1"></div>
                        <div class="pulse pulse-2"></div>
                        <div class="pulse pulse-3"></div>
                      </div>
                      <div class="c">3</div>
                    </div>
                  </div>
                  <div class="content">
                    <h5>{{ "home.medicalHistoryTitle" | transloco }}</h5>
                    <div class="text">
                      {{ "home.medicalHistoryText" | transloco }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 px-lg-0">
                <div
                  class="hiw--container_item no-animate d-flex flex-lg-column flex-row pb-lg-2 pb-5"
                >
                  <div class="">
                    <div class="line"></div>
                    <div class="circle">
                      <div class="mask">
                        <div class="pulse pulse-1"></div>
                        <div class="pulse pulse-2"></div>
                        <div class="pulse pulse-3"></div>
                      </div>
                      <div class="c">4</div>
                    </div>
                  </div>
                  <div class="content">
                    <h5>{{ "home.seeTheDoctorTitle" | transloco }}</h5>
                    <div class="text">
                      {{ "home.seeTheDoctorText" | transloco }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>

  <app-footer></app-footer>
</div>
