import { TEMPLATE_ENGINES } from "src/app/formsy";
import { Validators } from "@angular/forms";
let options: any = ["No", "Yes", "Unknown"];
options = options.map((o) => ({ key: o, value: o }));

export const CORONA_FORM_MODEL = {
  templateEngine: TEMPLATE_ENGINES.MATERIAL,
  controls: [
    {
      type: "radio",
      controlType: "control",
      options,
      name: "fever",
      label: "Do you have a fever?",
      ctrlSize: 5,
      validators: [Validators.required],
    },
    {
      type: "radio",
      controlType: "control",
      options: [
        { key: "None", value: "None" },
        { key: "Same Household", value: "Same Household" },
        { key: "Intimate Partner", value: "Intimate Partner" },
      ],
      name: "closeContact",
      label: "Close contact with suspected COVID-19 patient?",
      ctrlSize: 7,
      validators: [Validators.required],
    },
    {
      type: "radio",
      controlType: "control",
      options,
      name: "dryCough",
      label: "Do you have dry cough?",
      ctrlSize: 5,
      validators: [Validators.required],
    },
    {
      type: "radio",
      controlType: "control",
      options: [options[0], options[1]],
      name: "healthCareWorker",
      label: "Are you a healthcare worker with potential exposure to COVID-19?",
      ctrlSize: 7,
      validators: [Validators.required],
    },
    {
      type: "radio",
      controlType: "control",
      options,
      name: "breath",
      label: "Are you having breathing problems?",
      ctrlSize: 5,
      validators: [Validators.required],
    },

    {
      type: "radio",
      controlType: "control",
      options,
      name: "headache",
      label: "Do you have a headache?",
      ctrlSize: 7,
      validators: [Validators.required],
    },
    {
      type: "radio",
      controlType: "control",
      options: [options[0], options[1]],
      name: "recentTravel",
      label: "Recent International travel within past 14 days?",
      ctrlSize: 5,
      validators: [Validators.required],
    },
    {
      type: "multiline",
      controlType: "control",
      name: "placesOfVisit",
      label: "Places visited",
      ctrlSize: 7,
    },
  ],
};
