import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  Input,
} from "@angular/core";
import {
  ChecklistDatabase,
  TodoItemFlatNode,
  TodoItemNode,
} from "../../services/checklist-database.service";
import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { Logger } from "src/constants/logger";
import { FormControl } from "@angular/forms";
import { NgLog } from "src/app/logger";
import * as _ from "lodash";
@Component({
  selector: "app-labs-tree",
  templateUrl: "./labs-tree.component.html",
  styleUrls: ["./labs-tree.component.scss"],
  providers: [ChecklistDatabase],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@NgLog()
export class LabsTreeComponent implements OnInit, OnChanges {
  @Output() selected = new EventEmitter();
  @Input() selectedLabs: Array<string>;
  @Input() dataSet: any;
  other = new FormControl();
  otherLabs = new FormControl();
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = "";

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(
    true
  ); /* multiple */

  _selectedItems: TodoItemFlatNode[];
  labsSelectedCurrently: string[];

  constructor(private database: ChecklistDatabase) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) =>
    _nodeData.item === "";

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.dataSet && changes.dataSet.currentValue) {
      this.database.loadLabsFromJSON(changes.dataSet.currentValue);
      if (this.selectedLabs) {
        this.setChecked(this.selectedLabs);
      }
    }
    if (changes && changes.selectedLabs && changes.selectedLabs.currentValue) {
      const labs = changes.selectedLabs.currentValue;
      Logger.Log("selected labs", labs);
      this.setChecked(labs);
    }
  }
  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    const labs = this.checklistSelection.selected.map((s) => s.item);
    this.labsSelectedCurrently = labs;
    this.selected.emit([...labs, this.otherLabs.value]);
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    // HERE IS WHERE THE PART OF THE MODEL RELATED TO THE CLICKED CHECKBOX IS UPDATED
    this.checklistSelection.toggle(node);

    // HERE WE GET POTENTIAL CHILDREN OF THE CLICKED NODE
    const descendants = this.treeControl.getDescendants(node);

    // HERE IS WHERE THE REST OF THE MODEL (POTENTIAL CHILDREN OF THE CLICKED NODE) IS UPDATED
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
    // this._selectedItems = this.checklistSelection.selected.map((s) => s.item);
    const labs = this.checklistSelection.selected.map((s) => s.item);
    Logger.Log("LABS SELECTED", labs);
    this.labsSelectedCurrently = labs;
    this.selected.emit([...labs, this.otherLabs.value]);
  }

  setChecked(labs: Array<string>) {
    if(labs){
    this.treeControl.dataNodes.forEach((d) => {
      if (labs.includes(d.item)) {
        this.checklistSelection.toggle(d);
      }
    });
    this.otherLabsData();
    }
  }
  otherLabsData() {
    try {
      if (this.dataSet) {
        let set: any = decodeURIComponent(this.dataSet);
        set = eval(`(${set})`);
        let data: any[] = this.flattenEntries(set);
        data = _.flattenDeep(data);
        data = data.filter((d) => d !== null);
        data = data.map((d) => d.toLowerCase().split("."));
        data = _.flattenDeep(data);
        data = _.uniq(data);
        let difference = this.selectedLabs.filter((x) => {
          return !data.includes(x.trim().toLowerCase());
        });
        if (difference.length > 0) {
          this.other.setValue(true);
          this.otherLabs.setValue(difference.toString());
        }
      }
    } catch (e) {
      Logger.Log("labs error", e);
    }
  }
  flattenEntries(
    object,
    maxDepth = 4,
    keyPrefixer = (parentKey, childKey) => `${parentKey}.${childKey}`
  ): string[] {
    if (!object || !_.isPlainObject(object)) {
      return [];
    }
    // make maxDepth >= 1
    maxDepth = Math.max(1, Math.abs(maxDepth));

    const entryIsNotAnObject = ([key, val]) => !_.isPlainObject(val);

    let [simpleProperties, childObjects] = _.partition(
      Object.entries(object),
      entryIsNotAnObject
    );

    let result = simpleProperties;

    for (let depth = 1; depth < maxDepth; depth++) {
      for (let [childObjectKey, childObject] of childObjects) {
        const entries = Object.entries(childObject);
        const addParentPrefixToKey = ([key, val]) => [
          keyPrefixer(childObjectKey, key),
          val,
        ];
        const prefixedEntries = entries.map(addParentPrefixToKey);
        [simpleProperties, childObjects] = _.partition(
          prefixedEntries,
          entryIsNotAnObject
        );
        result = result.concat(simpleProperties);
      }
    }

    return result;
  }
  filterChanged(filterText: string) {
    this.database.filter(filterText);
    this.setChecked(this.labsSelectedCurrently);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }
}
